import React from "react"
import classNames from "classnames"

export default class Select extends React.Component {
    render() {
        const { value, onChange, children, className, disabled } = this.props

        const wrapperClass = classNames("relative", className)

        return <div className={wrapperClass}>
            <select
                className="block w-full appearance-none bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
                value={value}
                disabled={disabled}
                onChange={e => onChange && onChange(e.target.value)}
            >
                { children }
            </select>
            <div className="pointer-events-none absolute right-0 inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
        </div>
    }
}
