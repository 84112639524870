import React from "react"
import produce from "immer"
import cloneDeep from "lodash/cloneDeep"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
    FaPencilAlt,
    FaRegTrashAlt,
    FaClone,
    FaPlus,
    FaAngleLeft,
    FaAngleRight,
} from "react-icons/fa"

import {
    IoMdReturnLeft
} from "react-icons/io"

import Input from "~/components/Input"
import Button from "~/components/Button"
import EditProductModal from "~/components/EditProductModal"

import {
    refreshProducts,
    deleteProduct,
    updateProductsPagination,
    updateProductFilters,
} from "~/store"

import {
    ean13_checksum
} from "~/lib/utils"

const mapStateToProps = state => ({
    page: state.app.products.page.map(id => state.app.products.entities[id]),
    totalPages: Math.ceil(state.app.products.count / state.app.products.limit),
    currentPage: 1 + Math.floor(state.app.products.offset / state.app.products.limit),
    filters: state.app.products.filters,
})

const mapDispatchToProps = dispatch => ({
    onRefreshProducts: () => dispatch(refreshProducts()),
    onDeleteProduct: pid => dispatch(deleteProduct(pid)),
    onPageClick: (dir) => dispatch(updateProductsPagination(dir)),
    onUpdateFilters: (...args) => dispatch(updateProductFilters(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(class ProductsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editingProduct: null,
        }
    }

    componentDidMount() {
        this.props.onRefreshProducts()
    }

    render() {
        const {
            page,
            currentPage,
            totalPages,
            filters,
        } = this.props
        const {
            editingProduct
        } = this.state

        return <div className="max-w-4xl mx-auto py-2">
            <div className="flex items-center mtsp-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Productos
                </h1>
            </div>

            <div className="mtsp-2">
                <Button onClick={this.handleNewProduct}>
                    <FaPlus className="mr-1" />
                    Crear
                </Button>
            </div>

            <div className="mtsp-2 flex">
                <div className="pr-2 w-1/2">
                    <label className="uppercase text-xs">
                        nombre
                    </label>
                    <Input
                        value={filters.name}
                        onChange={this.handleSetFilter("name")}
                    />
                </div>
                <div className="pl-2 w-1/2">
                    <label className="uppercase text-xs">
                        pn
                    </label>
                    <Input
                        value={filters.pn}
                        onChange={this.handleSetFilter("pn")}
                    />
                </div>
            </div>

            <div className="mtsp-2 flex items-center">
                <Button
                    title="Página anterior"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(-1)}
                >
                    <FaAngleLeft />
                </Button>
                <span className="mx-2 text-sm text-gray-700 font-bold">
                    { currentPage } / { totalPages }
                </span>
                <Button
                    title="Página siguiente"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(1)}
                >
                    <FaAngleRight />
                </Button>
            </div>

            { page.map(product => <div
                className="mtsp-2 flex hover:bg-gray-200 p-2 rounded"
                key={product.id}
            >
                <div>
                    <div className="font-semibold">
                        { product.pn }
                    </div>
                    <div className="text-sm">
                        { product.name && <span className="mlsp-4">
                            { product.name }
                        </span> }
                        { product.ean13 && <>
                            <span className="mlsp-4 text-xs text-gray-700">
                                EAN13: { product.ean13 }{ ean13_checksum(product.ean13) }
                            </span>
                        </> }
                    </div>
                </div>
                <div className="flex ml-auto items-center">
                    <Button
                        title="Editar producto"
                        className="mlsp-2"
                        theme="transparent"
                        style="compact"
                        type="button"
                        onClick={e => this.handleEditProduct(e, product)}
                    >
                        <FaPencilAlt />
                    </Button>
                    <Button
                        title="Eliminar producto"
                        className="mlsp-2"
                        theme="transparent"
                        style="compact"
                        type="button"
                        onClick={e => this.handleDeleteProduct(e, product)}
                    >
                        <FaRegTrashAlt />
                    </Button>
                    <Button
                        title="Clonar producto"
                        className="mlsp-2"
                        theme="transparent"
                        style="compact"
                        type="button"
                        onClick={e => this.handleCloneProduct(e, product)}
                    >
                        <FaClone />
                    </Button>
                </div>
            </div>) }
            { editingProduct && <EditProductModal
                product={editingProduct}
                onRequestClose={this.hideEditProductModal}
            /> }
        </div>
    }

    handleSetFilter = prop => value => {
        this.props.onUpdateFilters({ [prop]: value })
    }

    hideEditProductModal = () => {
        this.setState({ editingProduct: null })
    }

    handleEditProduct = (event, product) => {
        event.preventDefault()

        this.setState(produce(draft => {
            draft.editingProduct = cloneDeep(product)
        }))
    }

    handleDeleteProduct = (event, product) => {
        const go = window.confirm(`¿Deseas eliminar ${product.pn}?`)
        if (!go) return

        this.props.onDeleteProduct(product.id)
    }

    handleCloneProduct = (event, product) => {
        this.setState(produce(draft => {
            const clone = cloneDeep(product)
            clone.pn = ""
            clone.ean13 = ""
            delete clone.id
            for (let block of clone.blocks) {
                delete block.id
                for (let step of block.steps) {
                    delete step.id
                }
            }
            draft.editingProduct = clone
        }))
    }

    handleNewProduct = () => {
        this.setState({
            editingProduct: {}
        })
    }

    handlePageClick = (dir) => {
        this.props.onPageClick(dir)
    }
})
