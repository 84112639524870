import React from "react"
import ReactModal from "react-modal"
import produce from "immer"
import set from "lodash/set"
import axios from "axios"

import {
    FaWindowClose,
    FaFileCsv,
} from "react-icons/fa"

import {
    MdPlaylistAdd
} from "react-icons/md"

import {
    doDownload,
} from "~/lib/utils"

import StockItemSelector from "~/components/StockItemSelector"
import Input from "~/components/Input"
import Button from "~/components/Button"

export default class StockNeedsModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            needs: [],
            shoplist: [],
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <h1 className="text-3xl font-extralight mb-4">
                Calculadora de previsiones
            </h1>

            <form onSubmit={this.handleSubmit}>
                <div className="mtsp-2">
                    { this.state.needs.map((need, idx) => <div
                        className="flex items-center mtsp-1"
                        key={idx}
                    >
                        <div className="pr-2 flex-grow">
                            <StockItemSelector

                                value={need.stockItemId}
                                onChange={this.handleInput(`needs[${idx}].stockItemId`)}
                            />
                        </div>
                        <div className="px-2">
                            <Input
                                type="number"
                                value={need.amount}
                                onChange={this.handleInput(`needs[${idx}].amount`)}
                            />
                        </div>
                        <div className="pl-2">
                            <Button
                                onClick={e => this.handleClickRemoveButton(e, idx)}
                                theme="outline"
                            >
                                x
                            </Button>
                        </div>
                    </div>) }
                </div>

                <div className="mtsp-2 flex justify-center">
                    <Button
                        theme="outline"
                        onClick={this.handleClickAddButton}
                    >
                        <MdPlaylistAdd className="mr-1" />
                        Add item
                    </Button>
                </div>

                <div className="mtsp-2 flex">
                    <Button className="ml-auto">
                        Calcular
                    </Button>
                </div>
            </form>

            <hr className="my-4" />

            <table className="w-full text-xs">
                <thead>
                    <tr>
                        <th className="text-left">
                            PN
                        </th>
                        <th className="text-left">
                            MPN
                        </th>
                        <th className="text-left">
                            Nombre
                        </th>
                        <th className="text-left">
                            Proveedor
                        </th>
                        <th>
                            En stock
                        </th>
                        <th>
                            Necesario
                        </th>
                        <th className="text-right">
                            Coste
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { this.state.shoplist.map((row, idx) => <tr
                        key={idx}
                        className="odd:bg-gray-200"
                    >
                        <td>
                            { row.pn }
                        </td>
                        <td>
                            { row.mpn }
                        </td>
                        <td>
                            { row.name }
                        </td>
                        <td>
                            { row.supplier }
                        </td>
                        <td className="text-center">
                            { row.stock }
                        </td>
                        <td className="text-center">
                            { row.needed }
                        </td>
                        <td className="text-right">
                            { row.cost.toFixed(4) } €
                        </td>
                    </tr>) }
                </tbody>
            </table>

            <div className="mt-2 flex">
                <Button
                    className="ml-auto"
                    onClick={this.handleClickDownloadCsv}
                >
                    <FaFileCsv className="mr-1" />
                    Descargar
                </Button>
            </div>
        </ReactModal>
    }

    handleClickDownloadCsv = event => {
        event.preventDefault()

        const csv = [
            `PN, MPN, Nombre, Proveedor, Stock, Needed, Coste`
        ].concat(this.state.shoplist.map(i => {
            return `${i.pn}, ${i.mpn || ""}, ${i.name}, ${i.supplier}, ${i.stock}, ${i.needed}, ${i.cost}`
        })).join("\n")
        doDownload(new Blob([csv], { type: "text/csv" }), "shoplist.csv")
    }

    handleClickAddButton = event => {
        event.preventDefault()

        this.setState(produce(draft => {
            draft.needs.push({
                stockItemId: null,
                amount: 0,
            })
        }))
    }

    handleClickRemoveButton = (event, index) => {
        event.preventDefault()

        this.setState(produce(draft => {
            draft.needs.splice(index, 1)
        }))
    }

    handleSubmit = async event => {
        event.preventDefault()

        const { needs } = this.state

        const wanted = {}
        for (let need of needs) {
            if (need.stockItemId && need.amount) {
                wanted[need.stockItemId] = need.amount
            }
        }

        const response = await axios.post("/stock-needs", { wanted })
        this.setState({
            shoplist: response.data
        })
    }

    handleInput = path => value => this.setState(produce(draft => set(draft, path, value)))
}
