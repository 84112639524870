import React from "react"
import ReactModal from "react-modal"
import { connect } from "react-redux"
import cn from "classnames"
import produce from "immer"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"

import {
    FaClone,
    FaTrashAlt,
    FaArrowLeft,
    FaFilePdf,
    FaLayerGroup,
} from "react-icons/fa"

import {
    MdDragHandle,
} from "react-icons/md"

import {
    reorderArray,
} from "~/lib/utils"

import {
    notify
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"
import Select from "~/components/Select"
import SortList from "~/components/SortList"

import EditStepModal from "~/components/EditStepModal"

const mapDispatchToProps = dispatch => ({
    onNotify: (...args) => dispatch(notify(...args)),
})

export default connect(null, mapDispatchToProps)(class EditBlockModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialBlock = Object.assign({
            name: "",
            steps: [],
        }, props.block)

        this.state = {
            stepTypes: ["check", "input", "multi-input", "json", "picture", "supply", "autosupply"],
            block: cloneDeep(this.initialBlock),
            newStepName: "",
            newStepType: "check",
            editingStepIdx: null,
        }

        this.stepElements = []
    }

    handleRequestClose = () => {
        if (!isEqual(this.initialBlock, this.state.block)) {
            const go = window.confirm("Este bloque tiene cambios sin guardar. ¿Seguro que deseas salir?")
            if (!go) return
        }

        this.props.onRequestClose()
    }

    render() {
        const {
            stepTypes,
            block,
            newStepName,
            newStepType,
            editingStepIdx,
        } = this.state
        const {
            descriptionName
        } = this.props

        const editingStep = block.steps[editingStepIdx]

        return <ReactModal
            className="max-w-3xl mx-auto pt-8 px-4 pb-4 relative bg-white rounded overflow-hidden outline"
            isOpen
            onRequestClose={this.handleRequestClose}
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                type="button"
                onClick={this.handleRequestClose}
            >
                <FaArrowLeft />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <h1 className="text-xl font-semibold">
                    { descriptionName }
                </h1>

                <hr className="my-2" />

                <div className="flex">
                    <div>
                        <label className="text-xs uppercase">
                            Nombre del bloque
                        </label>
                        <Input
                            value={block.name}
                            onChange={v => this.handleInput("block.name", v)}
                        />
                    </div>
                </div>

                <h1 className="mt-4 font-semibold text-xl">
                    Pasos
                </h1>

                <div className="h-64 overflow-y-auto">
                    <SortList
                        items={block.steps}
                        render={(step, { dragged, index, ref, onMouseDown, onTouchStart }) => <div
                            ref={ref}
                            className={cn("flex items-baseline py-1 border-b pr-4", {
                                "bg-gray-200": dragged,
                                "border-t": index == 0,
                            })}
                        >
                            <MdDragHandle
                                className="self-center mr-2 text-xl cursor-move"
                                onMouseDown={onMouseDown}
                                onTouchStart={onTouchStart}
                            />
                            <button
                                className="mr-2 font-semibold"
                                type="button"
                                onClick={() => this.handleStepClick(step, index)}
                            >
                                { step.name }
                            </button>
                            <div className="flex items-center">
                                <span className="text-gray-700 text-xs mlsp-2">
                                    { step.type }
                                </span>
                                <span className="text-gray-700 text-xs mlsp-2">
                                    { step.ref }
                                </span>
                                { step.manualVersionId && <FaFilePdf className="text-gray-700 text-xs mlsp-2" /> }
                                { step.perInstance && <FaLayerGroup className="text-gray-700 text-xs mlsp-2" /> }
                            </div>
                            <span className="ml-auto">
                                <button
                                    className="ml-2"
                                    type="button"
                                    onClick={() => this.handleCloneStep(index)}
                                >
                                    <FaClone />
                                </button>
                                <button
                                    className="ml-2"
                                    type="button"
                                    onClick={() => this.handleDeleteStep(index)}
                                >
                                    <FaTrashAlt />
                                </button>
                            </span>
                        </div>}
                        renderPlaceholder={() =><div className="bg-blue-800 h-1" />}
                        onDragEnd={this.handleDragEnd}
                    />
                </div>

                <div className="flex items-end py-1 mt-2">
                    <div>
                        <label className="text-xs uppercase">
                            Nombre
                        </label>
                        <Input
                            value={newStepName}
                            onChange={v => this.handleInput("newStepName", v)}
                        />
                    </div>
                    <div className="ml-2">
                        <label className="text-xs uppercase">
                            Tipo
                        </label>
                        <Select
                            value={newStepType}
                            onChange={v => this.handleInput("newStepType", v)}
                        >
                            { stepTypes.map(type => <option
                                key={type}
                                value={type}
                            >
                                { type }
                            </option>) }
                        </Select>
                    </div>
                    <Button
                        type="button"
                        className="ml-2"
                        onClick={this.handleNewStep}
                    >
                        Añadir
                    </Button>
                </div>

                <div className="flex mt-4">
                    <Button
                        type="button"
                        className="ml-auto"
                        onClick={this.handleReset}
                        theme="outline"
                    >
                        Reset
                    </Button>

                    <Button className="ml-2">
                        Guardar
                    </Button>
                </div>
            </form>

            { editingStep && <EditStepModal
                step={editingStep}
                descriptionName={descriptionName}
                blockName={block.name}
                onRequestClose={this.hideStepModal}
                onAccept={this.handleAccept}
            /> }
        </ReactModal>
    }

    handleAccept = step => {
        this.setState(produce(draft => {
            draft.block.steps[draft.editingStepIdx] = step
        }))
        this.hideStepModal()
    }

    hideStepModal = () => {
        this.setState({
            editingStepIdx: null
        })
    }

    handleSubmit = event => {
        event.preventDefault()

        this.props.onAccept(cloneDeep(this.state.block))
    }

    handleInput = (path, value) => {
        this.setState(produce(draft => {
            set(draft, path, value)
        }))
    }

    handleDragEnd = (fromIdx, toIdx) => {
        this.setState(produce(draft => {
            const { steps } = draft.block
            reorderArray(steps, fromIdx, toIdx)
        }))
    }

    handleCloneStep = (idx) => {
        this.setState(produce(draft => {
            let step = draft.block.steps[idx]
            step = cloneDeep(step)
            delete step.id
            draft.block.steps.push(step)
        }))
    }

    handleDeleteStep = (idx) => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        this.setState(produce(draft => {
            draft.block.steps.splice(idx, 1)
        }))
    }

    handleStepClick = (step, idx) => {
        this.setState({
            editingStepIdx: idx,
        })
    }

    handleReset = () => {
        this.setState({
            block: cloneDeep(this.initialBlock)
        })
    }

    handleNewStep = () => {
        if (this.state.newStepName.trim().length == 0) {
            this.props.onNotify("warn", "Empty step name")
            return
        }

        this.setState(produce(draft => {
            draft.block.steps.push({
                name: draft.newStepName,
                ref: "",
                type: draft.newStepType,
                parTime: 0,
                critical: false,
            })
            draft.newStepName = ""
        }))
    }
})
