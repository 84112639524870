import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import deburr from "lodash/deburr"
import { DateTime } from "luxon"
import cn from "classnames"
import { Link } from "react-router-dom"
import Calendar from "react-calendar"
import { usePopper } from "react-popper"

import {
    IoMdReturnLeft
} from "react-icons/io"

import {
    FaAngleLeft,
    FaAngleRight,
    FaCalculator,
    FaFileCsv,
} from "react-icons/fa"

import {
    useOnMousedownOutside,
    clamp,
    doDownload,
} from "~/lib/utils"

import {
    refreshStockGraphSnapshots,
    refreshStockGraph,
    setStockDate,
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"

import StockItemRow from "~/components/StockItemRow"
import StockNeedsModal from "~/components/StockNeedsModal"

function queryItems(items, query, offset, limit) {
    query = deburr(query.trim()).toLowerCase()

    if (query.length <= 2) return {
        results: items.slice(offset, offset + limit),
        count: items.length
    }

    let results = items.filter(item => {
        const name = deburr(item.name).toLowerCase()

        if (name.includes(query)) {
            return true
        }

        if (item.pn.toLowerCase().includes(query)) {
            return true
        }

        if (item.mpn.toLowerCase().includes(query)) {
            return true
        }

        if (item.locator.toLowerCase().includes(query)) {
            return true
        }

        return false
    })

    const count = results.length
    results = results.slice(offset, offset + limit)

    return { results, count }
}

export default function StockView() {
    const [query,setQuery] = useState("")
    const [offset, setOffset] = useState(0)
    const [limit] = useState(20) // const
    const [showStockNeedsModal, setShowStockNeedsModal] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)

    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)

    useOnMousedownOutside(popperElement, () => setShowPopUp(false))
    const { styles, attributes } = usePopper(referenceElement, popperElement)

    const currentUser   = useSelector(state => state.app.users[state.app.currentUserId])
    const items         = useSelector(state => state.app.stock.items)
    const totalCosts    = useSelector(state => state.app.stock.totalCosts)
    const date          = useSelector(state => state.app.stock.date)
    const snapshotDates = useSelector(state => state.app.stock.snapshotDates)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(refreshStockGraphSnapshots())
        dispatch(refreshStockGraph())
    }, [dispatch])

    const {
        results: filteredItems,
        count
    } = queryItems(Object.values(items), query, offset, limit)

    const currentPage = 1 + Math.floor(offset / limit)
    const totalPages = Math.ceil(count / limit)

    const now = DateTime.utc()

    const tileContent = ({ date }) => {
        date = DateTime.fromJSDate(date)
        const hasSnapshot = snapshotDates.some(d => DateTime.fromISO(d).hasSame(date, "day"))
        if (hasSnapshot) {
            return <div>
                •
            </div>
        } else {
            return null
        }
    }

    const tileDisabled = ({ date }) => {
        date = DateTime.fromJSDate(date)
        return !(snapshotDates.some(d => DateTime.fromISO(d).hasSame(date, "day")) || now.hasSame(date, "day"))
    }

    const handlePageClick = dir => {
        const count = Object.keys(items).length
        setOffset(clamp(0,
            limit * Math.floor(count / limit),
            offset + dir * limit))
    }

    const handleClickDownloadCsv = event => {
        event.preventDefault()

        const csv = [
            `PN, MPN, Cantidad, Precio (€/Unidad), Precio Total (€), holdedId`
        ].concat(Object.values(items).map(i => `${i.pn}, ${i.mpn.replaceAll(",", "")}, ${i.amount}, ${i.cost}, ${i.cost * i.amount}, ${i.holdedId}`)).join("\n")

        doDownload(new Blob([csv], { type: "text/csv" }), `stock_snapshot_${date}.csv`)
    }

    return <>
        <div className={cn("max-w-6xl mx-auto p-2", {
            "bg-yellow-200": !now.hasSame(DateTime.fromISO(date), "day")
        })}>
            <div className="flex items-center mtsp-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Stock
                </h1>

                { currentUser.admin && <div className="ml-auto flex">
                    <Button
                        className="mlsp-2"
                        onClick={handleClickDownloadCsv}
                    >
                        <FaFileCsv className="mr-1" />
                        Descargar
                    </Button>
                    <div
                        className="mlsp-2"
                        ref={setReferenceElement}
                    >
                        <Button
                            onClick={() => setShowPopUp(true)}
                            theme="outline"
                        >
                            TIME TRAVELING
                        </Button>
                    </div>

                    <Link
                        to="/stock-transactions"
                        className="mlsp-2"
                    >
                        <Button theme="outline">
                            Transacciones
                        </Button>
                    </Link>
                    <Button
                        onClick={() => setShowStockNeedsModal(true)}
                        theme="outline"
                        className="mlsp-2 self-start"
                    >
                        <FaCalculator className="mr-1" />
                        Previsiones
                    </Button>
                </div> }
            </div>

            <div className="my-2">
                <label className="text-xs uppercase">
                    Búsqueda
                </label>
                <Input
                    value={query}
                    placeholder="Nombre, PN, MPN o localización"
                    onChange={v => setQuery(v)}
                />
            </div>

            <div className="flex mb-2">
                <span className="flex items-center">
                    <Button
                        title="Página anterior"
                        theme="outline"
                        style="compact"
                        onClick={() => handlePageClick(-1)}
                    >
                        <FaAngleLeft />
                    </Button>
                    <span className="mx-2 text-sm text-gray-700 font-bold">
                        { currentPage } / { totalPages }
                    </span>
                    <Button
                        title="Página siguiente"
                        theme="outline"
                        style="compact"
                        onClick={() => handlePageClick(1)}
                    >
                        <FaAngleRight />
                    </Button>
                </span>
            </div>

            <div className="flex pl-12 text-sm text-gray-700">
                <div className="w-4/12 flex-grow mlsp-1">
                    Identificación
                </div>
                { currentUser.admin && <div className="w-1/12 flex-grow text-center mlsp-1">
                    Fabricante
                </div> }
                { currentUser.admin && <div className="w-1/12 flex-grow text-center mlsp-1">
                    Proveedor
                </div> }
                <div className="w-1/12 text-center mlsp-1">
                    Almacén
                </div>
            </div>

            { filteredItems.map(item => <StockItemRow
                className="-mtsp-1"
                totalCosts={totalCosts}
                item={item}
                key={item.id}
            />) }
        </div>

        { showStockNeedsModal && <StockNeedsModal
            onRequestClose={() => setShowStockNeedsModal(false)}
        /> }

        { showPopUp && <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="bg-white rounded shadow overflow-hidden text-gray-700 p-2"
        >
            <Calendar
                minDetail="year"
                value={DateTime.fromISO(date).toJSDate()}
                onChange={v => {
                    dispatch(setStockDate(DateTime.fromJSDate(v).toISO()))
                    dispatch(refreshStockGraph())
                } }
                tileContent={tileContent}
                tileDisabled={tileDisabled}
            />
        </div> }
    </>
}
