import React from "react"
import { connect } from "react-redux"
import { nanoid } from "nanoid"
import urlJoin from "url-join"
import { Link } from "react-router-dom"
import axios from "axios"

import {
    IoMdReturnLeft
} from "react-icons/io"

import {
    refreshManuals,
    deleteManual,
    uploadManual,
    notify,
    updateManualFilters,
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"
import Select from "~/components/Select"
import SpinnerModal from "~/components/SpinnerModal"
import PreviewModal from "~/components/PreviewModal"
import ReplaceManualVersionModal from "~/components/ReplaceManualVersionModal"
import ManualItem from "~/components/ManualItem"
import ManualVersionsModal from "~/components/ManualVersionsModal"
import UploadManualVersionModal from "~/components/UploadManualVersionModal"

const mapStateToProps = state => ({
    currentUser: state.app.users[state.app.currentUserId],
    manuals: state.app.manuals.manuals,
    filters: state.app.manuals,
})

const mapDispatchToProps = dispatch => ({
    onRefreshManuals: () => dispatch(refreshManuals()),
    onDeleteManual: (manual) => dispatch(deleteManual(manual)),
    onUploadManual: (...args) => dispatch(uploadManual(...args)),
    onNotify: (kind, text) => dispatch(notify(kind, text)),
    onUpdateFilters: (...args) => dispatch(updateManualFilters(...args)),
})

const MANUAL_TYPES = [
    "manufacture",
    "process",
]

export default connect(mapStateToProps, mapDispatchToProps)(class ManualsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            type: "manufacture",
            inputFileKey: nanoid(),
            previewUrl: "",
            showSpinner: false,

            replacingManual: null,  // { manual: Manual, version: ManualVersion }
            historyManual: null,    // Manual
            updatingManual: null,   // Manual
        }

        this.props.onRefreshManuals()
    }

    render() {
        const {
            currentUser,
            manuals,
            filters,
        } = this.props
        const {
            inputFileKey,
            previewUrl,
        } = this.state

        if (!currentUser) return ""

        return <div className="max-w-4xl mx-auto py-2">
            <div className="flex items-center mb-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Manuales
                </h1>
            </div>

            { currentUser.admin && <form
                className="flex items-start mt-2 p-4 bg-blue-200 rounded"
                onSubmit={this.handleFormSubmit}
            >
                <div className="mlsp-2">
                    <label className="uppercase text-xs">
                        tipo
                    </label>
                    <Select
                        value={this.state.type}
                        onChange={this.handleInput("type")}
                    >
                        { MANUAL_TYPES.map(type => <option
                            key={type}
                            value={type}
                        >
                            { type }
                        </option>) }
                    </Select>
                </div>
                <div className="mlsp-2">
                    <label className="uppercase text-xs block">
                        archivos
                    </label>
                    <input
                        ref={el => this.inputFile = el}
                        accept=".pdf"
                        type="file"
                        key={inputFileKey}
                    />
                </div>
                <Button className="ml-auto self-center">
                    Subir
                </Button>
            </form> }

            <div className="mt-4 flex">
                <div className="pr-2 w-1/2">
                    <label className="uppercase text-xs">
                        nombre
                    </label>
                    <Input
                        value={filters.name}
                        onChange={this.handleSetFilter("name")}
                    />
                </div>
                <div className="pl-2 w-1/2">
                    <label className="uppercase text-xs">
                        tipo
                    </label>
                    <Select
                        value={filters.type}
                        onChange={this.handleSetFilter("type")}
                    >
                        <option value="any">
                            -
                        </option>
                        { MANUAL_TYPES.map(type => <option
                            key={type}
                            value={type}
                        >
                            { type }
                        </option>) }
                    </Select>
                </div>
            </div>

            <div className="mt-2">
                { manuals.map(manual => <ManualItem
                    key={manual.id}
                    manual={manual}
                    className="mtsp-2"
                    onHistory={() => this.handleHistoryManual(manual)}
                    onPreview={() => this.handlePreviewManualVersion(manual.versions[manual.versions.length - 1])}
                    onUpdate={() => this.handleUpdateManual(manual)}
                    onDelete={() => this.handleDeleteManual(manual)}
                />) }
            </div>

            { previewUrl && <PreviewModal
                onRequestClose={this.handleStopPreview}
                url={previewUrl}
            /> }

            { this.state.showSpinner && <SpinnerModal /> }

            { this.state.replacingManual && <ReplaceManualVersionModal
                manual={this.state.replacingManual.manual}
                version={this.state.replacingManual.version}
                onRequestClose={() => this.setState({ replacingManual: null })}
            /> }

            { this.state.updatingManual && <UploadManualVersionModal
                manual={this.state.updatingManual}
                onRequestClose={() => this.setState({ updatingManual: null })}
            /> }

            { this.state.historyManual && <ManualVersionsModal
                manual={this.state.historyManual}
                onPreview={v => this.handlePreviewManualVersion(v)}
                onReplace={v => this.handleReplaceManualVersion(this.state.historyManual, v)}
                onRequestClose={() => this.setState({ historyManual: null })}
            /> }
        </div>
    }

    handleInput = prop => value => {
        this.setState({ [prop]: value })
    }

    handleSetFilter = prop => value => {
        this.props.onUpdateFilters({ [prop]: value })
    }

    handleStopPreview = () => {
        this.setState({
            previewUrl: ""
        })
    }

    handleHistoryManual = manual => {
        this.setState({
            historyManual: manual
        })
    }

    handlePreviewManualVersion = (version) => {
        this.setState({
            /* global API_BASE_URL */
            previewUrl: urlJoin(API_BASE_URL, "manuals", version.id, "file")
        })
    }

    handleReplaceManualVersion = (manual, version) => {
        this.setState({
            replacingManual: { manual, version }
        })
    }

    handleDeleteManual = (manual) => {
        this.props.onDeleteManual(manual)
    }

    handleUpdateManual = (manual) => {
        this.setState({
            updatingManual: manual
        })
    }

    handleFormSubmit = async (event) => {
        event.preventDefault()

        const files = Array.from(this.inputFile.files)
        if (files.length == 0) return

        for (let file of files) {
            const response = await axios.get(`/manuals?name=${file.name}`)
            if (response.data.length > 0) {
                window.alert(`Ya hay un manual con el nombre "${file.name}".`)
                return
            }
        }

        this.setState({ showSpinner: true })
        try {
            await this.props.onUploadManual(files, this.state.type)
        } finally {
            this.setState({ showSpinner: false })
        }
        this.setState({ inputFileKey: nanoid() })
    }
})
