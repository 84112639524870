import React from "react"
import urlJoin from "url-join"

import {
    FaCheck,
    FaFilePdf,
    FaSpinner,
} from "react-icons/fa"

import Button from "~/components/Button"
import PdfViewer from "~/components/PdfViewer"

/* global API_BASE_URL */
export default class StepCheck extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            thinking: false
        }
    }

    render() {
        const {
            step,
            blocked,
            tooSoon,
            headless
        } = this.props
        const {
            thinking
        } = this.state

        return <>
            <div className="relative">
                { step.manualVersionId && <PdfViewer
                    fit={headless ? "height" : "width"}
                    url={urlJoin(API_BASE_URL, "manuals", step.manualVersionId, "file")}
                    pageNumber={step.pageNumber}
                /> }
                { !step.manualVersionId && <div className="mt-20 text-gray-700 text-lg font-extralight flex items-center justify-center">
                    <FaFilePdf />
                    <span className="ml-2">
                        This step has no manual.
                    </span>
                </div> }
            </div>

            <div className="shadow-md-bottom fixed bottom-0 inset-x-0">
                <form
                    className="flex"
                    onSubmit={this.handleFormSubmit}
                >
                    <div className="p-2 flex items-center flex-1">
                    </div>
                    <div className="py-2 pr-2">
                        <Button
                            title="Confirmar"
                            className="shadow-md"
                            style="big-circle"
                            disabled={blocked || tooSoon || thinking}
                        >
                            { thinking ? <FaSpinner className="spin" /> : <FaCheck /> }
                        </Button>
                    </div>
                </form>
            </div>
        </>
    }

    handleFormSubmit = async event => {
        event.preventDefault()

        this.props.onInteraction()

        this.setState({ thinking: true })
        try {
            await this.props.onDone(true)
        } finally {
            this.setState({ thinking: false })
        }
    }
}
