import React from "react"
import cn from "classnames"
import { connect } from "react-redux"

import{
    ImSortAmountAsc,
    ImSortAmountDesc,
} from "react-icons/im"

import Button from "~/components/Button"
import Input from "~/components/Input"
import Select from "~/components/Select"
import InputDateRange from "~/components/InputDateRange"

import {
    updateBatchFilters
} from "~/store"

const mapStateToProps = state => ({
    users:         state.app.users,
    currentUserId: state.app.currentUserId,

    ...state.app.batches.filters,
})

const mapDispatchToProps = dispatch => ({
    onUpdateFilter: (filters) => dispatch(updateBatchFilters(filters))
})

const ORDERS = [
    { name: "Fecha de creación",     value: "createdAt" },
    { name: "Fecha de finalización", value: "doneAt" },
    { name: "Fecha objetivo",        value: "finishAt" },
]

export default connect(mapStateToProps, mapDispatchToProps)(class AdvancedSearch extends React.Component {
    render() {
        const {
            className,
            pn,
            doneAt,
            finishAt,
            enabledAt,
            client,
            uploadedAt,
            shippingAt,
            code, // batch
            sn,
            erpReference,
            createdAt,
            orderDir,
            orderBy,
            ownedBy,
        } = this.props

        return <div className={cn(className, "flex")}>
            <div className="w-1/2 pr-2">
                <div>
                    <label className="text-xs uppercase">
                        Batch Code
                    </label>
                    <Input
                        value={code}
                        onChange={v => this.handleSetFilter("code", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Serial Number
                    </label>
                    <Input
                        value={sn}
                        onChange={v => this.handleSetFilter("sn", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        PN
                    </label>
                    <Input
                        value={pn}
                        onChange={v => this.handleSetFilter("pn", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Cliente
                    </label>
                    <Input
                        value={client}
                        onChange={v => this.handleSetFilter("client", v)}
                    />
                </div>

                <div>
                    <label className="text-xs uppercase">
                        Pedido
                    </label>
                    <Input
                        value={erpReference}
                        onChange={v => this.handleSetFilter("erpReference", v)}
                    />
                </div>

                <div>
                    <label className="text-xs uppercase">
                        Asignado a
                    </label>
                    <div className="flex">
                        <Input
                            className="mlsp-2"
                            value={ownedBy}
                            onChange={v => this.handleSetFilter("ownedBy", v)}
                        />
                        <Button
                            className="mlsp-2"
                            theme="outline"
                            onClick={this.handleMeClick}
                        >
                            Yo
                        </Button>
                    </div>
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Orden
                    </label>
                    <div className="flex">
                        <Select
                            className="w-full mlsp-2"
                            value={orderBy}
                            onChange={v => this.handleSetFilter("orderBy", v)}
                        >
                            { ORDERS.map(order => <option
                                key={order.value}
                                value={order.value}
                            >
                                { order.name }
                            </option>) }
                        </Select>
                        <Button
                            className="mlsp-2"
                            theme="outline"
                            onClick={() => this.handleSetFilter("orderDir", orderDir == "ASC" ? "DESC" : "ASC")}
                        >
                            { orderDir == "ASC" ? <ImSortAmountAsc /> : <ImSortAmountDesc /> }
                        </Button>
                    </div>
                </div>
            </div>

            <div className="w-1/2 pl-2">
                <div>
                    <label className="text-xs uppercase">
                        Fecha de creación
                    </label>
                    <InputDateRange
                        value={createdAt}
                        onChange={v => this.handleSetFilter("createdAt", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Fecha de inicio
                    </label>
                    <InputDateRange
                        value={enabledAt}
                        onChange={v => this.handleSetFilter("enabledAt", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Fecha objetivo
                    </label>
                    <InputDateRange
                        value={finishAt}
                        onChange={v => this.handleSetFilter("finishAt", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Fecha de finalización
                    </label>
                    <InputDateRange
                        value={doneAt}
                        onChange={v => this.handleSetFilter("doneAt", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Fecha de publicación
                    </label>
                    <InputDateRange
                        value={uploadedAt}
                        onChange={v => this.handleSetFilter("uploadedAt", v)}
                    />
                </div>
                <div>
                    <label className="text-xs uppercase">
                        Fecha de envío
                    </label>
                    <InputDateRange
                        value={shippingAt}
                        onChange={v => this.handleSetFilter("shippingAt", v)}
                    />
                </div>
            </div>
        </div>
    }

    handleSetFilter = (prop, value) => {
        this.props.onUpdateFilter({ [prop]: value })
    }

    handleMeClick = () => {
        const {
            users,
            currentUserId
        } = this.props

        const u = users[currentUserId]

        this.props.onUpdateFilter({
            ownedBy: u.name
        })
    }
})
