import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

import {
    IoMdReturnLeft
} from "react-icons/io"

import Button from "~/components/Button"
import Input from "~/components/Input"

export default function TagsView() {
    const [tags, setTags] = useState([])

    const refreshTags = async () => {
        const response = await axios.get("/tags")
        setTags(response.data)
    }

    useEffect(() => { refreshTags() }, [])

    const [ref, setRef] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault()
        await axios.post("/tags", { ref })
        setRef("")
        await refreshTags()
    }

    const handleDelete = async (tag) => {
        await axios.delete(`/tags/${tag.id}`)
        await refreshTags()
    }

    return <div className="p-2 max-w-xl mx-auto">
        <div className="flex items-center">
            <Link to="/">
                <Button theme="transparent">
                    <IoMdReturnLeft />
                </Button>
            </Link>
            <h1 className="text-xl ml-2">
                Tags
            </h1>
        </div>

        <div className="mt-4">
            { tags.map(tag => <div
                key={tag.id}
                className="py-1 px-2 border flex items-center rounded"
            >
                <div>
                    { tag.ref }
                </div>
                <Button
                    className="ml-auto"
                    style="compact"
                    theme="danger"
                    onClick={() => handleDelete(tag)}
                >
                    Borrar
                </Button>
            </div>) }
        </div>

        <form
            className="flex mt-4"
            onSubmit={handleSubmit}
        >
            <div>
                <label className="uppercase text-xs">
                    Ref
                </label>
                <Input
                    value={ref}
                    onChange={v => setRef(v)}
                />
            </div>
            <Button className="self-end ml-2">
                Crear
            </Button>
        </form>
    </div>
}
