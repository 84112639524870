import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import axios from "axios"
import { DateTime } from "luxon"

import {
    FaWindowClose,
    FaPlus,
    FaCheck,
} from "react-icons/fa"

import {
    obj2qs,
} from "~/lib/utils"

import useDebounce from "~/lib/useDebounce"

import Button from "~/components/Button"
import Input from "~/components/Input"

export default function MatchModal({
    batch,
    instance,

    onMatch,
    onRequestClose,
}) {
    const [code, setCode]     = useState("")
    const [count, setCount]   = useState(-1)
    const [groups, setGroups] = useState([])

    const debouncedCode = useDebounce(code, 500)

    useEffect(() => {
        let qs = {
            pn: batch.pn,
            limit: 5,
        }

        if (debouncedCode.trim()) qs.code = debouncedCode.trim()
        qs = obj2qs(qs)

        axios.get(`/match-groups?${qs}`)
        .then(response => {
            setGroups(response.data.results)
            setCount(response.data.count)
        })
    }, [batch.pn, debouncedCode])

    return <ReactModal
        className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
        onRequestClose={onRequestClose}
        isOpen
    >
        <Button
            className="absolute left-0 top-0"
            style="compact"
            theme="transparent"
            onClick={onRequestClose}
        >
            <FaWindowClose />
        </Button>

        <div>
            <div className="flex items-baseline text-xl">
                { batch.name } ({ batch.pn })
            </div>
            <div>
                { instance.code }
            </div>
        </div>

        <div>
            <label className="uppercase text-xs">
                Filtrar
            </label>
            <Input
                value={code}
                onChange={v => setCode(v)}
            />
        </div>

        <div className="text-xs text-gray-700">
            { count } grupos
        </div>

        <div className="mt-4">
            { groups.map(group => <div
                key={group.id}
                className="mtsp-2 flex items-center"
            >
                <Button
                    style="compact"
                    theme="outline"
                    onClick={() => onMatch(group)}
                >
                    <FaCheck />
                </Button>
                <div className="ml-2">
                    { group.code }
                </div>
                <div className="ml-2 text-sm">
                    ({ group.count } instancias)
                </div>
                <div className="ml-2 text-sm">
                    { DateTime.fromISO(group.createdAt).toLocaleString(DateTime.DATETIME_SHORT) }
                </div>
            </div>) }

            { groups.length == 0 && <div className="text-gray-700">
                No hay ningún grupo de matcheo disponible.
            </div> }
        </div>

        <div className="mt-4">
            <Button onClick={() => onMatch()}>
                <FaPlus className="mr-1" />
                Nuevo grupo
            </Button>
        </div>
    </ReactModal>
}
