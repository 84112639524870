import React from "react"
import produce from "immer"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
    FaSuperpowers,
    FaPlus,
    FaPencilAlt,
} from "react-icons/fa"

import {
    IoMdReturnLeft
} from "react-icons/io"

import {
    createUser,
    updateUser,
    refreshUsers,
    notify,
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"
import SpinnerModal from "~/components/SpinnerModal"

const mapStateToProps = state => ({
    users: state.app.users
})
const mapDispatchToProps = dispatch => ({
    onRefreshUsers: (...args) => dispatch(refreshUsers(...args)),
    onCreateUser:   (...args) => dispatch(createUser(...args)),
    onUpdateUser:   (...args) => dispatch(updateUser(...args)),
    onNotify:       (...args) => dispatch(notify(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(class UsersView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            passwords: {},
            name: "",
            email: "",
            password: "",
            showSpinner: false,
        }

        this.props.onRefreshUsers()
    }

    render() {
        const {
            passwords,
            name,
            email,
            password,
            showSpinner,
        } = this.state

        const {
            users,
        } = this.props

        return <div className="max-w-4xl mx-auto pt-2">
            <div className="flex items-center mb-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Usuarios
                </h1>
            </div>

            <table className="w-full border border-gray-500 p-1">
                <thead>
                    <tr className="border-b border-gray-500">
                        <th className="px-2 border-r border-gray-500">
                            Nombre
                        </th>
                        <th className="px-2 border-r border-gray-500">
                            Email
                        </th>
                        <th className="px-2">
                            Contraseña
                        </th>
                        <th className="px-">
                            Activo
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { Object.values(users).map((user, idx) => <tr
                        className="odd:bg-gray-200"
                        key={idx}
                    >
                        <td className="px-2 py-1 border-r border-gray-500">
                            <div className="flex items-center">
                            <div className="text-sm">
                                { user.name }
                            </div>
                            { !!user.admin && <FaSuperpowers className="ml-2" /> }
                            </div>
                        </td>
                        <td className="px-2 py-1 border-r border-gray-500">
                            { user.email }
                        </td>
                        <td className="px-2 py-1 border-r border-gray-500">
                            <form
                                className="flex items-center"
                                onSubmit={e => this.handleUpdateUser(e, user)}
                            >
                                <Input
                                    className="mlsp-2"
                                    type="password"
                                    value={passwords[user.id] || ""}
                                    onChange={v => this.handlePasswordInput(user, v)}
                                />
                                <Button className="mlsp-2">
                                    <FaPencilAlt />
                                </Button>
                            </form>
                        </td>
                        <td className="px-2 py-1 border-r border-gray-500">
                            <input
                                type="checkbox"
                                checked={user.active}
                                onChange={e => this.handleActiveInput(user, e)}
                            />
                        </td>
                    </tr>) }
                </tbody>
            </table>

            <form
                className="mt-4"
                onSubmit={e => this.handleCreateUser(e)}
            >
                <div className="flex">
                    <div className="mlsp-4">
                        <label>Nombre</label>
                        <Input
                            value={name}
                            onChange={this.handleInput("name")}
                        />
                    </div>
                    <div className="mlsp-4">
                        <label>Email</label>
                        <Input
                            value={email}
                            onChange={this.handleInput("email")}
                        />
                    </div>
                    <div className="mlsp-4">
                        <label>Contraseña</label>
                        <Input
                            value={password}
                            type="password"
                            onChange={this.handleInput("password")}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <Button>
                        <FaPlus className="mr-1" />
                        Crear
                    </Button>
                </div>
            </form>

            { showSpinner && <SpinnerModal /> }
        </div>
    }

    handleActiveInput = async (user, e) => {
        const active = e.target.checked

        this.setState({ showSpinner: true })
        try {
            await this.props.onUpdateUser(user.id, { active })
        } finally {
            this.setState({ showSpinner: false })
        }

    }

    handleCreateUser = async event => {
        event.preventDefault()

        const {
            password,
            email,
            name,
        } = this.state

        if (!password.trim()) {
            this.props.onNotify("warn", "Empty password")
            return
        }
        if (!email.trim()) {
            this.props.onNotify("warn", "Empty email")
            return
        }
        if (!name.trim()) {
            this.props.onNotify("warn", "Empty name")
            return
        }

        this.setState({ showSpinner: true })
        try {
            await this.props.onCreateUser(password, name, email)
            this.setState({
                password: "",
                email: "",
                name: "",
            })
        } finally {
            this.setState({ showSpinner: false })
        }
    }

    handleUpdateUser = async (event, user) => {
        event.preventDefault()

        const password = this.state.passwords[user.id]
        if (!password || !password.trim()) {
            this.props.onNotify("warn", "Empty password")
            return
        }

        this.setState({ showSpinner: true })
        try {
            await this.props.onUpdateUser(user.id, { password })
            this.setState(produce(draft => {
                draft.passwords[user.id] = ""
            }))
        } finally {
            this.setState({ showSpinner: false })
        }
    }

    handleInput = prop => v => this.setState({ [prop]: v })

    handlePasswordInput = (user, password) => {
        this.setState(produce(draft => {
            draft.passwords[user.id] = password
        }))
    }
})
