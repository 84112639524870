import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
    FaAngleLeft,
    FaAngleRight,
} from "react-icons/fa"

import { clamp } from "~/lib/utils"

import PdfViewer from "~/components/PdfViewer"
import Button from "~/components/Button"

export default class PreviewModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pageNumber: 1,
            numPages: -1,
            active: false,
        }
    }

    render() {
        const {
            onRequestClose,
            url,
        } = this.props

        const {
            pageNumber,
            numPages,
            active,
        } = this.state

        const noMoreLeft  = pageNumber <= 1
        const noMoreRight = pageNumber >= numPages

        return <ReactModal
            className="m-8 p-4 mx-auto max-w-6xl relative bg-white rounded overflow-hidden"
            isOpen
            onAfterOpen={this.handleAfterOpen}
            onRequestClose={onRequestClose}
        >
            <Button
                className="absolute left-0 top-0 z-10"
                style="compact"
                theme="transparent"
                onClick={onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <PdfViewer
                url={url}
                pageNumber={pageNumber}
                onReady={this.handleReady}
                onRenderSuccess={this.handleRenderSuccess}
            />

            { numPages != -1 && <div className="p-4 absolute top-0 inset-x-0 flex items-start justify-between opacity-75">
                <Button
                    style="circle"
                    onClick={this.handleLeft}
                    disabled={!active || noMoreLeft}
                >
                    <FaAngleLeft />
                </Button>

                <span className="text-xs bg-white font-semibold rounded px-2">
                    { pageNumber } / { numPages }
                </span>

                <Button
                    style="circle"
                    onClick={this.handleRight}
                    disabled={!active || noMoreRight}
                >
                    <FaAngleRight />
                </Button>
            </div> }
        </ReactModal>
    }

    handleAfterOpen = () => {
        this.setState({
            pageNumber: 1,
            numPages: -1,
            active: false,
        })
    }

    handleReady = ({ numPages }) => {
        this.setState({
            numPages,
            active: true,
        })
    }

    handleRenderSuccess = () => {
        this.setState({
            active: true,
        })
    }

    handleLeft = () => {
        this.setState(s => ({
            pageNumber: clamp(1, s.numPages, s.pageNumber - 1),
            active: false,
        }))
    }

    handleRight = () => {
        this.setState(s => ({
            pageNumber: clamp(1, s.numPages, s.pageNumber + 1),
            active: false,
        }))
    }
}
