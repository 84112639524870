import React from "react"
import classNames from "classnames"

export default class TextArea extends React.Component {
    focus() {
        this.el.focus()
    }

    render() {
        const {
            value,
            defaultValue,
            className,
            rows,
            disabled,
            onChange,
            onBlur,
        } = this.props

        const areaClass = classNames("block py-3 px-4 w-full rounded leading-tight bg-white border-gray-200 border focus:outline-none focus:border-gray-500", className, {
            "text-gray-500": disabled,
            "bg-gray-100": disabled,
        })

        return <textarea
            ref={el => this.el = el}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            rows={rows}
            onChange={e => onChange && onChange(e.target.value)}
            onBlur={onBlur}
            className={areaClass}
        />
    }
}
