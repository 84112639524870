import React from "react"
import cn from "classnames"

import {
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

// TODO dupped
// ns: normalized standard deviation
const healthClass = ns => cn({
    "bg-green-200": ns < 0.1,
    "bg-orange-200": ns >= 0.1 && ns < 0.3,
    "bg-red-200": ns >= 0.3,
})

export default class GravityMetrics extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            folded: true,
        }
    }

    render() {
        return <div className={cn("p-2 relative", this.props.className)}>
            <h2 className="text-2xl font-extralight">
                Gravity
            </h2>

            <button
                className="absolute right-0 top-0 text-gray-300 bg-gray-700 hover:bg-gray-600 p-2 rounded-bl rounded-tr"
                onClick={this.toggleFolded}
            >
                { this.state.folded ? <FaChevronDown /> : <FaChevronUp /> }
            </button>

            { !this.state.folded && <>
                { Object.entries(this.props.gravityMetrics).map(([pn, values]) => <div
                    key={pn}
                >
                    <div className="font-semibold">
                        { pn } metrics
                    </div>

                    <table className="w-full bg-gray-200">
                        <thead>
                            <tr>
                                <th>
                                    metric
                                </th>
                                <th>
                                    avg
                                </th>
                                <th>
                                    std dev
                                </th>
                                <th>
                                    std dev (normalized)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { Object.entries(values).map(([metric, [avg, s]]) => <tr
                                className={healthClass(Math.abs(avg == 0 ? 0 : s / avg))}
                                key={metric}
                            >
                                <td>
                                    { metric }
                                </td>
                                <td className="text-center">
                                    { avg?.toFixed(3) }
                                </td>
                                <td className="text-center">
                                    { s?.toFixed(4) }
                                </td>
                                <td className="text-center">
                                    { (avg == 0 ? 0 : s / avg).toFixed(3) }
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>) }

                { Object.entries(this.props.gravityTimes).map(([pn, { attack, release }]) => <div
                    key={pn}
                >
                    <div className="font-semibold">
                        { pn } times
                    </div>

                    <div className="flex">
                        <div className="flex-1 mlsp-2">
                            <table className="w-full">
                                <caption className="font-semibold text-sm bg-gray-200">
                                    Attack
                                </caption>
                                <thead className="font-semibold text-sm bg-gray-200">
                                    <tr>
                                        <th>
                                            Position
                                        </th>
                                        <th>
                                            Average
                                        </th>
                                        <th>
                                            std dev
                                        </th>
                                        <th>
                                            std dev (normalized)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { attack.map(([left, right], index) => <tr
                                        className="hover:bg-gray-100"
                                        key={index}
                                    >
                                        <td className="px-2 border-l border-r border-gray-600 text-center">
                                            { index + 1 }
                                        </td>
                                        <td className="px-2 border-l border-r border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { left[0].toFixed(3) } ms
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { right[0].toFixed(3) } ms
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-2 border-l border-r border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { left[1].toFixed(3) } ms
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { right[1].toFixed(3) } ms
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-2 border-l border-r border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { (left[1] / left[0]).toFixed(3) }
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { (right[1] / right[0]).toFixed(3) }
                                                </span>
                                            </div>
                                        </td>
                                    </tr>) }
                                </tbody>
                            </table>
                        </div>

                        <div className="flex-1 mlsp-2">
                            <table className="w-full">
                                <caption className="font-semibold text-sm bg-gray-200">
                                    Release
                                </caption>
                                <thead className="font-semibold text-sm bg-gray-200">
                                    <tr>
                                        <th>
                                            Position
                                        </th>
                                        <th>
                                            Average
                                        </th>
                                        <th>
                                            std dev
                                        </th>
                                        <th>
                                            std dev (normalized)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { release.map(([left, right], index) => <tr
                                        className="hover:bg-gray-100"
                                        key={index}
                                    >
                                        <td className="px-2 border-r border-l border-gray-600 text-center">
                                            { index + 1 }
                                        </td>
                                        <td className="px-2 border-r border-l border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { left[0].toFixed(3) } ms
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { right[0].toFixed(3) } ms
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-2 border-r border-l border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { left[1].toFixed(3) } ms
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { right[1].toFixed(3) } ms
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-2 border-r border-l border-gray-600">
                                            <div className="flex">
                                                <span className={cn("w-1/2 mlsp-2", healthClass(Math.abs(left[1] / left[0])))}>
                                                    { (left[1] / left[0]).toFixed(3) }
                                                </span>
                                                <span className={cn("w-1/2 mlsp-2 text-right", healthClass(Math.abs(right[1] / right[0])))}>
                                                    { (right[1] / right[0]).toFixed(3) }
                                                </span>
                                            </div>
                                        </td>
                                    </tr>) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>) }
            </> }
        </div>
    }

    toggleFolded = () => {
        this.setState(s => ({
            folded: !s.folded
        }))
    }
}
