import React from "react"
import cn from "classnames"

import {
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

// TODO dupped
// ns: normalized standard deviation
const healthClass = ns => cn({
    "bg-green-200": ns < 0.1,
    "bg-orange-200": ns >= 0.1 && ns < 0.3,
    "bg-red-200": ns >= 0.3,
})

// metrics: {
//  pn: {
//   metric: [avg, devstd]
//  }
// }

export default class SimpleDeviceMetrics extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            folded: true
        }
    }


    render() {
        return <div className={cn("p-2 relative", this.props.className)}>
            <h2 className="text-2xl font-extralight">
                { this.props.name }
            </h2>

            <button
                className="absolute right-0 top-0 text-gray-300 bg-gray-700 hover:bg-gray-600 p-2 rounded-bl rounded-tr"
                onClick={this.toggleFolded}
            >
                { this.state.folded ? <FaChevronDown /> : <FaChevronUp /> }
            </button>

            { !this.state.folded && <>
                { Object.entries(this.props.metrics).map(([pn, values]) => <div
                    className="mtsp-4"
                    key={pn}
                >
                    <div className="font-semibold">
                        { pn }
                    </div>

                    <table className="w-full bg-gray-200">
                        <thead>
                            <tr>
                                <th>
                                    metric
                                </th>
                                <th>
                                    avg
                                </th>
                                <th>
                                    std dev
                                </th>
                                <th>
                                    std dev (normalized)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { Object.entries(values).map(([metric, [avg, s]]) => <tr
                                className={healthClass(Math.abs(avg == 0 ? 0 : s / avg))}
                                key={metric}
                            >
                                <td>
                                    { metric }
                                </td>
                                <td className="text-center">
                                    { avg?.toFixed(3) }
                                </td>
                                <td className="text-center">
                                    { s?.toFixed(4) }
                                </td>
                                <td className="text-center">
                                    { (avg == 0 ? 0 : s / avg).toFixed(3)}
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>) }

                { this.props.children }
            </> }
        </div>
    }

    toggleFolded = () => {
        this.setState(s => ({
            folded: !s.folded
        }))
    }
}
