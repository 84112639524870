import React from "react"
import urlJoin from "url-join"
import cn from "classnames"

import Button from "~/components/Button"

/* global API_BASE_URL */
export default function TreeReport({ className }) {
    return <div className={cn("p-2", className)}>
        <h2 className="text-2xl font-extralight">
            Informe de plantación
        </h2>

        <div className="flex mt-2">
            <a href={urlJoin(API_BASE_URL, "/stats/instances-with-trees")}>
                <Button type="button">
                    Con árbol
                </Button>
            </a>
            <a href={urlJoin(API_BASE_URL, "/stats/instances-without-trees")}>
                <Button
                    type="button"
                    className="ml-2"
                >
                    Sin árbol
                </Button>
            </a>
        </div>
    </div>
}
