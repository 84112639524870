import React from "react"
import { connect } from "react-redux"

import Input from "~/components/Input"
import Button from "~/components/Button"

import { login } from "~/store"

const mapDispatchToProps = dispatch => ({
    onRequestLogin: (email, password) => dispatch(login(email, password))
})

/* global VERSION */
export default connect(null, mapDispatchToProps)(class LoginView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
        }
    }

    render() {
        return <>
            <div className="mt-32 max-w-xl mx-auto p-4">
                <h1 className="text-4xl text-center mb-12 font-extralight">
                    TIERRA Audio ASM
                </h1>

                <form
                    onSubmit={this.handleFormSubmit}
                    className="flex flex-col items-stretch"
                >
                    <div className="mb-3">
                        <label className="text-xs uppercase">
                            email
                        </label>
                        <Input
                            onChange={this.handleEmailInput}
                            value={this.state.email}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="text-xs uppercase">
                            contraseña
                        </label>
                        <Input
                            type="password"
                            onChange={this.handlePasswordInput}
                            value={this.state.password}
                        />
                    </div>
                    <Button>
                        Entrar
                    </Button>
                </form>
            </div>
            <div className="text-xs text-gray-500 text-center p-6">
                { VERSION }
            </div>
        </>
    }

    handleFormSubmit = event => {
        event.preventDefault()
        const { email, password } = this.state
        this.props.onRequestLogin(email, password)
    }

    handleEmailInput = email => {
        this.setState({ email })
    }

    handlePasswordInput = password => {
        this.setState({ password })
    }
})
