import React from "react"
import produce from "immer"

export default class SortList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dragItemIdx: -1, // Number; -1 => no drag item
            dropItemIdx: null, // Number? null => no drag item
        }

        this.elements = []
    }

    getElementIdxFromCoords = (y) => {
        for (let i = 0; i < this.elements.length; i++) {
            const el = this.elements[i]
            const rect = el.getBoundingClientRect()

            const el1 = this.elements[i+1]
            const rect1 = el1?.getBoundingClientRect()

            if (y > rect.top && y < (rect1?.top || Infinity)) {
                return i;
            }
        }
        return -1
    }

    render() {
        const { items, render, renderPlaceholder } = this.props
        const { dragItemIdx, dropItemIdx } = this.state

        return <>
            { dropItemIdx == -1 && renderPlaceholder?.() }
            { items.map((item, idx) => {
                return <React.Fragment key={idx}>
                    { render(item, {
                        index: idx,
                        dragged: dragItemIdx == idx,
                        onMouseDown: e => this.handleMouseDown(e, idx),
                        onTouchStart: e => this.handleTouchStart(e, idx),
                        ref: el => this.elements[idx] = el,
                    }) }
                    { dropItemIdx == idx && renderPlaceholder?.() }
                </React.Fragment>
            }) }
        </>
    }

    handleTouchStart = (event, idx) => {
        const touches = Array.from(event.touches)
        if (touches.length != 1) return
        const touchId = touches[0].identifier

        event.preventDefault()
        if (this.props.disabled) return

        this.setState({
            dragItemIdx: idx,
            dropItemIdx: null,
        })

        this.touchmove = event => {
            const touch = Array.from(event.changedTouches).find(t => t.identifier == touchId)
            if (!touch) return

            event.preventDefault()
            const idx = this.getElementIdxFromCoords(touch.clientY)
            this.setState({ dropItemIdx: idx })
        }

        this.touchend = event => {
            const touches = Array.from(event.changedTouches)
            const touch = touches.find(t => t.identifier == touchId)
            if (!touch) return

            this.setState(produce(draft => {
                const idx = this.getElementIdxFromCoords(touch.clientY)

                const from = this.props.items[draft.dragItemIdx]
                const to   = this.props.items[idx]
                this.props.onDragEnd?.(draft.dragItemIdx, idx, from, to)

                draft.dragItemIdx = -1
                draft.dropItemIdx = null
            }))

            document.removeEventListener("touchmove", this.touchmove)
            document.removeEventListener("touchend", this.touchend)
            document.removeEventListener("touchcancel", this.touchcancel)
        }

        this.touchcancel = event => {
            const touches = Array.from(event.changedTouches)
            const touch = touches.find(t => t.identifier == touchId)
            if (!touch) return

            // TODO onDragCancel?

            this.setState({
                dragItemIdx: -1,
                dropItemIdx: null
            })
        }

        document.addEventListener("touchmove", this.touchmove, { passive: false })
        document.addEventListener("touchend", this.touchend)
        document.addEventListener("touchcancel", this.touchcancel)

        const from = this.props.items[idx]
        this.props.onDragStart?.(idx, from)
    }

    handleMouseDown = (event, idx) => {
        event.preventDefault()
        if (this.props.disabled) return

        this.setState({
            dragItemIdx: idx,
            dropItemIdx: null
        })

        this.mousemove = event => {
            event.preventDefault()

            const idx = this.getElementIdxFromCoords(event.clientY)
            this.setState({ dropItemIdx: idx })
        }

        this.mouseup = event => {
            this.setState(produce(draft => {
                const idx = this.getElementIdxFromCoords(event.clientY)

                const from = this.props.items[draft.dragItemIdx]
                const to   = this.props.items[idx]
                this.props.onDragEnd?.(draft.dragItemIdx, idx, from, to)

                draft.dragItemIdx = -1
                draft.dropItemIdx = null
            }))

            document.removeEventListener("mousemove", this.mousemove)
            document.removeEventListener("mouseup", this.mouseup)
        }

        document.addEventListener("mousemove", this.mousemove)
        document.addEventListener("mouseup", this.mouseup)

        const from = this.props.items[idx]
        this.props.onDragStart?.(idx, from)
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.mousemove)
        document.removeEventListener("mouseup", this.mouseup)
        document.removeEventListener("touchmove", this.touchmove)
        document.removeEventListener("touchend", this.touchend)
        document.removeEventListener("touchcancel", this.touchcancel)
    }
}
