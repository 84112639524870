import React from "react"
import classNames from "classnames"

export default class Input extends React.Component {
    focus() {
        this.input.focus()
    }

    render() {
        const {
            value,
            className,
            onChange,
            type="text",
            min,
            max,
            step,
            disabled,
            placeholder,
            maxLength,
        } = this.props

        const inputClass = classNames("py-3 px-4 block w-full rounded leading-tight bg-white border-gray-200 border focus:outline-none focus:border-gray-500", className, {
            "text-gray-500": disabled,
            "bg-gray-100": disabled,
        })

        return <input
            ref={input => this.input = input}
            value={value}
            type={type}
            min={min}
            max={max}
            step={step || "any"}
            onChange={e => onChange && onChange(e.target.value)}
            onClick={e => this.props.onClick && this.props.onClick(e)}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            className={inputClass}
        />
    }
}
