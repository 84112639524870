import React from "react"

import {
    MdAddAPhoto
} from "react-icons/md"

import Button from "~/components/Button"

export default class InputFile extends React.Component {
    handleClick = event => {
        event.preventDefault()
        this.el.click()
    }

    handleChange = async () => {
        if (this.props.multiple) {
            const files = Array.from(this.el.files)
            this.props.onChange(files)
        } else {
            const file = this.el.files[0]
            if (file) {
                this.props.onChange(file)
            }  else {
                this.props.onChange(null)
            }
        }
    }

    render() {
        const {
            className,
            accept,
            multiple,
        } = this.props

        return <div className="relative">
            <Button onClick={this.handleClick}>
                <MdAddAPhoto />
            </Button>
            <input
                accept={accept}
                className={"pointer-events-none absolute opacity-0 " + className}
                multiple={multiple}
                ref={el => this.el = el}
                type="file"
                onChange={this.handleChange}
            />
        </div>
    }
}

