import React from "react"
import classNames from "classnames"

export default class Button extends React.Component {
    focus() {
        this.button.focus()
    }

    getThemeClass(theme, disabled) {
        switch (theme) {
            case "transparent":
                return {
                    "text-gray-700": true,
                    "hover:bg-gray-100": true,
                }

            case "outline":
                return {
                    "text-blue-600": true,
                    "border border-blue-500": true,
                    "hover:bg-blue-100": true,
                }

            case "danger":
                return {
                    "text-red-100": true,

                    "bg-red-500": !disabled,
                    "hover:bg-red-400": !disabled,

                    "bg-gray-500": disabled,
                    "hover:bg-gray-400": disabled,
                }

            case "warn":
                return {
                    "text-yellow-100": true,

                    "bg-yellow-500": !disabled,
                    "hover:bg-yellow-400": !disabled,

                    "bg-gray-500": disabled,
                    "hover:bg-gray-400": disabled,
                }

            case "primary":
            default:
                return {
                    "text-blue-100": true,

                    "bg-blue-500": !disabled,
                    "hover:bg-blue-400": !disabled,

                    "bg-gray-500": disabled,
                    "hover:bg-gray-400": disabled,
                }
        }
    }

    getStyleClass(style) {
        switch (style) {
            case "compact":
                return "p-2 rounded-full"

            case "small-circle":
                return "w-4 h-4 rounded-full"

            case "circle":
                return "w-12 h-12 rounded-full"

            case "big-circle":
                return "w-16 h-16 rounded-full"

            case "rounded":
            default:
                return "py-3 px-4 rounded"
        }
    }

    render() {
        const {
            type,
            disabled,
            theme,
            style,
            className,
            title,
        } = this.props

        const buttonClass = classNames(className, "touch-action-manipulation flex items-center justify-center uppercase text-sm font-bold",
            this.getThemeClass(theme, disabled),
            this.getStyleClass(style))

        return <button
            title={title}
            type={type}
            ref={button => this.button = button}
            onClick={this.handleButtonClick}
            className={buttonClass}
        >
            { this.props.children }
        </button>
    }

    handleButtonClick = (event) => {
        const { onClick, disabled} = this.props

        if (disabled) {
            event.preventDefault()
        } else {
            if (onClick) onClick(event)
        }
    }
}
