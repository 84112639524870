import React from "react"
import axios from "axios"
import debounce from "lodash/debounce"
import uniqBy from "lodash/uniqBy"

import Input from "~/components/Input"
import Button from "~/components/Button"

// TODO "No hay resultados"
export default class ProductSelector extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            query: "",
            results: [],
        }

        this.refreshResults = debounce(this.refreshResults, 500)
    }

    render() {
        const {
            value
        } = this.props

        if (value) {
            return <div className="flex">
                <div className="w-full">
                    <div>
                        { value.name }
                    </div>
                    <div className="text-xs text-gray-700">
                        { value.pn }
                    </div>
                </div>
                <Button
                    type="button"
                    theme="outline"
                    className="p-2 hover:bg-gray-200"
                    onClick={() => this.props.onChange(null)}
                >
                    X
                </Button>
            </div>
        } else {
            return <div className="relative">
                <Input
                    value={this.state.query}
                    onChange={this.handleInputQuery}
                    placeholder="Escribe: Nombre, PN"
                />
                <div className="mt-1 absolute w-full z-10 bg-white rounded shadow overflow-hidden">
                    { this.state.results.map(product => <button
                        type="button"
                        key={product.id}
                        className="mtsp-px text-left hover:bg-gray-200 w-full px-2 px-1"
                        onClick={e => this.handleProductClick(e, product)}
                    >
                        <div>
                            { product.name }
                        </div>
                        <div className="text-xs text-gray-700">
                            { product.pn }
                        </div>
                    </button>) }
                </div>
            </div>
        }
    }

    handleInputQuery = value => {
        this.setState({
            query: value
        })
        this.refreshResults()
    }

    async refreshResults() {
        let { query } = this.state
        query = query.trim()
        if (query.length < 3) {
            this.setState({
                results: []
            })
        } else {
            let response = await axios.get(`/products?name=${query}`)
            const products1 = response.data.results

            response = await axios.get(`/products?pn=${query}`)
            const products2 = response.data.results

            const results = uniqBy(products1.concat(products2), p => p.id)

            this.setState({ results })
        }
    }

    handleProductClick = (event, product) => {
        event.preventDefault()

        this.props.onChange(product)
    }
}
