import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import cn from "classnames"

import {
    FaExternalLinkAlt,
    FaInfoCircle,
} from "react-icons/fa"

import StockItemTypeIcon from "./StockItemTypeIcon"
import TotalCosts from "./TotalCosts"

const mapStateToProps = state => ({
    currentUser: state.app.users[state.app.currentUserId],
})

export default connect(mapStateToProps)(function StockItemRow({
    currentUser, className, item, totalCosts
}) {
    return <div className={cn("flex rounded-sm hover:bg-blue-200 pr-1 py-1", className)}>
        <StockItemTypeIcon
            className="m-4 flex-shrink-0"
            type={item.type}
        />
        <div className="w-4/12 mlsp-1 flex-grow bg-gray-200 px-2 flex flex-col justify-center">
            <div className="flex items-center">
                <Link
                    to={`/stock/${item.id}`}
                    className="font-semibold text-sm"
                >
                    { item.name }
                </Link>
                { item.description && <FaInfoCircle
                    className="ml-1 text-xs text-gray-700 mt-px"
                    title={item.description}
                /> }
            </div>
            <div className="text-gray-700 text-xs">
                { item.pn }
            </div>
        </div>
        { currentUser.admin && <div className="w-1/12 mlsp-1 flex-grow bg-gray-200 px-2 flex flex-col justify-center">
            <div className="text-sm">
                { item.manufacturer }
            </div>
            <div className="text-xs text-gray-700">
                { item.mpn }
            </div>
        </div> }
        { currentUser.admin && <div className="w-1/12 mlsp-1 flex-grow bg-gray-200 px-2 flex flex-col justify-center">
            <div className="flex items-baseline">
                <span className="text-sm truncate">
                    { item.supplier || item.web }
                </span>
                { item.web && <a
                    className="text-xs ml-1 text-blue-700"
                    href={item.web}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaExternalLinkAlt />
                </a> }
            </div>
            <TotalCosts totalCosts={totalCosts[item.id]} />
        </div> }
        <div className="w-1/12 mlsp-1 bg-gray-200 px-2 flex flex-col justify-center">
            <div className="text-sm">
                { item.locator }
            </div>
            <div className="text-xs">
                <span className="mr-1 text-gray-700">
                    Stock:
                </span>
                <span className="font-semibold">
                    { item.amount }
                </span>
            </div>
        </div>
    </div>
})
