import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import CountrySelector from "~/components/CountrySelector"
import Input from "~/components/Input"

export default class CreateOrderModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            erpReference:   this.props.erpReference  || "",
            clientName:     this.props.clientName    || "",
            clientEmail:    this.props.clientEmail   || "",
            clientCountry:  this.props.clientCountry || "es",
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <div className="flex">
                    <div className="flex-1 pr-2">
                        <div>
                            <label className="text-xs uppercase">
                                Pedido
                            </label>
                            <Input
                                value={this.state.erpReference}
                                onChange={this.handleInput("erpReference")}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                País del cliente
                            </label>
                            <CountrySelector
                                value={this.state.clientCountry}
                                onChange={this.handleInput("clientCountry")}
                            />
                        </div>
                    </div>
                    <div className="flex-1 pl-2">
                        <div>
                            <label className="text-xs uppercase">
                                Nombre del cliente
                            </label>
                            <Input
                                value={this.state.clientName}
                                onChange={this.handleInput("clientName")}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Email del cliente
                            </label>
                            <Input
                                value={this.state.clientEmail}
                                onChange={this.handleInput("clientEmail")}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <Button className="ml-auto">
                        Crear
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleInput = path => value => {
        this.setState({
            [path]: value
        })
    }

    handleSubmit = async event => {
        event.preventDefault()

        const {
            erpReference,
            clientName,
            clientEmail,
            clientCountry,
        } = this.state

        this.props.onSubmit({
            erpReference,
            clientName,
            clientEmail,
            clientCountry,
        })
    }
}
