import React from "react"
import axios from "axios"
import ReactModal from "react-modal"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import cn from "classnames"
import urlJoin from "url-join"

import {
    FaPencilAlt,
    FaWindowClose,
    FaCloudUploadAlt,
} from "react-icons/fa"

import {
    copyTextToClipboard,
} from "~/lib/utils"

import {
    selectBatchParTime,
} from "~/lib/selectors"

import {
    notify,
    addNote,
    changeBatchPriority,
    changeBatchFinishAt,
    reassignBatch,
    uploadInstance,
} from "~/store"

import BatchIcon from "~/components/BatchIcon"
import ChangeFinishAtModal from "~/components/ChangeFinishAtModal"
import ReassignBatchModal from "~/components/ReassignBatchModal"
import InstanceModal from "~/components/InstanceModal"
import InstanceMediaItem from "~/components/InstanceMediaItem"
import MatchModal from "~/components/MatchModal"

import Input from "~/components/Input"
import Button from "~/components/Button"
import StarInput from "~/components/StarInput"
import TextArea from "~/components/TextArea"

const mapStateToProps = state => ({
    users: state.app.users,
    currentUser: state.app.users[state.app.currentUserId],
})

const mapDispatchToProps = (dispatch) => ({
    onNotify:             (...args) => dispatch(notify(...args)),
    onAddNote:            (...args) => dispatch(addNote(...args)),
    onReassignBatch:      (...args) => dispatch(reassignBatch(...args)),
    onChangePriority:     (...args) => dispatch(changeBatchPriority(...args)),
    onChangeFinishAt:     (...args) => dispatch(changeBatchFinishAt(...args)),
    onUploadInstance:     (...args) => dispatch(uploadInstance(...args)),
})

/* global API_BASE_URL */
export default connect(mapStateToProps, mapDispatchToProps)(class BatchInfoModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            note: "",
            currentTab: "info",
            instances: [],
            pingCount: {},
            showChangeFinishAtModal: false,
            showReassignModal: false,
            selectedInstanceId: null,
            matchInstanceId: null,
            offset: 0,
        }
    }

    async componentDidMount() {
        const { id } = this.props.batch

        const response = await axios.get(`/batches/${id}/ping-count`)
        this.setState({ pingCount: response.data })

        await this.refreshInstances()
    }

    refreshInstances = async () => {
        const { id } = this.props.batch
        const response = await axios.get(`/batches/${id}/instances`)
        this.setState({ instances: response.data })
    }

    render() {
        const {
            batch,
            currentUser,
            users,
        } = this.props
        const {
            currentTab,
            instances,
        } = this.state

        const activeUsers = Object.values(users).filter(u => u.active)

        const createdAt = DateTime.fromISO(batch.createdAt)

        let doneAt = batch.doneAt
        if (doneAt) doneAt = DateTime.fromISO(doneAt)

        let enabledAt = batch.enabledAt
        if (enabledAt) enabledAt = DateTime.fromISO(enabledAt)

        const tabClass = tab => cn("p-2 w-full uppercase text-sm rounded-t", {
            "text-white": currentTab == tab,
            "font-semibold": currentTab == tab,
            "bg-blue-500": currentTab == tab,
            "hover:bg-blue-400": currentTab == tab,

            "font-extralight": currentTab != tab,
            "text-gray-800": currentTab != tab,
            "hover:bg-blue-200": currentTab != tab,
        })

        const times = []
        for (let [userId, minutes] of Object.entries(this.state.pingCount)) {
            const name = users[userId].name
            times.push([name, minutes])
        }

        const creator = users[batch.createdBy]
        const owner = users[batch.ownedBy]
        const parTime = selectBatchParTime(batch)

        const cookedNotes = (batch.notes || []).map(n => ({
            id: n.id,
            createdAt: DateTime.fromISO(n.createdAt),
            name: users[n.createdBy]?.name,
            text: n.text,
        }))

        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-3xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <div className="flex mtsp-4">
                <div className="flex px-4 items-center justify-center text-xl">
                    <BatchIcon batch={batch} />
                </div>
                <div>
                    <div className="flex items-baseline">
                        <h1 className="text-xl">
                            { batch.name } ({ batch.pn })
                        </h1>
                        <span className="ml-2 text-gray-700">
                            (x{ batch.units })
                        </span>
                    </div>
                    <div className="flex items-center">
                        <h2 className="font-extralight">
                            { batch.code }
                        </h2>
                        <StarInput
                            className="ml-4"
                            value={batch.priority}
                            onChange={this.handleChangePriority}
                            disabled={!currentUser.admin}
                        />
                    </div>
                </div>
            </div>

            <div className="flex mtsp-4 overflow-auto">
                <div className="flex-1 mlsp-2">
                    <div className="text-sm text-gray-700 text-center">
                        Creación
                    </div>
                    <div className="text-center">
                        { createdAt.toFormat("yyyy-MM-dd") }
                    </div>
                    <div className="text-center text-xs">
                        { createdAt.toFormat("HH:mm:ss") }
                    </div>
                </div>
                <div className="flex-1 mlsp-2">
                    <div className="text-sm text-gray-700 text-center">
                        Comienzo
                    </div>
                    <div className="text-center">
                        { enabledAt ? enabledAt.toFormat("yyyy-MM-dd") : "-" }
                    </div>
                    { enabledAt && <div className="text-center text-xs">
                        { enabledAt.toFormat("HH:mm:ss") }
                    </div> }
                </div>
                <div className="flex-1 mlsp-2">
                    <div className="text-sm text-gray-700 text-center">
                        Objetivo
                        { currentUser.admin && <button
                            onClick={() => this.setState({ showChangeFinishAtModal: true })}
                            title="Modificar"
                            className="ml-1 text-xs"
                        >
                            <FaPencilAlt />
                        </button> }
                    </div>
                    <div className="text-center">
                        { batch.finishAt ? DateTime.fromISO(batch.finishAt).toFormat("yyyy-MM-dd") : "-" }
                    </div>
                    { batch.finishAt && <div className="text-center text-xs">
                        { DateTime.fromISO(batch.finishAt).toFormat("HH:mm:ss") }
                    </div> }
                </div>
                <div className="flex-1 mlsp-2">
                    <div className="text-sm text-gray-700 text-center">
                        Finalización
                    </div>
                    <div className="text-center">
                        { doneAt ? doneAt.toFormat("yyyy-MM-dd") : "-" }
                    </div>
                    { doneAt && <div className="text-center text-xs">
                        { doneAt.toFormat("HH:mm:ss") }
                    </div> }
                </div>
            </div>

            <div className="flex mtsp-4 -mx-4 px-4 border-b border-blue-500">
                <button
                    className={tabClass("info")}
                    onClick={() => this.setState({ currentTab: "info" })}
                >
                    Info
                </button>
                <button
                    className={tabClass("instances")}
                    onClick={() => this.setState({ currentTab: "instances" })}
                >
                    Instancias ({ this.state.instances.length })
                </button>
                <button
                    className={tabClass("notes")}
                    onClick={() => this.setState({ currentTab: "notes" })}
                >
                    Notas ({ batch.notes.length })
                </button>
            </div>

            { currentTab == "info" && <div className="grid grid-cols-3 gap-4 mt-4">
                <div className="flex flex-col">
                    <h2 className="text-sm text-gray-700">
                        Creado por
                    </h2>
                    <span>
                        { creator.name }
                    </span>
                </div>

                <div>
                    <h2 className="text-sm text-gray-700">
                        Asignado a
                        { currentUser.admin && <button
                            onClick={this.handleReassignClick}
                            title="Reasignar"
                            className="ml-1 text-xs"
                        >
                            <FaPencilAlt />
                        </button> }
                    </h2>
                    <span>
                        { owner?.name }
                    </span>
                </div>

                <div className="flex flex-col">
                    <h2 className="text-sm text-gray-700">
                        Tiempo
                    </h2>
                    <span>
                        <ul>
                            { times.map(([name, time], idx) => <li
                                key={idx}
                                className="text-sm"
                            >
                                { name }: { time } min.
                            </li>) }
                        </ul>
                    </span>
                    <span className="text-gray-700 text-xs">
                        (obj. { parTime } min.)
                    </span>
                </div>

                { (currentUser.admin && this.props.onReset) && <div>
                    <h2 className="text-sm text-gray-700">
                        Reset
                    </h2>
                    <Button
                        className="mt-1"
                        theme="danger"
                        onClick={this.handleResetClick}
                    >
                        Resetear
                    </Button>
                </div> }
                { (currentUser.admin && this.props.onDelete) && <div>
                    <h2 className="text-sm text-gray-700">
                        Borrar
                    </h2>
                    <Button
                        className="mt-1"
                        theme="danger"
                        onClick={this.handleDeleteClick}
                    >
                        Borrar
                    </Button>
                </div> }
                { (currentUser.admin && this.props.onUpdate) && <div>
                    <h2 className="text-sm text-gray-700">
                        Actualizar
                    </h2>
                    <Button
                        className="mt-1"
                        theme="danger"
                        onClick={this.handleUpdateClick}
                    >
                        Actualizar
                    </Button>
                </div> }
            </div> }

            { currentTab == "instances" && <div>
                <div className="flex items-end mb-4">
                    { batch.retail && <>
                        <div className="ml-2">
                            <label className="uppercase text-xs">
                                offset
                            </label>
                            <Input
                                type="number"
                                min="0"
                                max="40"
                                step="1"
                                value={this.state.offset}
                                onChange={v => this.setState({ offset: Number(v) })}
                                placeholder="Offset"
                            />
                        </div>

                        <a
                            className="ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urlJoin(API_BASE_URL, "device-stickers", instances.map(i => i.id).join(":"), `?offset=${this.state.offset}`)}
                        >
                            <Button
                                style="compact"
                                theme="outline"
                            >
                                Pegatinas traseras
                            </Button>
                        </a>

                        <a
                            className="ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urlJoin(API_BASE_URL, "bamboo-stickers", instances.map(i => i.id).join(":"), `?offset=${this.state.offset}`)}
                        >
                            <Button
                                style="compact"
                                theme="outline"
                            >
                                Pegatinas bamboo
                            </Button>
                        </a>

                        <a
                            className="ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urlJoin(API_BASE_URL, "sec-codes-stickers", instances.map(i => i.id).join(":"))}
                        >
                            <Button
                                style="compact"
                                theme="outline"
                            >
                                Pegatinas Sec. Codes
                            </Button>
                        </a>

                        <Button
                            className="ml-2"
                            style="compact"
                            theme="outline"
                            onClick={this.handleClickUploadAll}
                        >
                            <FaCloudUploadAlt className="mr-1" />
                            Publicar todos
                        </Button>

                        <a
                            className="ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={urlJoin(API_BASE_URL, "cocs", instances.map(i => i.id).join(":"))}
                        >
                            <Button
                                style="compact"
                                theme="outline"
                            >
                                COCS
                            </Button>
                        </a>
                    </> }
                </div>

                { instances.map((instance) => <div
                    className="mtsp-2"
                    key={instance.id}
                >
                    <InstanceMediaItem
                        className="w-full"
                        batch={batch}
                        instance={instance}
                        offset={this.state.offset}
                        onViewInstance={() => this.setState({ selectedInstanceId: instance.id })}
                        onViewSecurityCodes={() => this.handleSecurityCodesClick(instance)}
                        onToggleDemo={() => this.handleDemoClick(instance)}
                        onUpload={(...args) => this.handleUploadClick(instance, ...args)}
                        onMatch={() => this.handleMatchClick(instance)}
                        onUnmatch={() => this.handleUnmatchClick(instance)}
                    />
                </div>) }
            </div> }

            { currentTab == "notes" && <div className="mtsp-4">
                <div className="pl-2">
                    { cookedNotes.map(note => <div
                        key={note.id}
                        className="mtsp-4"
                    >
                        <div className="flex">
                            <div className="text-xs text-gray-700">
                                { note.name }
                            </div>
                            <div className="text-xs ml-auto">
                                { note.createdAt.toLocaleString(DateTime.DATETIME_SHORT) }
                            </div>
                        </div>
                        <div>
                            { note.text }
                        </div>
                    </div>) }
                    { cookedNotes.length == 0 && <div className="text-gray-700">
                        No hay notas.
                    </div> }
                </div>
                <form
                    className="flex mt-2"
                    onSubmit={this.handleSubmitNote}
                >
                    <TextArea
                        value={this.state.note}
                        onChange={this.handleNoteChange}
                    />
                    <Button className="self-end ml-2">
                        Añadir
                    </Button>
                </form>
            </div> }

            { this.state.showChangeFinishAtModal && <ChangeFinishAtModal
                onRequestClose={() => this.setState({ showChangeFinishAtModal: false })}
                finishAt={batch.finishAt}
                onChange={this.handleChangeFinishAt}
            /> }

            { this.state.showReassignModal && <ReassignBatchModal
                users={activeUsers}
                onRequestClose={() => this.setState({ showReassignModal: false })}
                onReassign={this.handleReassign}
            /> }

            { this.state.selectedInstanceId && <InstanceModal
                batch={batch}
                instance={instances.find(i => i.id == this.state.selectedInstanceId)}
                onRequestClose={this.handleRequestCloseInstanceModal}
            /> }

            { this.state.matchInstanceId && <MatchModal
                batch={batch}
                instance={instances.find(i => i.id == this.state.matchInstanceId)}
                onMatch={this.handleMatch}
                onRequestClose={() => this.setState({ matchInstanceId: null })}
            /> }
        </ReactModal>
    }

    handleClickUploadAll = async () => {
        const { instances } = this.state
        for (let instance of instances) {
            await this.props.onUploadInstance(instance.id, false)
        }
        await this.refreshInstances()
    }

    handleRequestCloseInstanceModal = async () => {
        await this.refreshInstances()
        this.setState({ selectedInstanceId: null })
    }

    handleChangePriority = async priority => {
        await this.props.onChangePriority(this.props.batch.id, priority)
    }

    handleChangeFinishAt = async date => {
        await this.props.onChangeFinishAt(this.props.batch.id, date)
        this.setState({ showChangeFinishAtModal: false })
    }

    handleReassignClick = e => {
        e.preventDefault()

        this.setState({ showReassignModal: true })
    }

    handleReassign = async userId => {
        await this.props.onReassignBatch(this.props.batch.id, userId)
        this.setState({ showReassignModal: false })
    }

    handleSubmitNote = async event => {
        event.preventDefault()

        const text = this.state.note.trim()
        if (!text) return

        await this.props.onAddNote(this.props.batch.id, text)
        this.setState({ note: "" })
    }

    handleNoteChange = value => {
        this.setState({
            note: value
        })
    }

    handleDemoClick = async (instance) => {
        if (instance.uploadedAt) {
            window.alert("Este montaje ya está publicado. Para que este cambio se refleje en la web de cliente, es necesario republicarlo.")
        }

        const value = !instance.demo
        await axios.post(`/instances/${instance.id}/demo`, { value })
        await this.refreshInstances()
        if (value) {
            this.props.onNotify("success", "Equipo marcado como de demostración")
        } else {
            this.props.onNotify("success", "Equipo marcado como comercial")
        }
    }

    handleSecurityCodesClick = instance => {
        const txt = instance.securityCodes.join(" ")
        copyTextToClipboard(txt)
        this.props.onNotify("success", "Códigos de seguridad copiados al portapapeles")
    }

    handleUploadClick = async (instance, updateDoneAtDate) => {
        await this.props.onUploadInstance(instance.id, updateDoneAtDate)
        await this.refreshInstances()
    }

    handleMatchClick = (instance) => {
        this.setState({
            matchInstanceId: instance.id,
        })
    }

    handleUnmatchClick = async (instance) => {
        await axios.post(`/match-groups/${instance.matchGroupId}/delete`, {
            instanceId: instance.id
        })
        await this.refreshInstances()
        await this.props.onNotify("success", "Equipo desmatcheado")
    }

    handleMatch = async (group) => {
        if (group) {
            await axios.post(`/match-groups/${group.id}`, {
                instanceId: this.state.matchInstanceId
            })
        } else {
            await axios.post(`/match-groups`, {
                instanceId: this.state.matchInstanceId
            })
        }
        await this.refreshInstances()
        await this.props.onNotify("success", "Equipo matcheado")
        this.setState({ matchInstanceId: null })
    }

    handleResetClick = async () => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        await this.props.onReset()
    }

    handleDeleteClick = async () => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        await this.props.onDelete()
    }

    handleUpdateClick = async () => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        await this.props.onUpdate()
    }
})
