import React from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import urlJoin from "url-join"
import { DateTime } from "luxon"
import cn from "classnames"

import {
    IoMdReturnLeft,
} from "react-icons/io"

import {
    FaFileCsv,
    FaCheck,
} from "react-icons/fa"

import Button from "~/components/Button"

/* global API_BASE_URL */
export default class StockTransactionsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pendingTransactions: [],
            pendingBatches: [],
        }
    }

    async componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        axios.get("/stock-transactions")
        .then(response => {
            this.setState({
                pendingTransactions: response.data
            })
        })
        axios.get("/stock-batches")
        .then(response => {
            this.setState({
                pendingBatches: response.data
            })
        })
    }

    render() {
        const trxs = this.state.pendingTransactions
        const { pendingBatches } = this.state

        return <div className="mx-auto max-w-4xl p-2">
            <div className="flex items-center">
                <Link to="/stock">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                    </Link>
                <h1 className="text-xl ml-2">
                    Transacciones de stock
                </h1>
            </div>

            <h2 className="text-lg">
                Transacciones Pendientes
            </h2>

            { trxs.length > 0 && <>
                <div className="mb-2">
                    { trxs.map(trx => <div
                        className="flex items-center mtsp-2 border"
                        key={trx.id}
                    >
                        <div
                            className={cn("px-4 py-2 text-sm font-bold", {
                                "text-green-700": trx.amount > 0,
                                "text-red-700":   trx.amount < 0,
                            })}
                        >
                            { trx.amount }
                        </div>
                        <div className="mr-auto">
                            <div>
                                { trx.pn }
                            </div>
                            <div className="text-sm text-gray-700">
                                { trx.description }
                            </div>
                        </div>
                        <div className="ml-2 text-xs px-2">
                            { DateTime.fromISO(trx.createdAt).toFormat("yyyy-LL-dd HH:mm:ss") }
                        </div>
                    </div>) }
                </div>

                <Button onClick={this.handleBatchDownloadClick}>
                    Batch & Download
                </Button>
            </> }
            { trxs.length == 0 && <div className="text-gray-700">
                No hay transacciones pendientes
            </div> }

            { pendingBatches.length > 0 && <>
                <h2 className="text-lg mt-8">
                    Lotes
                </h2>

                { pendingBatches.map(batch => <div
                    key={batch.id}
                    className="flex items-center mtsp-2"
                >
                    { batch.confirmedAt && <FaCheck className="mlsp-2" /> }
                    <div className="mlsp-2 font-extralight">
                        { batch.id }
                    </div>
                    <div className="mlsp-2">
                        { DateTime.fromISO(batch.createdAt).toFormat("yyyy/LL/dd HH:mm:ss") }
                    </div>
                    <div className="mlsp-2 flex">
                        <a
                            href={urlJoin(API_BASE_URL, "stock-batches", batch.id)}
                            className="mlsp-2"
                        >
                            <Button
                                className="h-full"
                                style="compact"
                                theme="outline"
                            >
                                <FaFileCsv className="mr-1" />
                                Descargar
                            </Button>
                        </a>
                        { !batch.confirmedAt && <Button
                            title="Confirmar"
                            style="compact"
                            onClick={() => this.handleConfirmClick(batch)}
                            className="mlsp-2"
                        >
                            Confirmar
                        </Button> }
                    </div>
                </div>) }
            </> }
        </div>
    }

    handleBatchDownloadClick = async () => {
        const response = await axios.post("/stock-transactions")
        const batch = response.data

        this.refresh()

        const a = window.document.createElement("a")
        a.href = urlJoin(API_BASE_URL, "stock-batches", batch.id)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    handleConfirmClick = async batch => {
        await axios.post(`/stock-batches/${batch.id}/confirm`)
        this.refresh()
    }
}
