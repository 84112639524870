import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Calendar from "react-calendar"
import { DateTime } from "luxon"
import axios from "axios"
import uniq from "lodash/uniq"
import cn from "classnames"

import { obj2qs } from "~/lib/utils"

import BatchMediaItem from "~/components/BatchMediaItem"

export default function Schedule({ className }) {
    const [batches, setBatches] = useState([])
    const [day, setDay] = useState(new Date())
    const users = useSelector(state => state.app.users)

    useEffect(() => {
        let qs = {
            doneAt: null
        }
        qs = obj2qs(qs)
        axios.get(`/batches?${qs}`)
        .then(response => {
            setBatches(response.data.results)
        })
    }, [])

    const bs = batches.filter(b => DateTime.fromJSDate(day).hasSame(DateTime.fromISO(b.finishAt), "day"))

    const tileContent = ({ date }) => {
        const bs = batches.filter(b => DateTime.fromJSDate(date).hasSame(DateTime.fromISO(b.finishAt), "day"))

        let xs = []

        for (let b of bs) {
            const owner = users[b.ownedBy]
            const initials = owner.name.split(" ").map(n => n[0]).join("")
            xs.push(initials)
        }

        xs = uniq(xs)

        return <div className="text-gray-700 flex justify-center">
            { xs.map((x, idx) => <span
                key={idx}
                className="mlsp-px"
            >
                •
            </span>) }
        </div>
    }

    return <div className={cn("p-2", className)}>
        <h2 className="text-2xl font-extralight mb-4">
            Planificación
        </h2>

        <Calendar
            minDetail="year"
            tileContent={tileContent}
            onChange={v => setDay(v)}
        />

        <div className="mt-4 mb-2 text-lg">
            { DateTime.fromJSDate(day).toLocaleString(DateTime.DATE_HUGE) }
        </div>

        { bs.map(b => <BatchMediaItem
            className="mtsp-4"
            key={b.id}
            batch={b}
        />) }
    </div>
}
