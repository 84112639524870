import React from "react"
import axios from "axios"
import cn from "classnames"

import {
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

import {
    ean13_checksum
} from "~/lib/utils"

export default class Ean13Stats extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            folded: true,
            eanUsage: {},
        }
    }

    async componentDidMount() {
        const response = await axios.get("/stats/ean-usage")
        this.setState({
            eanUsage: response.data
        })
    }

    render() {
        return <div className={cn("p-2 relative", this.props.className)}>
            <h2 className="text-2xl font-extralight">
                EAN13
            </h2>
            <button
                className="absolute right-0 top-0 text-gray-300 bg-gray-700 hover:bg-gray-600 p-2 rounded-bl rounded-tr"
                onClick={this.toggleFolded}
            >
                { this.state.folded ? <FaChevronDown /> : <FaChevronUp /> }
            </button>

            { !this.state.folded && <table>
                <thead>
                    <tr>
                        <th>
                            EAN13
                        </th>
                        <th>
                            # Products
                        </th>
                        <th>
                            # Batches
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { Object.entries(this.state.eanUsage).map(([ean, { products, batches }]) => <tr
                        className={cn("text-sm", {
                            "font-semibold": products > 0 || batches > 0,
                            "text-black": products > 0 || batches > 0,
                            "text-gray-700": !(products > 0 || batches > 0)
                        })}
                        key={ean}
                    >
                        <td>
                            { ean }
                            <span className="text-xs">
                                { ean13_checksum(ean) }
                            </span>
                        </td>
                        <td className="text-center">
                            { products  }
                        </td>
                        <td className="text-center">
                            { batches }
                        </td>
                    </tr>) }
                </tbody>
            </table> }
        </div>
    }

    toggleFolded = () => {
        this.setState(s => ({
            folded: !s.folded
        }))
    }
}
