import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"

import {
    FaAngleRight,
    FaAngleLeft,
} from "react-icons/fa"

import {
    IoMdReturnLeft
} from "react-icons/io"

import Input from "~/components/Input"
import Button from "~/components/Button"
import Select from "~/components/Select"
import InputDateRange from "~/components/InputDateRange"

import {
    refreshEmails,
    updateEmailsPagination,
    updateEmailFilters,
} from "~/store"

import EmailModal from "~/components/EmailModal"

const mapStateToProps = state => ({
    emails: state.app.emails.page.map(id => state.app.emails.entities[id]),
    totalPages: Math.ceil(state.app.emails.count / state.app.emails.limit),
    currentPage: 1 + Math.floor(state.app.emails.offset / state.app.emails.limit),
    filters: state.app.emails.filters,
})

const mapDispatchToProps = dispatch => ({
    onRefreshEmails: (...args) => dispatch(refreshEmails(...args)),
    onPageClick: (dir) => dispatch(updateEmailsPagination(dir)),
    onUpdateFilter: (filters) => dispatch(updateEmailFilters(filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(class EmailsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedEmailId: null
        }
    }

    componentDidMount() {
        this.props.onRefreshEmails()
    }

    render() {
        const {
            emails,
            currentPage,
            totalPages,
            filters,
        } = this.props
        const {
            selectedEmailId,
        } = this.state

        const selectedEmail = emails.find(e => e.id == selectedEmailId)

        return <div className="p-2">
            <div className="flex items-center mtsp-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Emails
                </h1>
            </div>

            <div className="flex items-center">
                <Button
                    title="Página anterior"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(-1)}
                >
                    <FaAngleLeft />
                </Button>
                <span className="mx-2 text-sm text-gray-700 font-bold">
                    { currentPage } / { totalPages }
                </span>
                <Button
                    title="Página siguiente"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(1)}
                >
                    <FaAngleRight />
                </Button>
            </div>

            <div className="flex">
                <div className="w-1/4 mlsp-4">
                    <label className="text-xs uppercase">
                        Tipo
                    </label>
                    <Input
                        value={filters.type}
                        onChange={v => this.handleSetFilter("type", v)}
                    />
                </div>
                <div className="w-1/4 mlsp-4">
                    <label className="text-xs uppercase">
                        Destinatario
                    </label>
                    <Input
                        value={filters.recipient}
                        onChange={v => this.handleSetFilter("recipient", v)}
                    />
                </div>
                <div className="w-1/4 mlsp-4">
                    <label className="text-xs uppercase">
                        Estado
                    </label>
                    <Select
                        value={filters.status}
                        onChange={v => this.handleSetFilter("status", v)}
                    >
                        <option value=""></option>
                        <option value="sent">sent</option>
                        <option value="error">error</option>
                    </Select>
                </div>
                <div className="w-1/4 mlsp-4">
                    <label className="text-xs uppercase">
                        Fecha de envío
                    </label>
                    <InputDateRange
                        value={filters.sentAt}
                        onChange={v => this.handleSetFilter("sentAt", v)}
                    />
                </div>
            </div>

            <table className="w-full text-sm">
                <thead className="font-semibold">
                    <tr>
                        <td>
                            Asunto
                        </td>
                        <td>
                            Destinatario
                        </td>
                        <td>
                            Tipo
                        </td>
                        <td>
                            Estado
                        </td>
                        <td>
                            Fecha de envío
                        </td>
                    </tr>
                </thead>
                <tbody>
                    { emails.map(email => <tr key={email.id} >
                        <td>
                            <button onClick={e => this.handleSelectEmail(e, email)}>
                                { email.subject }
                            </button>
                        </td>
                        <td>
                            { email.recipients.join(" ") }
                        </td>
                        <td>
                            { email.type }
                        </td>
                        <td>
                            { email.status }
                        </td>
                        <td>
                            { DateTime.fromISO(email.sentAt).toFormat("yyyy LLL dd hh:mm") }
                        </td>
                    </tr>) }
                </tbody>
            </table>

            <EmailModal
                isOpen={!!selectedEmail}
                email={selectedEmail}
                onRequestClose={this.handleCloseModal}
            />
        </div>
    }

    handleSelectEmail = (event, email) => {
        event.preventDefault()

        this.setState({
            selectedEmailId: email.id
        })
    }

    handleCloseModal = () => {
        this.setState({ selectedEmailId: null })
    }

    handlePageClick = (dir) => {
        this.setState({ selectedEmailId: null })
        this.props.onPageClick(dir)
    }

    handleSetFilter = (prop, value) => {
        this.props.onUpdateFilter({ [prop]: value })
    }
})
