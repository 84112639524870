import React from "react"
import set from "lodash/set"
import { Link } from "react-router-dom"
import axios from "axios"
import produce from "immer"

import {
    IoMdReturnLeft
} from "react-icons/io"

import Button from "~/components/Button"
import Select from "~/components/Select"

import LogFile from "~/components/LogFile"


export default class LogsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            names: ["server", "holded"],
            days: [""],

            name: "server.log",

            levels: {
                fatal: true,
                error: true,
                warn:  true,
                info:  true,
                debug: false,
                trace: false,
            },

            files: []
        }
    }

    async componentDidMount() {
        const response = await axios.get("/logs")
        this.setState({
            files: response.data
        })
    }

    render() {
        const { name, levels } = this.state

        return <div className="p-2">
            <div className="flex items-center">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Logs
                </h1>
            </div>

            <div className="flex">
                <div className="border-r p-2 w-48 flex-shrink-0">
                    <div>
                        <label>File</label>
                        <Select
                            value={this.state.name}
                            onChange={this.handleInput("name")}
                        >
                            { this.state.files.map(file => <option
                                key={file}
                                value={file}
                            >
                                { file }
                            </option>)  }
                        </Select>
                    </div>
                    <div>
                        <label>
                            Nivel
                        </label>
                        <ul>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.fatal}
                                        onChange={e => this.handleInput("levels.fatal")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Fatal
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.error}
                                        onChange={e => this.handleInput("levels.error")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Error
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.warn}
                                        onChange={e => this.handleInput("levels.warn")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Warning
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.info}
                                        onChange={e => this.handleInput("levels.info")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Info
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.debug}
                                        onChange={e => this.handleInput("levels.debug")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Debug
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.levels.trace}
                                        onChange={e => this.handleInput("levels.trace")(e.target.checked)}
                                        className="mr-1"
                                    />
                                    Trace
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <LogFile
                    key={name}
                    className="p-2 w-full"
                    file={name}
                    levels={levels}
                />
            </div>
        </div>
    }

    handleInput = path => value => {
        this.setState(produce(draft => {
            set(draft, path, value)
        }))
    }
}
