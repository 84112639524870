import React from "react"
import Calendar from "react-calendar"
import isArray from "lodash/isArray"
import ReactModal from "react-modal"

import Button from "~/components/Button"

import {
    FaWindowClose,
} from "react-icons/fa"

export default class CalendarModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dates: this.props.value
        }
    }

    render() {
        const {
            isOpen,
            onRequestClose,
            range,
        } = this.props
        const {
            dates
        } = this.state

        return <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="pb-4 px-4 pt-8 rounded shadow bg-white absolute center outline-none"
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <Calendar
                value={range ? (isArray(dates) ? dates : null) : (dates || null)}
                minDetail="year"
                selectRange={range}
                onChange={this.handleChange}
            />

            <div className="mt-4 flex">
                { range && <>
                    <Button
                        theme={dates === true ? "primary" : "outline"}
                        onClick={this.handleAnyClick}
                    >
                        ANY
                    </Button>
                    <Button
                        className="ml-2"
                        theme={dates === null ? "primary" : "outline"}
                        onClick={this.handleNotClick}
                    >
                        NOT
                    </Button>
                </> }
                <Button
                    className="ml-auto"
                    theme="outline"
                    onClick={this.handleOkClick}
                >
                    Aceptar
                </Button>
            </div>
        </ReactModal>
    }

    handleChange = values => {
        this.setState({
            dates: values
        })
    }

    handleCloseClick = () => {
        this.props.onClose()
    }

    handleAnyClick = () => {
        this.setState({
            dates: true,
        })
    }

    handleNotClick = () => {
        this.setState({
            dates: null,
        })
    }

    handleOkClick = () => {
        this.props.onChange(this.state.dates)
    }
}
