import React from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import set from "lodash/set"
import produce from "immer"
import { DateTime } from "luxon"
import debounce from "lodash/debounce"
import cn from "classnames"

import {
    IoMdReturnLeft
} from "react-icons/io"

import Button from "~/components/Button"
import SimpleDeviceMetrics from "~/components/SimpleDeviceMetrics"
import BorealMetrics from "~/components/BorealMetrics"
import GravityMetrics from "~/components/GravityMetrics"
import InputDate from "~/components/InputDate"

// TODO dupped
// ns: normalized standard deviation
const healthClass = ns => cn({
    "bg-green-200": ns < 0.1,
    "bg-orange-200": ns >= 0.1 && ns < 0.3,
    "bg-red-200": ns >= 0.3,
})

export default class StatsView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            lavaMetrics:    {},
            lavaGains:      {},
            calimaMetrics:  {},
            calimaGains:    {},
            borealMetrics:  {},
            borealTimes:    {},
            gravityMetrics: {},
            gravityTimes:   {},
            icicleMetrics:  {},
            canyonMetrics:  {},
            flavourMetrics: {},

            from:   new Date(0),
            to:     DateTime.now().plus({ days: 1 }).toJSDate(),
        }

        this.updateDates = debounce(this.updateDates, 1000)
    }

    async componentDidMount() {
        await this.updateDates()
    }

    updateDates = async () => {
        const from = this.state.from.toISOString()
        const to   = this.state.to.toISOString()
        const response = await axios.get(`/stats?from=${from}&to=${to}`)
        this.setState(response.data)
    }

    render() {
        return <div className="max-w-6xl mx-auto p-2">
            <div className="flex items-center mb-2">
                <Link to="/">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    Estadísticas
                </h1>
            </div>

            <div className="flex mtsp-8">
                <div className="flex-1 pr-2">
                    <label className="uppercase text-xs">
                        FROM
                    </label>
                    <InputDate
                        value={this.state.from}
                        onChange={this.handleDateInput("from")}
                    />
                </div>
                <div className="flex-1 pl-2">
                    <label className="uppercase text-xs">
                        TO
                    </label>
                    <InputDate
                        value={this.state.to}
                        onChange={this.handleDateInput("to")}
                    />
                </div>
            </div>

            <SimpleDeviceMetrics
                className="mtsp-8 shadow rounded"
                name="Lava"
                metrics={this.state.lavaMetrics}
            >
                { Object.entries(this.state.lavaGains).map(([pn, gains], idx) => <div
                    key={idx}
                >
                    <div className="font-semibold">
                        { pn }
                    </div>
                    <table className="w-full bg-gray-200">
                        <thead>
                            <tr>
                                <th>
                                    position
                                </th>
                                <th>
                                    avg
                                </th>
                                <th>
                                    std dev
                                </th>
                                <th>
                                    std dev (normalized)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { gains.map(([avg, s], idx) => <tr
                                key={idx}
                                className={healthClass(Math.abs(avg == 0 ? 0 : s / avg))}
                            >
                                <td>
                                    { idx + 1}
                                </td>
                                <td className="text-center">
                                    { avg.toFixed(3) }
                                </td>
                                <td className="text-center">
                                    { s.toFixed(4) }
                                </td>
                                <td className="text-center">
                                    { (avg == 0 ? 0 : s / avg).toFixed(3) }
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>) }
            </SimpleDeviceMetrics>


            <SimpleDeviceMetrics
                className="mtsp-8 shadow rounded"
                name="Calima"
                metrics={this.state.calimaMetrics}
            >
                { Object.entries(this.state.calimaGains).map(([pn, gains], idx) => <div
                    key={idx}
                >
                    <div className="font-semibold">
                        { pn }
                    </div>
                    <table className="w-full bg-gray-200">
                        <thead>
                            <tr>
                                <th>
                                    position
                                </th>
                                <th>
                                    avg
                                </th>
                                <th>
                                    std dev
                                </th>
                                <th>
                                    std dev (normalized)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { gains.map(([avg, s], idx) => <tr
                                key={idx}
                                className={healthClass(Math.abs(avg == 0 ? 0 : s / avg))}
                            >
                                <td>
                                    { idx + 1}
                                </td>
                                <td className="text-center">
                                    { avg.toFixed(3) }
                                </td>
                                <td className="text-center">
                                    { s.toFixed(4) }
                                </td>
                                <td className="text-center">
                                    { (avg == 0 ? 0 : s / avg).toFixed(3) }
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>) }
            </SimpleDeviceMetrics>

            <BorealMetrics
                className="mtsp-8 shadow rounded"
                borealMetrics={this.state.borealMetrics}
                borealTimes={this.state.borealTimes}
            />

            <GravityMetrics
                className="mtsp-8 shadow rounded"
                gravityMetrics={this.state.gravityMetrics}
                gravityTimes={this.state.gravityTimes}
            />

            <SimpleDeviceMetrics
                className="mtsp-8 shadow rounded"
                name="Icicle"
                metrics={this.state.icicleMetrics}
            />

            <SimpleDeviceMetrics
                className="mtsp-8 shadow rounded"
                name="Canyon"
                metrics={this.state.canyonMetrics}
            />

            <SimpleDeviceMetrics
                className="mtsp-8 shadow rounded"
                name="Flavours"
                metrics={this.state.flavourMetrics}
            />
        </div>
    }

    handleDateInput = path => value => {
        this.setState(produce(draft => {
            set(draft, path, value)
        }), () => {
            this.updateDates()
        })
    }
}
