import React from "react"
import urlJoin from "url-join"
import cn from "classnames"
import { connect } from "react-redux"
import { DateTime } from "luxon"

import {
    FaTrashAlt,
    FaDownload,
    FaEye,
    FaListOl,
    FaUpload,
} from "react-icons/fa"

import Button from "~/components/Button"

const mapStateToProps = state => ({
    currentUser: state.app.users[state.app.currentUserId],
})

/* global API_BASE_URL */
export default connect(mapStateToProps)(class ManualItem extends React.Component {
    render() {
        const {
            manual,
            className,
            currentUser,
        } = this.props

        const lastVersion = manual.versions[manual.versions.length - 1]

        return <div className={cn("flex items-center hover:bg-gray-200 p-2 rounded", className)}>
            <div className="mlsp-2">
                <Button
                    style="circle"
                    theme="transparent"
                    type="button"
                    onClick={this.props.onPreview}
                    title="Previsualizar"
                >
                    <FaEye />
                </Button>
            </div>
            <div className="w-full mlsp-2">
                <div>
                    <span className="font-semibold mlsp-4">
                        { manual.name }
                    </span>
                    <span className="text-xs text-gray-700 mlsp-4">
                        { lastVersion.numPages } páginas
                    </span>
                    <span className="text-xs text-gray-700 mlsp-4">
                        { manual.versions.length } versiones
                    </span>
                </div>
                <div className="text-sm italic">
                    { manual.type }
                </div>
            </div>
            <div className="ml-auto flex-shrink-0 flex flex-col text-xs text-gray-700 text-right">
                <span>
                    Creación: { DateTime.fromISO(lastVersion.createdAt).toLocal().toFormat("yyyy/LL/dd hh:mm:ss") }
                </span>
                <span>
                    Actualización: { DateTime.fromISO(lastVersion.updatedAt).toLocal().toFormat("yyyy/LL/dd hh:mm:ss") }
                </span>
            </div>
            <div className="flex items-center ml-2">
                { currentUser.admin && <>
                    <Button
                        type="button"
                        theme="transparent"
                        style="compact"
                        className="mlsp-2"
                        title="Historial"
                        onClick={this.props.onHistory}
                    >
                        <FaListOl />
                    </Button>
                    <a
                        href={urlJoin(API_BASE_URL, "manuals", manual.id, "file")}
                        className="mlsp-2"
                        title="Descargar última versión"
                    >
                        <Button
                            theme="transparent"
                            style="compact"
                            type="button"
                        >
                            <FaDownload />
                        </Button>
                    </a>
                    <Button
                        className="mlsp-2"
                        theme="transparent"
                        style="compact"
                        type="button"
                        title="Actualizar manual"
                        onClick={this.props.onUpdate}
                    >
                        <FaUpload />
                    </Button>
                    <Button
                        type="button"
                        theme="transparent"
                        style="compact"
                        className="mlsp-2"
                        onClick={this.props.onDelete}
                        title="Borrar"
                    >
                        <FaTrashAlt />
                    </Button>
                </> }
            </div>
        </div>
    }
})
