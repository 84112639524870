import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import Select from "~/components/Select"

export default class ReassignBatchModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ownedBy: Object.values(props.users)[0]?.id,
        }
    }

    render() {
        const {
            onRequestClose,
            users,
        } = this.props
        const {
            ownedBy
        } = this.state

        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-lg relative outline-none"
            onRequestClose={onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <p>
                Asignar a:
            </p>

            <form onSubmit={this.handleSubmit}>
                <Select
                    value={ownedBy}
                    onChange={this.handleOwnedByChange}
                >
                    { Object.values(users).map(user => {
                        return <option
                            value={user.id}
                            key={user.id}
                        >
                            { user.name }
                        </option>
                    }) }
                </Select>
                <div className="mt-2 flex justify-end">
                    <Button>
                        Asignar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleOwnedByChange = id => {
        this.setState({
            ownedBy: id
        })
    }

    handleSubmit = event => {
        event.preventDefault()

        this.props.onReassign(this.state.ownedBy)
    }
}
