import React from "react"
import cn from "classnames"
import chunk from "lodash/chunk"

import {
    FaCheck,
} from "react-icons/fa"

import Button from "~/components/Button"

const BUCKETS_PER_TUBE = 15

export default class StepSupply extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.answer?.value || 0
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.answer?.id != prevProps.answer?.id) {
            this.setState({
                value:      this.props.answer?.value || 0,
                thinking:   false,
                editing:    false,
            })
        }
    }

    groupedItems = () => {
        const { step, batch } = this.props

        const groupedItems = []

        if (step.trayItems.length > 0) {
            groupedItems.push({
                name: "Bandeja",
                items: step.trayItems.map(item => ({
                    designator: item.designator,
                    locator:    item.locator,
                    amount:     item.amount * batch.units,
                    mpn:        item.mpn,
                    pn:         item.pn,
                    name:       item.name,
                })),
            })
        }

        const buckets = []
        for (let item of step.tubeItems) {
            let total = item.amount * batch.units

            while (total > 0) {
                const amount = item.perBucket ? Math.min(item.perBucket, total) : total
                buckets.push({
                    designator: item.designator,
                    locator:    item.locator,
                    amount,
                    mpn:        item.mpn,
                    pn:         item.pn,
                    name:       item.name,
                })
                total -= amount
            }
        }

        const chunkedBuckets = chunk(buckets, BUCKETS_PER_TUBE)
        for (let i = 0; i < chunkedBuckets.length; i++) {
            groupedItems.push({
                name: `Tubo ${i+1}`,
                items: chunkedBuckets[i]
            })
        }

        return groupedItems
    }

    render() {
        const {
            blocked,
            tooSoon,
        } = this.props

        const {
            value,
        } = this.state

        const cursor = value

        let i = 0
        return <div className="pt-10 pb-2 max-w-3xl mx-auto">
            <div className="mtsp-4">
                { this.groupedItems().map((group, idx) => {
                    return <div
                        key={idx}
                        className="mtsp-4"
                    >
                        <div className="text-xl">
                            { group.name }
                        </div>

                        <table className="w-full max-w-full">
                            <tbody>
                                { group.items.map((item, idx) => {
                                    const index = i++
                                    const classes = cn("rounded px-2", {
                                        "text-green-700": index < cursor,
                                        "bg-blue-200": index == cursor,
                                    })

                                    return <tr
                                        key={idx}
                                        className={classes}
                                    >
                                        <td className="mlsp-2 px-2 overflow-hidden text-xs">
                                            { item.designator }
                                        </td>
                                        <td className="mlsp-2 px-2 truncate">
                                            { item.pn || item.mpn }
                                        </td>
                                        <td className="mlsp-2 px-2 truncate">
                                            { item.name }
                                        </td>
                                        <td className="mlsp-2 px-2 whitespace-no-wrap">
                                            { item.locator }
                                        </td>

                                        <td className="mlsp-2 text-xl">
                                            x{ item.amount }
                                        </td>

                                        <td className="relative">
                                            { cursor == index && <Button
                                                style="circle"
                                                className="absolute center ml-6"
                                                disabled={tooSoon || blocked}
                                                onClick={this.handleClick}
                                            >
                                                <FaCheck />
                                            </Button> }
                                        </td>
                                    </tr>
                                }) }
                            </tbody>
                        </table>
                    </div>
                }) }
            </div>
        </div>
    }

    handleClick = () => {
        const value = this.state.value + 1
        this.setState({ value })

        this.props.onInteraction()

        const totalItems = this.groupedItems().reduce((acc, cur) => acc + cur.items.length, 0)
        if (value == totalItems) {
            window.setTimeout(() => {
                this.props.onDone(this.state.value)
            }, 2500)
        }
    }
}
