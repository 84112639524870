import React from "react"

import {
    FaRegCheckCircle,
    FaRegClock,
    FaSyncAlt,
} from "react-icons/fa"

import {
    selectBatchStage,
} from "~/lib/selectors"

export default class BatchIcon extends React.Component {
    render() {
        const {
            batch
        } = this.props

        const stage  = selectBatchStage(batch)

        switch (stage) {
            case "done": return <FaRegCheckCircle
                title="Terminado"
                className="text-green-600"
            />

            case "building": return <FaSyncAlt
                title="Fabricando"
                className="text-blue-700"
            />

            case "ready": return <FaRegClock
                title="Preparado"
                className="text-gray-700"
            />

            default:
                throw new Error(`Unknown batch stage: ${stage}`)
        }
    }
}
