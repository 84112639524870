import isNil from "lodash/isNil"
import uniq from "lodash/uniq"

export const selectHasActiveFilters = filters => {
    return filters.sn.trim().length > 0
        || filters.doneAt !== undefined
        || filters.code.trim().length > 0
        || filters.enabledAt !== undefined
        || filters.pn.trim().length > 0
        || filters.createdAt !== undefined
}

export const selectBatchParTime = batch => {
    const t = selectBatchSteps(batch).reduce((acc, cur) => cur.parTime + acc, 0)
    return (batch.units !== -1 ? batch.units : 1) * t
}

export const selectBatchSteps = batch => {
    const steps = []
    for (let block of batch.blocks) {
        for (let step of block.steps) {
            steps.push(step)
        }
    }
    return steps
}

// Checks if a value is valid for a given step.
export const selectStepStatus = (step, value) => {
    let thresholdOk = true

    if (!isNil(step.min)) {
        const number = parseFloat(value)
        if (isNaN(number) || number < step.min) {
            thresholdOk = false
        }
    }

    if (!isNil(step.max)) {
        const number = parseFloat(value)
        if (isNaN(number) || number > step.max) {
            thresholdOk = false
        }
    }

    if (thresholdOk) {
        return "ok"
    } else if (step.critical) {
        return "error"
    } else {
        return "warn"
    }
}


export const selectBatchBuilders = (batch, users) => {
    const ids = uniq(selectBatchSteps(batch).filter(s => s.doneAt).map(s => s.doneBy))
    return ids.map(id => users[id])
}

export const selectBlockFromStepId = (batch, stepId) => {
    for (let block of batch.blocks) {
        for (let step of block.steps) {
            if (stepId == step.id) return block
        }
    }
    return null
}

export const selectBatchStage = batch => {
    if (batch.doneAt) {
        return "done"
    } else if (batch.enabledAt) {
        return "building"
    } else {
        return "ready"
    }
}
