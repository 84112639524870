import React from "react"
import cn from "classnames"

import {
    FaStar,
    FaRegStar,
} from "react-icons/fa"

import {
    times,
    fmtpriority,
} from "~/lib/utils"

export default class StarInput extends React.Component {
    render() {
        return <div
            className={cn("flex", this.props.className)}
        >
            { times(this.props.value, i => <button
                className="text-orange-600 mlsp-1 hover:text-orange-400"
                key={i}
                onClick={() => this.handleClick(i+1)}
                title={fmtpriority(i+1)}
                disabled={this.props.disabled}
            >
                <FaStar />
            </button>) }
            { times(3 - this.props.value, i => <button
                className="mlsp-1 text-gray-400 hover:text-orange-400"
                key={i}
                onClick={() => this.handleClick(this.props.value+i+1)}
                title={fmtpriority(this.props.value+i+1)}
                disabled={this.props.disabled}
            >
                <FaRegStar />
            </button>) }
        </div>
    }

    handleClick = (i) => {
        this.props.onChange(i)
    }
}
