import React from "react"
import ReactModal from "react-modal"
import axios from "axios"
import cn from "classnames"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import maxBy from "lodash/maxBy"

import {
    FaWindowClose,
} from "react-icons/fa"

import {
    notify
} from "~/store"

import BatchIcon from "~/components/BatchIcon"
import CreateOrderModal from "~/components/CreateOrderModal"
import ShipOrderModal from "~/components/ShipOrderModal"
import ReclaimOrderModal from "~/components/ReclaimOrderModal"
import OrderMediaItem from "~/components/OrderMediaItem"
import TreeTab from "~/components/TreeTab"

import Button from "~/components/Button"
import Select from "~/components/Select"
import Input from "~/components/Input"

const mapDispatchToProps = dispatch => ({
    onNotify: (...args) => dispatch(notify(...args)),
})

export default connect(null, mapDispatchToProps)(class InstanceModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentTab: "orders",

            emails: [],
            orders: [],
            tags:   [],
            tagAssociations: [],

            selectedTagId: "",
            tagValue: "",

            showCreateOrderModal:   false,
            showShipOrderModal:     false,
            showReclaimOrderModal:  false,
            showEditOrderModal:     false,
        }
    }

    async componentDidMount() {
        await this.refreshEmails()
        await this.refreshOrders()
        await this.refreshTags()
        await this.refreshTagAssociations()
    }

    async refreshEmails() {
        const { instance } = this.props
        const response = await axios.get(`/instances/${instance.id}/emails`)
        this.setState({
            emails: response.data
        })
    }

    async refreshOrders() {
        const { instance } = this.props
        const response = await axios.get(`/instances/${instance.id}/orders`)
        this.setState({
            orders: response.data
        })
    }

    async refreshTags() {
        const response = await axios.get("/tags")
        const tags = response.data
        this.setState({
            tags,
            selectedTagId: tags.length > 0 ? tags[0].id : "",
        })
    }

    async refreshTagAssociations() {
        const { instance } = this.props
        const response = await axios.get(`/instances/${instance.id}/tags`)
        this.setState({
            tagAssociations: response.data
        })
    }

    async handleDeleteTagClick(ta) {
        await axios.delete(`/instances/${ta.instanceId}/tags/${ta.tagId}`)
        await this.refreshTagAssociations()
    }

    render() {
        const {
            instance,
            batch,
        } = this.props
        const {
            currentTab,
            orders,
        } = this.state

        const lastOrder = maxBy(orders, o => o.createdAt)

        const tabClass = tab => cn("p-2 w-full uppercase text-sm rounded-t", {
            "text-white":           currentTab == tab,
            "font-semibold":        currentTab == tab,
            "bg-blue-500":          currentTab == tab,
            "hover:bg-blue-400":    currentTab == tab,

            "font-extralight":      currentTab != tab,
            "text-gray-800":        currentTab != tab,
            "hover:bg-blue-200":    currentTab != tab,
        })

        let treeInfo = this.state.tagAssociations.find(t => t.ref == "tree_info")?.value
        if (treeInfo) treeInfo = JSON.parse(treeInfo)


        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <div className="mb-4 flex">
                <div className="flex px-4 items-center justify-center text-xl">
                    <BatchIcon batch={batch} />
                </div>
                <div>
                    <div className="flex items-baseline">
                        <div className="text-xl">
                            { batch.name } ({ batch.pn } )
                        </div>
                        <span className="ml-2 text-gray-700">
                            (x{ batch.units })
                        </span>
                    </div>
                    <div className="font-extralight">
                        { batch.code }
                    </div>
                    <div>
                        { instance.code }
                    </div>
                </div>
            </div>

            <div className="flex -mx-4 px-4 border-b border-blue-500">
                <button
                    className={tabClass("orders")}
                    onClick={() => this.setState({ currentTab: "orders" })}
                >
                    Pedidos ({ this.state.orders.length })
                </button>
                <button
                    className={tabClass("emails")}
                    onClick={() => this.setState({ currentTab: "emails" })}
                >
                    Emails ({ this.state.emails.length })
                </button>
                <button
                    className={tabClass("tags")}
                    onClick={() => this.setState({ currentTab: "tags" })}
                >
                    Tags ({ this.state.tagAssociations.length })
                </button>
                <button
                    className={tabClass("tree")}
                    onClick={() => this.setState({ currentTab: "tree" })}
                >
                    Árbol
                </button>
            </div>

            { currentTab == "orders" && <div className="pt-2">
                <div className="flex mb-4">
                    { (!lastOrder || lastOrder.reclaimedAt) && <Button
                        theme="outline"
                        style="compact"
                        onClick={this.handleAssociateOrderClick}
                    >
                        Asociar pedido
                    </Button> }

                    { (lastOrder && !lastOrder.reclaimedAt) && <Button
                        theme="outline"
                        style="compact"
                        onClick={this.handleEditOrderClick}
                    >
                        Modificar pedido
                    </Button> }
                    { (lastOrder && !lastOrder.shippingAt) && <Button
                        theme="outline"
                        style="compact"
                        className="ml-2"
                        onClick={this.handleShipOrderClick}
                    >
                        Enviar
                    </Button> }
                    { (lastOrder && lastOrder.shippingAt && !lastOrder.reclaimedAt) && <Button
                        theme="outline"
                        style="compact"
                        className="ml-2"
                        onClick={this.handleReclaimOrderClick}
                    >
                        Recuperar
                    </Button> }
                </div>

                { orders.map((order, idx) => <OrderMediaItem
                    key={order.id}
                    className={cn({ "bg-blue-200 rounded": idx == 0 })}
                    order={order}

                    onClickResendStartEmail={() => this.handleResendStartEmailClick(order)}
                    onClickResendDoneEmail={() => this.handleResendDoneEmailClick(order)}
                    onClickResendShippingEmail={() => this.handleResendShippingEmailClick(order)}
                />) }

                { this.state.orders.length == 0 && <div className="text-gray-700">
                    No hay pedidos asociados con esta instancia.
                </div> }
            </div> }

            { currentTab == "emails" && <div className="pt-2">
                { this.state.emails.map(email => <div
                    className="flex mtsp-4"
                    key={email.id}
                >
                    <div className="flex-1">
                        <div className="flex text-xs whitespace-no-wrap text-gray-700">
                            <div>
                                { email.recipients.join(", ") }
                            </div>
                            <div className="ml-auto">
                                { email.type }
                            </div>
                        </div>
                        <div className="text-sm truncate">
                            { email.subject }
                        </div>
                    </div>
                    <div className="ml-2">
                        <div className="text-xs">
                            { email.status }
                        </div>
                        <div className="text-xs">
                            { DateTime.fromISO(email.sentAt).toLocaleString(DateTime.DATETIME_SHORT) }
                        </div>
                    </div>
                </div>) }

                { this.state.emails.length == 0 && <div className="text-gray-700">
                    No hay emails asociados con esta instancia.
                </div> }
            </div> }

            { currentTab == "tags" && <div className="pt-2">
                <form
                    className="flex items-end"
                    onSubmit={this.handleTagFormSubmit}
                >
                    <div>
                        <label className="uppercase text-xs">
                            Tag
                        </label>
                        <Select
                            value={this.state.selectedTagId}
                            onChange={this.handleInput("selectedTagId")}
                        >
                            { this.state.tags.map(tag => <option
                                key={tag.id}
                                value={tag.id}
                            >
                                { tag.ref }
                            </option>) }
                        </Select>
                    </div>
                    <div className="ml-2">
                        <label className="uppercase text-xs">
                            Valor
                        </label>
                        <Input
                            value={this.state.tagValue}
                            onChange={this.handleInput("tagValue")}
                        />
                    </div>
                    <Button className="ml-2">
                        Crear
                    </Button>
                </form>

                <div className="mt-4">
                    { this.state.tagAssociations.map((ta, idx) => <div
                        className="flex px-2 py-1 border rounded items-center"
                        key={idx}
                    >
                        <div className="overflow-hidden">
                            <strong>{ ta.ref }</strong>: <span>{ ta.value }</span>
                        </div>
                        <Button
                            theme="danger"
                            style="compact"
                            className="ml-auto"
                            onClick={() => this.handleDeleteTagClick(ta)}
                        >
                            Borrar
                        </Button>
                    </div>) }
                </div>
            </div> }

            { currentTab == "tree" && <TreeTab
                instance={instance}
                latitude={treeInfo?.latitude}
                longitude={treeInfo?.longitude}
                location={treeInfo?.location}
                description={treeInfo?.description}
                imageUrl={treeInfo?.imageUrl}
                onSubmit={this.handleSubmitTree}
                className="pt-2"
            /> }

            { this.state.showCreateOrderModal && <CreateOrderModal
                onSubmit={this.handleAssociateOrder}
                onRequestClose={() => this.setState({ showCreateOrderModal: false })}
            /> }

            { this.state.showEditOrderModal && <CreateOrderModal
                onSubmit={args => this.handleEditOrder(lastOrder, args)}
                erpReference={lastOrder.erpReference}
                clientName={lastOrder.clientName}
                clientEmail={lastOrder.clientEmail}
                clientCountry={lastOrder.clientCountry}
                onRequestClose={() => this.setState({ showEditOrderModal: false })}
            /> }

            { this.state.showShipOrderModal && <ShipOrderModal
                onSubmit={this.handleShipOrder}
                onRequestClose={() => this.setState({ showShipOrderModal: false })}
            /> }

            { this.state.showReclaimOrderModal && <ReclaimOrderModal
                onSubmit={this.handleReclaimOrder}
                onRequestClose={() => this.setState({ showReclaimOrderModal: false })}
            /> }
        </ReactModal>
    }

    handleSubmitTree = async ({ latitude, longitude, location, description, imageUrl}) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/tree-info`, {
            latitude,
            longitude,
            location,
            description,
            imageUrl
        })
        await this.props.onNotify("success", "Árborito plantado.")
        this.refreshTags()
        this.refreshTagAssociations()
    }

    handleInput = path => value => {
        this.setState({
            [path]: value
        })
    }

    handleTagFormSubmit = async event => {
        event.preventDefault()

        const { instance } = this.props

        await axios.post(`/instances/${instance.id}/tags`, {
            tagId: this.state.selectedTagId,
            value: this.state.tagValue,
        })

        this.setState({ tagValue: "" })

        await this.refreshTagAssociations()
    }

    handleEditOrderClick = () => {
        this.setState({
            showEditOrderModal: true
        })
    }

    handleEditOrder = async (order, { erpReference, clientName, clientEmail, clientCountry }) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/orders/${order.id}`, {
            erpReference,
            clientName,
            clientEmail,
            clientCountry,
        })
        await this.refreshOrders()
        this.setState({ showEditOrderModal: false })
        await this.props.onNotify("success", "Pedido actualizado")
    }

    handleResendStartEmailClick = async (order) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/orders/${order.id}/email-client-assembly-start`)
        await this.refreshEmails()
        await this.props.onNotify("success", "Email de inicio enviado.")
    }

    handleResendDoneEmailClick = async (order) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/orders/${order.id}/email-client-assembly-done`)
        await this.refreshEmails()
        await this.props.onNotify("success", "Email de finalización enviado.")
    }

    handleResendShippingEmailClick = async (order) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/orders/${order.id}/email-client-assembly-shipping`)
        await this.refreshEmails()
        await this.props.onNotify("success", "Email de envío enviado.")
    }

    handleAssociateOrderClick = () => {
        this.setState({ showCreateOrderModal: true })
    }

    handleAssociateOrder = async ({ erpReference, clientName, clientEmail, clientCountry }) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/orders`, {
            erpReference,
            clientName,
            clientEmail,
            clientCountry,
        })
        await this.refreshOrders()
        this.setState({ showCreateOrderModal: false })
        await this.props.onNotify("success", "Pedido asociado")
    }

    handleShipOrderClick = async () => {
        this.setState({ showShipOrderModal: true })
    }

    handleShipOrder = async ({ courier, trackingNumber, trackingUrl, sendNotification }) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/ship`, {
            courier, trackingNumber, trackingUrl, sendNotification
        })
        await this.refreshOrders()
        this.setState({ showShipOrderModal: false })
        await this.props.onNotify("success", "Instancia enviada")
    }

    handleReclaimOrderClick = async () => {
        this.setState({ showReclaimOrderModal: true })
    }

    handleReclaimOrder = async ({ reason }) => {
        const { instance } = this.props
        await axios.post(`/instances/${instance.id}/reclaim`, { reason })
        await this.refreshOrders()
        this.setState({ showReclaimOrderModal: false })
        await this.props.onNotify("success", "Instancia recuperada")
    }
})
