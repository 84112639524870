import React from "react"
import { connect } from "react-redux"
import ReactModal from "react-modal"

import {
    FaSpinner,
    FaWindowClose,
} from "react-icons/fa"

import {
    notify
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"
import StockItemSelector from "~/components/StockItemSelector"

const mapDispatchToProps = dispatch => ({
    onNotify: (...args) => dispatch(notify(...args))
})

export default connect(null, mapDispatchToProps)(class AddStockDescendantModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedItemId: this.props.selectedItemId || null,
            amount:         this.props.amount || 0,
            notes:          this.props.notes || "",

            working: false,
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <div className="mtsp-2">
                    <label className="text-xs uppercase">
                        Ítem
                    </label>
                    <StockItemSelector
                        value={this.state.selectedItemId}
                        onChange={this.handleInput("selectedItemId")}
                    />
                </div>

                <div className="mtsp-2">
                    <label className="text-xs uppercase">
                        Cantidad
                    </label>
                    <Input
                        value={this.state.amount}
                        type="number"
                        onChange={v => this.handleInput("amount")(Number(v))}
                    />
                </div>

                <div className="mtsp-2">
                    <label className="text-xs uppercase">
                        Designador
                    </label>
                    <Input
                        value={this.state.notes}
                        onChange={this.handleInput("notes")}
                    />
                </div>

                <div className="flex mtsp-2">
                    <Button
                        className="ml-auto"
                        disabled={this.state.working}
                    >
                        Guardar
                        { this.state.working && <div className="ml-2 flex items-center">
                            <FaSpinner className="spin text-xl text-gray-700" />
                        </div> }
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleInput = (prop) => {
        return value => {
            this.setState({ [prop]: value })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        const { selectedItemId, notes, amount } = this.state
        if (!selectedItemId) {
            this.props.onNotify("warn", "Debes seleccionar un ítem")
            return
        }

        this.setState({ working: true })
        try {
            await this.props.onSelectItem(selectedItemId, notes, amount)
        } finally {
            this.setState({ working: false })
        }

        this.props.onRequestClose()
    }
})
