import React from "react"
import { Link } from "react-router-dom"

import {
    IoMdReturnLeft
} from "react-icons/io"

import StockReport from "~/components/StockReport"
import EanUsage from "~/components/EanUsage"
import TimeReport from "~/components/TimeReport"
import SeqUsage from "~/components/SeqUsage"
import Schedule from "~/components/Schedule"
import TreeReport from "~/components/TreeReport"

import Button from "~/components/Button"

export default function ReportsView() {
    return <div className="max-w-6xl mx-auto p-2">
        <div className="flex items-center mb-2">
            <Link to="/">
                <Button theme="transparent">
                    <IoMdReturnLeft />
                </Button>
            </Link>
            <h1 className="text-xl ml-2">
                Informes
            </h1>
        </div>

        <Schedule className="shadow rounded" />

        <StockReport className="mt-8 shadow rounded" />

        <EanUsage className="mt-8 shadow rounded" />

        <SeqUsage className="mt-8 shadow rounded" />

        <TimeReport className="mt-8 shadow rounded" />

        <TreeReport className="mt-8 shadow rounded" />
    </div>
}
