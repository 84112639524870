import React from "react"
import { DateTime } from "luxon"

import Input from "~/components/Input"
import CalendarModal from "~/components/CalendarModal"

export default class InputDate extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showCalendar: false
        }
    }

    render() {
        const {
            value
        } = this.props
        const {
            showCalendar
        } = this.state

        return <div>
            <Input
                value={value ? DateTime.fromJSDate(value).toFormat("yyyy LLL dd") : ""}
                onClick={this.handleInputClick}
            />

            <CalendarModal
                isOpen={showCalendar}
                value={value}
                onRequestClose={this.handleCloseModal}
                onChange={this.handleCalendarChange}
            />
        </div>
    }

    handleCloseModal = () => {
        this.setState({
            showCalendar: false
        })
    }

    handleCalendarChange = v => {
        this.props.onChange(v)
        this.setState({
            showCalendar: false
        })
    }

    handleInputClick = () => {
        this.setState({
            showCalendar: true
        })
    }
}
