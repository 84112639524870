import React from "react"
import { DateTime } from "luxon"
import cn from "classnames"

import {
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

import Button from "~/components/Button"

const level2class = level => {
    switch (level) {
        case 60: return "bg-red-900"
        case 50: return "bg-red-300"
        case 40: return "bg-yellow-200"
        case 30: return "bg-white"
        case 20: return "bg-blue-100"
        case 10: return "bg-gray-100"
        default :
            throw new Error(`Unknown level ${level}`)
    }
}

export default class LogEntry extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            folded: true
        }
    }

    render() {
        const { entry } = this.props

        return <>
            <div className={cn("flex items-center p-1", level2class(entry.level))}>
                <div className="mlsp-4">
                    <Button
                        style="compact"
                        theme="transparent"
                        onClick={this.toggleFolded}
                    >
                        { this.state.folded ? <FaChevronDown /> : <FaChevronUp /> }
                    </Button>
                </div>
                <div className="mlsp-4 text-sm text-gray-700">
                    { DateTime.fromISO(entry.time).toFormat("yyyy/LL/dd hh:mm:ss") }
                </div>
                <div className="mlsp-4">
                    { entry.msg }
                </div>
            </div>
            { !this.state.folded && <div>
                <pre className="text-xs bg-gray-100 px-1 py-2">
                    { JSON.stringify(entry, null, 4) }
                </pre>
            </div> }
        </>
    }

    toggleFolded = () => {
        this.setState(s => ({
            folded: !s.folded
        }))
    }
}
