import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import Select from "~/components/Select"
import TextArea from "~/components/TextArea"

const reasons = [
    "Golepado en transporte",
    "Ruido en potenciómetros",
    "Mal funcionamiento del vúmetro",
    "Salta el fusible",
    "Ruido",
    "No le gusta",
]

export default class ShipOrderModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reason: reasons[0],
            text: "",
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <div>
                    <label className="uppercase text-xs">
                        Motivo
                    </label>
                    <Select
                        value={this.state.reason}
                        onChange={this.handleInput("reason")}
                    >
                        { reasons.map((r, idx) => <option
                            key={idx}
                            value={r}
                        >
                            { r }
                        </option>) }
                        <option value={"otros"}>
                            Otros
                        </option>
                    </Select>

                    { this.state.reason == "otros" && <div>
                        <label className="uppercase text-xs">
                            Indicar motivo
                        </label>
                        <TextArea
                            value={this.state.text}
                            onChange={this.handleInput("text")}
                        />
                    </div> }
                </div>
                <div className="mt-4">
                    <Button className="ml-auto">
                        Enviar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleInput = path => value => {
        this.setState({ [path]: value })
    }

    handleSubmit = event => {
        event.preventDefault()

        if (this.state.reason == "otros") {
            this.props.onSubmit({ reason: this.state.text.trim() })
        } else {
            this.props.onSubmit({ reason: this.state.reason })
        }
    }
}
