import React from "react"
import urlJoin from "url-join"

import {
    FaCheck,
    FaFilePdf,
    FaSpinner,
    FaPencilAlt,
} from "react-icons/fa"

import Button from "~/components/Button"
import InputImage from "~/components/InputImage"
import PdfViewer from "~/components/PdfViewer"

/* global API_BASE_URL */
export default class StepPicture extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value:      props.answer?.value || "",
            thinking:   false,
            editing:    false,
            uploading:  false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.answer?.id != prevProps.answer?.id) {
            this.setState({
                value:      this.props.answer?.value || "",
                thinking:   false,
                editing:    false,
                uploading:  false,
            })
        }
    }

    render() {
        const {
            step,
            blocked,
            tooSoon,
            headless,
            answer,
        } = this.props
        const {
            value,
            thinking,
            editing,
            uploading,
        } = this.state

        const urls = value.split("::")
            .filter(v => v)
            .map(name => urlJoin(API_BASE_URL, "images", name))

        return <>
            <div className="relative">
                { step.manualVersionId && <PdfViewer
                    fit={headless ? "height" : "width"}
                    url={urlJoin(API_BASE_URL, "manuals", step.manualVersionId, "file")}
                    pageNumber={step.pageNumber}
                /> }
                { !step.manualVersionId && <div className="mt-20 text-gray-700 text-lg font-extralight flex items-center justify-center">
                    <FaFilePdf />
                    <span className="ml-2">
                        This step has no manual.
                    </span>
                </div> }
            </div>

            <div className="fixed bottom-0 inset-x-0">
                <form
                    className="flex"
                    onSubmit={editing ? this.handleEditSubmit : this.handleFormSubmit}
                >
                    <div className="p-1 bg-gray-200 self-end flex items-center rounded-tr overflow-hidden flex-1">
                        { urls.map((url, idx) => <InputImage
                            className="mr-2"
                            key={idx}
                            value={url}
                            canDelete={!blocked || editing}
                            onChange={() => this.handleDelete(idx)}
                        />) }
                        { (!blocked || editing) && <InputImage
                            onUploading={this.handleUploading}
                            onDone={this.handleDone}
                            onChange={this.handleChange}
                        /> }
                    </div>
                    <div className="ml-2 py-2 pr-2">
                        { (answer && !editing) && <Button
                            title="Editar"
                            theme="warn"
                            style="small-circle"
                            type="button"
                            onClick={this.handleEditClick}
                        >
                            <FaPencilAlt className="transform scale-50" />
                        </Button> }
                        { editing && <Button
                            title="Cancelar edición"
                            style="small-circle"
                            type="button"
                            theme="danger"
                            onClick={this.handleCancelEditClick}
                        >
                            <FaPencilAlt className="transform scale-50" />
                        </Button> }
                        <Button
                            title="Confirmar"
                            style="big-circle"
                            disabled={(blocked && !editing) || tooSoon || thinking || uploading}
                        >
                            { thinking ? <FaSpinner className="spin" /> : <FaCheck /> }
                        </Button>
                    </div>
                </form>
            </div>
        </>
    }

    handleCancelEditClick = e => {
        e.preventDefault()

        this.setState((_, props) => ({
            editing: false,
            value: props.answer?.value || "",
        }))
    }

    handleEditClick = e => {
        e.preventDefault()

        this.setState({ editing: true })
    }

    handleEditSubmit = async e => {
        e.preventDefault()

        this.setState({ thinking: true })
        try {
            await this.props.onEdit(this.state.value)
        } finally {
            this.setState({
                thinking: false,
                editing: false,
            })
        }
    }

    handleFormSubmit = async event => {
        event.preventDefault()

        this.props.onInteraction()

        this.setState({ thinking: true })
        try {
            await this.props.onDone(this.state.value)
        } finally {
            this.setState({ thinking: false })
        }
    }

    handleDelete = idx => {
        const names = this.state.value.split("::").filter(v => v)
        names.splice(idx, 1)
        this.setState({
            value: names.join("::")
        })
    }

    handleUploading = () => {
        this.setState({ uploading: true })
    }

    handleChange = name => {
        const names = this.state.value.split("::").filter(v => v)
        names.push(name)
        this.setState({
            value: names.join("::")
        })
    }

    handleDone = () => {
        this.setState({ uploading: false })
    }
}
