import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"

export default class EmailModal extends React.Component {
    render() {
        const {
            onRequestClose,
            isOpen,
            email,
        } = this.props

        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-3xl relative outline-none"
            onRequestClose={onRequestClose}
            isOpen={isOpen}
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <div dangerouslySetInnerHTML={{ __html: email?.body }} />
        </ReactModal>
    }
}
