import React from "react"
import { connect } from "react-redux"
import ReactModal from "react-modal"

import Button from "~/components/Button"
import StockItemSelector from "~/components/StockItemSelector"

import {
    notify
} from "~/store"

import {
    FaWindowClose,
} from "react-icons/fa"

const mapDispatchToProps = dispatch => ({
    onNotify: (...args) => dispatch(notify(...args))
})

export default connect(null, mapDispatchToProps)(class ImportStockDescendantsModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedItemId: null
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <div>
                    <label className="text-xs uppercase">
                        Ítem
                    </label>
                    <StockItemSelector
                        value={this.state.selectedItemId}
                        onChange={this.handleInput("selectedItemId")}
                    />
                </div>

                <div className="flex justify-end mt-2">
                    <Button>
                        Aceptar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleInput = (prop) => {
        return value => {
            this.setState({ [prop]: value })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        if (!this.state.selectedItemId) {
            this.props.onNotify("warn", "Debes seleccionar un ítem")
            return
        }

        await this.props.onImportItem(this.state.selectedItemId)
        this.props.onRequestClose()
    }
})
