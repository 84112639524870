import React from "react"
import ReactModal from "react-modal"
import urlJoin from "url-join"
import { DateTime } from "luxon"

import {
    FaExchangeAlt,
    FaWindowClose,
    FaEye,
    FaDownload,
} from "react-icons/fa"

import Button from "~/components/Button"

/* global API_BASE_URL */
export default class ManualVersionsModal extends React.Component {
    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-4xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <h1 className="mb-4">
                { this.props.manual.name }
            </h1>

            { this.props.manual.versions.map((version, index) => <div
                key={version.id}
                className="p-2 flex hover:bg-gray-200 rounded"
            >
                <div className="mlsp-2">
                    <Button
                        type="button"
                        theme="transparent"
                        style="compact"
                        onClick={() => this.props.onPreview(version)}
                        title="Previsualizar"
                    >

                        <FaEye />
                    </Button>
                </div>
                <div className="mlsp-2">
                    <span className="font-semibold mlsp-4">
                        { version.filename }
                    </span>
                    <span className="text-xs text-gray-700 mlsp-4">
                        { version.numPages } páginas
                    </span>
                    <span className="text-xs text-gray-700 mlsp-4">
                        Versión { index + 1}
                    </span>
                </div>
                <div className="ml-auto flex flex-col text-xs text-gray-700 text-right">
                    <span>
                        Creación: { DateTime.fromISO(version.createdAt).toLocal().toFormat("yyyy/LL/dd hh:mm:ss") }
                    </span>
                    <span>
                        Actualización: { DateTime.fromISO(version.updatedAt).toLocal().toFormat("yyyy/LL/dd hh:mm:ss") }
                    </span>
                </div>
                <div className="ml-2 flex items-center">
                    <Button
                        type="button"
                        theme="transparent"
                        style="compact"
                        className="mlsp-2"
                        onClick={() => this.props.onReplace(version)}
                        title="Reemplazar"
                    >
                        <FaExchangeAlt />
                    </Button>
                    <a
                        href={urlJoin(API_BASE_URL, "manuals", version.id, "file")}
                        className="mlsp-2"
                        title="Descargar"
                    >
                        <Button
                            theme="transparent"
                            style="compact"
                            type="button"
                        >
                            <FaDownload />
                        </Button>
                    </a>
                </div>
            </div>) }
        </ReactModal>
    }
}
