import React, { useState } from "react"
import urlJoin from "url-join"

import Button from "~/components/Button"
import Input from "~/components/Input"
import TextArea from "~/components/TextArea"
import InputImage from "~/components/InputImage"

/* global API_BASE_URL */
export default function TreeModal(props) {
    const { className, onSubmit } = props

    const [latitude, setLatitude] = useState(props.latitude || "")
    const [longitude, setLongitude] = useState(props.longitude || "")
    const [location, setLocation] = useState(props.location || "")
    const [description, setDescription] = useState(props.description || "")
    const [imageUrl, setImageUrl] = useState(props.imageUrl || "")

    const handleSubmit = event => {
        event.preventDefault()

        onSubmit({ latitude, longitude, location, description, imageUrl })
    }

    return <div className={className}>
        <form onSubmit={handleSubmit}>
            <div className="flex">
                <div>
                    <label className="uppercase text-xs">
                        Latitud
                    </label>
                    <Input
                        type="number"
                        value={latitude}
                        onChange={v => setLatitude(v)}
                    />
                </div>

                <div className="ml-2">
                    <label className="uppercase text-xs">
                        Longitud
                    </label>
                    <Input
                        type="number"
                        value={longitude}
                        onChange={v => setLongitude(v)}
                    />
                </div>

                <div className="ml-2">
                    <label className="uppercase text-xs">
                        Ubicación
                    </label>
                    <Input
                        value={location}
                        onChange={v => setLocation(v)}
                    />
                </div>
            </div>

            <div>
                <label className="uppercase text-xs">
                    Descripción
                </label>
                <TextArea
                    value={description}
                    onChange={v => setDescription(v)}
                />
            </div>

            <div>
                <label className="uppercase text-xs">
                    Imagen
                </label>
                <InputImage
                    target="/tree-images"
                    canDelete
                    value={imageUrl ? urlJoin(API_BASE_URL, "tree-images", imageUrl) : ""}
                    onChange={v => setImageUrl(v)}
                />
            </div>

            <div className="mt-2">
                <Button className="ml-auto">
                    Aceptar
                </Button>
            </div>
        </form>
    </div>
}
