import React, { useState, useEffect } from "react"
import cn from "classnames"
import axios from "axios"

import {
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

export default function SeqUsage({ className }) {
    const [folded, setFolded] = useState(true)
    const [indices, setIndices] = useState([])

    useEffect(() => {
        axios.get("/stats/seq-usage").then(response => {
            setIndices(response.data)
        })
    }, [])

    return <div className={cn("p-2 relative", className)}>
        <h2 className="text-2xl font-extralight">
            Números secuenciales
        </h2>
        <button
            className="absolute right-0 top-0 text-gray-300 bg-gray-700 hover:bg-gray-600 p-2 rounded-bl rounded-tr"
            onClick={() => setFolded(!folded)}
        >
            { folded ? <FaChevronDown /> : <FaChevronUp /> }
        </button>

        { !folded && <table>
            <thead>
                <tr>
                    <th>
                        PN
                    </th>
                    <th>
                        Seq
                    </th>
                </tr>
            </thead>
            <tbody>
                { indices.map(([pn, seq]) => <tr key={pn}>
                    <td className="text-center">
                        { pn }
                    </td>
                    <td className="text-center">
                        { seq }
                    </td>
                </tr>) }
            </tbody>
        </table> }
    </div>
}
