import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import isEqual from "lodash/isEqual"
import axios from "axios"

import {
    FaAngleDown,
    FaAngleUp,
    FaAngleLeft,
    FaAngleRight,
    FaBoxes,
    FaFilePdf,
    FaFilter,
    FaMailBulk,
    FaPlus,
    FaSignOutAlt,
    FaUsers,
    FaTags,
    FaFileInvoice,
} from "react-icons/fa"

import {
    MdDeviceHub,
} from "react-icons/md"

import {
    IoMdStats
} from "react-icons/io"

import {
    RiRecordMailLine
} from "react-icons/ri"

import {
    logout,
    refreshBatches,
    updateBatchesPagination,
    updateBatchFilters,
    defaultBatchFilterValues,
    resetBatch,
    deleteBatch,
    updateBatch,
} from "~/store"

import {
    selectHasActiveFilters,
} from "~/lib/selectors"

import BatchMediaItem from "~/components/BatchMediaItem"
import BatchInfoModal from "~/components/BatchInfoModal"
import AdvancedSearch from "~/components/AdvancedSearch"
import StartBatchModal from "~/components/StartBatchModal"

import Button from "~/components/Button"

const mapStateToProps = state => ({
    batches: state.app.batches.entities,

    currentUser: state.app.users[state.app.currentUserId],
    page: state.app.batches.page.map(id => state.app.batches.entities[id]),
    totalPages: Math.ceil(state.app.batches.count / state.app.batches.limit),
    totalResults: state.app.batches.count,
    currentPage: 1 + Math.floor(state.app.batches.offset / state.app.batches.limit),
    filters: state.app.batches.filters,
    hasActiveFilters: selectHasActiveFilters(state.app.batches.filters),
})

const mapDispatchToProps = dispatch => ({
    onLogout:            () => dispatch(logout()),
    onRefreshBatches:    () => dispatch(refreshBatches()),
    onPageClick:         (dir) => dispatch(updateBatchesPagination(dir)),
    onUpdateFilter:      (filters) => dispatch(updateBatchFilters(filters)),
    onResetBatch:        (...args) => dispatch(resetBatch(...args)),
    onDeleteBatch:       (...args) => dispatch(deleteBatch(...args)),
    onUpdateBatch:       (...args) => dispatch(updateBatch(...args)),
})

const stages = {
    ready: {
        enabledAt:  null,
        pn:         "",
        code:       "",
        ownedBy:    "",

        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
    building: {
        enabledAt:  true,
        doneAt:     null,

        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
    done: {
        doneAt:     true,
        uploadedAt: null,

        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
    uploaded: {
        uploadedAt: true,

        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
    shipping: {
        shippingAt: true,

        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
    all: {
        orderBy:    "doneAt",
        orderDir:   "DESC",
    },
}

export default connect(mapStateToProps, mapDispatchToProps)(class Take2View extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showStartBatchModal: false,
            showAdvancedSearch: false,
            selectedBatchId: null,
            openBatchesIds: [],
        }
    }

    componentDidMount() {
        this.props.onRefreshBatches()

        if (this.props.currentUser.admin) {
            this.intervalId = window.setInterval(() => {
                axios.get(`/opened-batches`)
                .then(response => {
                    this.setState({ openBatchesIds: response.data })
                })
            }, 7500)
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId)
    }

    render() {
        const {
            batches,
            currentUser,
            page,
            currentPage,
            totalPages,
            filters,
            hasActiveFilters,
            totalResults,
        } = this.props

        const {
            showStartBatchModal,
            showAdvancedSearch,
            selectedBatchId,
            openBatchesIds,
        } = this.state

        const stageIsSelected = stage => isEqual(
            Object.assign({}, defaultBatchFilterValues, stage),
            Object.assign({}, defaultBatchFilterValues, filters),
        )

        if (!currentUser) return ""

        return <div className="max-w-4xl mx-auto p-2">
            <div className="flex items-center overflow-auto mb-2">
                { currentUser.admin && <Link
                    title="Usuarios"
                    className="-ml-2 p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                    to="/users"
                >
                    <FaUsers className="mr-1"/>
                    Usuarios
                </Link> }
                <Link
                    to="/manuals"
                    title="Manuales"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <FaFilePdf className="mr-1" />
                    Manuales
                </Link>
                { currentUser.admin && <Link
                    to="/products"
                    title="Productos"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <MdDeviceHub className="mr-1"/>
                    Productos
                </Link> }
                <Link
                    to="/stock"
                    title="Stock"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <FaBoxes className="mr-1"/>
                    Stock
                </Link>
                { currentUser.admin && <Link
                    to="/emails"
                    title="Emails"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <FaMailBulk className="mr-1"/>
                    Emails
                </Link> }
                <Link
                    to="/stats"
                    title="Estadísticas"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <IoMdStats className="mr-1"/>
                    Stats
                </Link>
                { currentUser.admin && <Link
                    to="/reports"
                    title="Informes"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <FaFileInvoice className="mr-1" />
                    Informes
                </Link> }
                { currentUser.admin && <Link
                    to="/logs"
                    title="Logs"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <RiRecordMailLine className="mr-1" />
                    Logs
                </Link> }
                { currentUser.admin && <Link
                    to="/tags"
                    title="Tags"
                    className="p-2 flex items-center text-blue-700 font-semibold text-sm hover:text-blue-500"
                >
                    <FaTags className="mr-1" />
                    Tags
                </Link> }
                <div className="ml-auto flex items-center">
                    <span className="text-gray-700 text-sm font-bold">
                        { currentUser.name }
                    </span>
                    <Button
                        className="ml-2"
                        title="Salir"
                        onClick={this.handleLogOutClick}
                        theme="transparent"
                    >
                        <FaSignOutAlt />
                    </Button>
                </div>
            </div>

            <div className="flex items-center mb-2">
                <h1 className="text-xl mr-auto">
                    Lotes
                </h1>
                { currentUser.admin && <Button
                    style="compact"
                    theme="outline"
                    onClick={this.handleCreateBatchClick}
                >
                    <FaPlus className="mr-2" />
                    Crear lote
                </Button> }
            </div>

            <div className="flex mb-2 overflow-auto">
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.all) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.all)}
                >
                    Todos
                </Button>
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.ready) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.ready)}
                >
                    Preparados
                </Button>
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.building) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.building)}
                >
                    Fabricando
                </Button>
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.done) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.done)}
                >
                    Terminados
                </Button>
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.uploaded) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.uploaded)}
                >
                    Publicados
                </Button>
                <Button
                    className="mlsp-2"
                    theme={stageIsSelected(stages.shipping) ? "primary" : "transparent"}
                    style="compact"
                    onClick={() => this.handleSelectStage(stages.shipping)}
                >
                    Enviados
                </Button>
                <Button
                    title="Filtros"
                    className="ml-auto"
                    theme={hasActiveFilters ? "primary" : "transparent"}
                    style="compact"
                    onClick={this.handleToggleAdvancedSearch}
                >
                    <FaFilter />
                    { showAdvancedSearch ? <FaAngleUp className="ml-2" /> : <FaAngleDown className="ml-2"/> }
                </Button>
            </div>

            { showAdvancedSearch && <AdvancedSearch className="mb-2 shadow rounded p-4" /> }

            <div className="flex items-center mb-2">
                <Button
                    title="Página anterior"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(-1)}
                >
                    <FaAngleLeft />
                </Button>
                <span className="mx-2 text-sm text-gray-700 font-bold">
                    { currentPage } / { totalPages }
                </span>
                <Button
                    title="Página siguiente"
                    theme="outline"
                    style="compact"
                    onClick={() => this.handlePageClick(1)}
                >
                    <FaAngleRight />
                </Button>
                <div className="ml-auto text-gray-700">
                    { totalResults } resultados
                </div>
            </div>

            { page.map(batch => <BatchMediaItem
                className="mtsp-3"
                batch={batch}
                key={batch.id}
                live={openBatchesIds.includes(batch.id)}
                onInfoClick={() => this.handleBatchInfoClick(batch)}
            />) }

            { selectedBatchId && <BatchInfoModal
                batch={batches[selectedBatchId]}
                onRequestClose={() => this.setState({ selectedBatchId: null })}
                onReset={this.handleReset}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
            /> }

            { showStartBatchModal && <StartBatchModal
                onRequestClose={() => this.setState({ showStartBatchModal: false })}
            /> }
        </div>
    }

    handleReset = async () => {
        await this.props.onResetBatch(this.state.selectedBatchId)
        this.setState({ selectedBatchId: null })
    }

    handleDelete = async () => {
        await this.props.onDeleteBatch(this.state.selectedBatchId)
        this.setState({ selectedBatchId: null })
    }

    handleUpdate = async () => {
        await this.props.onUpdateBatch(this.state.selectedBatchId)
        this.setState({ selectedBatchId: null })
    }

    handleLogOutClick = () => {
        this.props.onLogout()
    }

    handleCreateBatchClick = () => {
        this.setState({
            showStartBatchModal: true
        })
    }

    handlePageClick = (dir) => {
        this.props.onPageClick(dir)
    }

    handleBatchInfoClick = batch => {
        this.setState({
            selectedBatchId: batch.id
        })
    }

    handleToggleAdvancedSearch = () => {
        this.setState(s => ({
            showAdvancedSearch: !s.showAdvancedSearch
        }))
    }

    handleSelectStage = (stage) => {
        const filters = Object.assign({}, defaultBatchFilterValues, stage)
        this.props.onUpdateFilter(filters)
    }
})
