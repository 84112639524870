import React from "react"
import ReactModal from "react-modal"
import { connect } from "react-redux"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import SpinnerModal from "~/components/SpinnerModal"

import {
    replaceManualVersion
} from "~/store"

const mapDispatchToProps = dispatch => ({
    onReplaceManualVersion: (...args) => dispatch(replaceManualVersion(...args)),
})

export default connect(null, mapDispatchToProps)(class ReplaceManualVersionModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSpinner: false,
        }
    }

    render() {
        return <ReactModal
            className="m-8 p-4 mx-auto max-w-xl relative bg-white rounded overflow-hidden"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0 z-10"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <h2 className="text-xl">
                { this.props.manual.name }
            </h2>

            <form
                onSubmit={this.handleSubmit}
            >
                <input
                    ref={el => this.inputFile = el}
                    accept=".pdf"
                    type="file"
                />
                <p className="my-4 bg-gray-200 italic p-4 text-sm rounded text-gray-700">
                    Reemplazar este manual afectará tanto a montajes nuevos como a existentes.
                </p>
                <Button className="ml-auto">
                    Reemplazar
                </Button>
            </form>

            { this.state.showSpinner && <SpinnerModal /> }
        </ReactModal>
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        const files = Array.from(this.inputFile.files)
        if (files.length == 0) return

        this.setState({ showSpinner: true })
        try {
            await this.props.onReplaceManualVersion(this.props.manual, this.props.version, files[0])
        } finally {
            this.setState({ showSpinner: false })
        }
        this.props.onRequestClose()
    }
})
