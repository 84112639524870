import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import {
    FaRegCheckCircle,
    FaSpinner,
    FaWindowClose,
} from "react-icons/fa"

import {
    TiWarningOutline
} from "react-icons/ti"

import {
    createBatch,
} from "~/store"

import {
    fmtpriority
} from "~/lib/utils"

import Button from "~/components/Button"
import Input from "~/components/Input"
import InputDate from "~/components/InputDate"
import ProductSelector from "~/components/ProductSelector"
import Select from "~/components/Select"

export default function StartBatchModal({
    onRequestClose
}) {
    const dispatch = useDispatch()

    const users = useSelector(s => s.app.users)

    const [product, setProduct] = useState(null)
    const [units, setUnits] = useState(1)
    const [finishAt, setFinishAt] = useState(new Date())
    const [priority, setPriority] = useState(1)
    const [ownedBy, setOwnedBy] = useState(Object.values(users)[0]?.id)
    const [problems, setProblems] = useState([])
    const [checkingStock, setCheckingStock] = useState(false)
    const [average, setAverage] = useState(0)

    useEffect(() => {
        if (!product) return

        axios.get(`/stats/time-report/${product.pn}`)
        .then(response => {
            const { avg } = response.data
            setAverage(avg)
        })
    }, [product])

    useEffect(() => {
        if (!product) return

        setCheckingStock(true)
        axios.get(`/products/${product.id}/shortages?units=${units}`)
        .then(response => {
            setProblems(response.data.shortages.map(s => ({
                message: s,
                critical: false, // TODO XXX Temporary allow
            })))
            setCheckingStock(false)
        }, error => {
            if (error.response.status == 400) {
                setProblems([{
                    message: error.response.data.message,
                }])
                setCheckingStock(false)
            } else {
                return Promise.reject(error)
            }
        })
    }, [product, units])

    const handleBatchCreation = async () => {
        if (!product) {
            window.alert("Debes seleccionar un producto.")
            return
        }

        if (problems.length > 0) {
            const go = window.confirm(
                "Parece que este lote tendrá problemas. ¿Estás seguro de que deseas continuar?"
            )
            if (!go) return
        }

        await dispatch(createBatch({
            productId: product.id,
            units,
            finishAt,
            ownedBy,
            priority,
        }))
        onRequestClose()
    }

    const activeUsers = Object.values(users).filter(u => u.active)

    return <ReactModal
        className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-3xl relative outline-none"
        onRequestClose={onRequestClose}
        isOpen
    >
        <Button
            className="absolute left-0 top-0"
            style="compact"
            theme="transparent"
            onClick={onRequestClose}
        >
            <FaWindowClose />
        </Button>

        <h1 className="text-xl">
            Crear nuevo lote
        </h1>

        <div className="flex items-center mt-2">
            <div className="mlsp-2 flex-grow">
                <label className="text-xs uppercase">
                    Producto
                </label>
                <ProductSelector
                    value={product}
                    onChange={v => setProduct(v)}
                />
            </div>

            { product && <div className="mlsp-2">
                <label className="text-xs uppercase">
                    Cantidad óptima de fabricación
                </label>
                <div>
                    { product.optimalUnits }
                </div>
            </div> }

            { (product && average) && <div className="mlsp-2">
                <label className="text-xs uppercase">
                    Tiempo medio de fabricación
                </label>
                <div>
                    { average.toFixed(0) } minutos / unidad
                </div>
            </div> }
        </div>

        <div className="flex items-end mt-2">
            <div className="mlsp-2 w-full">
                <label className="text-xs uppercase">
                    Unidades
                </label>
                <Input
                    value={units}
                    onChange={v => setUnits(v)}
                    type="number"
                    min="1"
                    max="50"
                    step="1"
                />
            </div>

            <div className="mlsp-2 w-full">
                <label className="text-xs uppercase">
                    Fecha objetivo
                </label>
                <InputDate
                    value={finishAt}
                    onChange={v => setFinishAt(v)}
                />
            </div>
            <div className="mlsp-2 w-full">
                <label className="text-xs uppercase">
                    Prioridad
                </label>
                <Select
                    value={priority}
                    onChange={v => setPriority(v)}
                >
                    <option value={1}>{ fmtpriority(1) }</option>
                    <option value={2}>{ fmtpriority(2) }</option>
                    <option value={3}>{ fmtpriority(3) }</option>
                </Select>
            </div>
            <div className="mlsp-2 w-full">
                <label className="text-xs uppercase">
                    Asignado a
                </label>
                <Select
                    value={ownedBy}
                    onChange={v => setOwnedBy(v)}
                >
                    { activeUsers.map(user => {
                        return <option
                            value={user.id}
                            key={user.id}
                        >
                            { user.name }
                        </option>
                    }) }
                </Select>
            </div>
        </div>

        <div className="mt-2 flex">
            <Button
                onClick={handleBatchCreation}
                disabled={checkingStock || problems.some(p => p.critical)}
            >
                Crear
            </Button>

            { checkingStock && <div className="ml-2 flex items-center">
                <FaSpinner className="spin text-xl text-gray-700" />
            </div> }
        </div>

        { product && <div className="mt-4">
            { (!checkingStock && problems.length == 0) && <div className="flex items-center">
                <FaRegCheckCircle className="text-green-600 mr-1" />
                <span className="text-sm text-green-800">
                    Ningún problema
                </span>
            </div> }

            { (!checkingStock && problems.length > 0) && <div>
                <div className="flex items-center">
                    <TiWarningOutline className="text-yellow-600 mr-1" />
                    <span className="text-sm text-yellow-800">
                        Hay problemas:
                    </span>
                </div>

                <ul className="text-sm">
                    { problems.map((s, idx) => <li
                        className="mtsp-1"
                        key={idx}
                    >
                        { s.message }
                    </li>) }
                </ul>
            </div> }
        </div> }
    </ReactModal>
}
