import React from "react"
import axios from "axios"
import debounce from "lodash/debounce"
import isEqual from "lodash/isEqual"

import Input from "~/components/Input"
import Button from "~/components/Button"

// TODO "No hay resultados"
export default class ManualSelector extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            query: "",
            results: [],
            manual: null
        }

        this.refreshResults = debounce(this.refreshResults, 500)
    }

    async componentDidMount() {
        const manual = await this.getManual()
        if (!isEqual(manual, this.state.manual)) {
            this.setState({ manual })
        }
    }

    async componentDidUpdate() {
        const manual = await this.getManual()
        if (!isEqual(manual, this.state.manual)) {
            this.setState({ manual })
        }
    }

    async getManual() {
        const { value } = this.props
        if (!value) return null
        const response = await axios.get(`/manuals?versionId=${value}`)
        return response.data[0]
    }

    render() {
        const { manual } = this.state
        const lastVersion = manual?.versions[manual?.versions.length-1]

        if (manual) {
            return <div className="flex">
                <div className="w-full">
                    <div>
                        { manual.name } v{ manual.versions.length}
                    </div>
                    <div className="text-xs text-gray-700">
                        { lastVersion.numPages } páginas
                    </div>
                </div>
                <Button
                    type="button"
                    theme="outline"
                    className="p-2 hover:bg-gray-200"
                    onClick={() => this.props.onChange(null)}
                >
                    X
                </Button>
            </div>
        } else {
            return <div className="relative">
                <Input
                    value={this.state.query}
                    onChange={this.handleInputQuery}
                    placeholder="Escribe: Nombre"
                />
                <div className="mt-1 absolute w-full z-10 bg-white rounded shadow overflow-hidden">
                    { this.state.results.map(manual => <button
                        type="button"
                        key={manual.id}
                        className="mtsp-px text-left text-xs hover:bg-gray-200 w-full px-2 px-1"
                        onClick={e => this.handleManualClick(e, manual.versions[manual.versions.length-1].id)}
                    >
                        { manual.name }
                    </button>) }
                </div>
            </div>
        }
    }

    handleInputQuery = value => {
        this.setState({
            query: value
        })
        this.refreshResults()
    }

    async refreshResults() {
        let { query } = this.state
        query = query.trim()
        if (query.length < 3) {
            this.setState({
                results: []
            })
        } else {
            const response = await axios.get(`/manuals?name=${query}&type=manufacture`)
            this.setState({
                results: response.data
            })
        }
    }

    handleManualClick = (event, manualVersionId) => {
        event.preventDefault()

        this.props.onChange(manualVersionId)
    }
}
