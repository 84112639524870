import React from "react"
import cn from "classnames"

export default function ProgressBar({
    onClick,
    segments, // ["ok", "warn", "error", "enabled", "disabled"]
    currentSegmentIdx, // Number
    children=[], // [fn?
}) {
    const handleClick = (idx) => {
        if (onClick) onClick(idx)
    }

    const segmentClass = (s, idx) => {
        const current = idx == currentSegmentIdx
        const last = segments.length - 1 == idx

        const tall = !!onClick

        return cn("flex-1 flex items-center justify-center", {
            "border-r": !last,

            "h-1": !tall,
            "h-2": tall,

            "cursor-pointer": !!onClick,

            // Enabled
            "hover:bg-gray-500":    s == "enabled" && tall,
            "bg-gray-700":          s == "enabled" && !current,
            "bg-gray-500":          s == "enabled" && current,

            // OK
            "hover:bg-green-500":   s == "ok" && tall,
            "bg-green-700":         s == "ok" && !current,
            "bg-green-500":         s == "ok" && current,

            // Warn
            "hover:bg-yellow-500":  s == "warn" && tall,
            "bg-yellow-700":        s == "warn" && !current,
            "bg-yellow-500":        s == "warn" && current,

            // Error
            "hover:bg-red-500":     s == "error" && tall,
            "bg-red-700":           s == "error" && !current,
            "bg-red-500":           s == "error" && current,
        })
    }

    if (!Array.isArray(children)) {
        children = [children]
    }

    return <div className="flex bg-white border-t border-b">
        { segments.map((s, idx) => <div
            key={idx}
            className={segmentClass(s, idx)}
            onClick={() => handleClick(idx)}
        >
            { children.map(child => child(s, idx)) }
        </div>) }
    </div>
}
