import React from "react"
import { connect } from "react-redux"
import cn from "classnames"
import { Link } from "react-router-dom"

import {
    IoMdRemoveCircleOutline,
} from "react-icons/io"

import {
    MdDragHandle
} from "react-icons/md"

import {
    FaEdit,
    FaPeace,
} from "react-icons/fa"

import TotalCosts from "~/components/TotalCosts"

const mapStateToProps = state => ({
    currentUser: state.app.users[state.app.currentUserId],
})

/* eslint-disable react/display-name  */
const StockDescendantItem = connect(
    mapStateToProps, undefined, undefined, { forwardRef: true }
)(React.forwardRef((props, ref) => {
    const {
        totalCosts,
        item,
        isBeingDragged,
        currentUser,
    } = props

    const thisItemTotalCosts = {}
    for (let key in totalCosts[item.id]) {
        thisItemTotalCosts[key] = totalCosts[item.id][key] * item.relation.amount
    }

    return <tr
        className={cn("-mtsp-px border-t border-b border-gray-400", {
            "opacity-50 bg-gray-200": isBeingDragged,
            "bg-red-200": item.amount < item.relation.amount
        })}
        ref={ref}
    >
        <td
            className="px-2 self-center cursor-move hover:bg-gray-200 mr-2"
            onMouseDown={props.onMouseDown}
        >
            <MdDragHandle />
        </td>
        <td className="px-2 text-center text.sm leading.snug">
            {item.relation?.amount }
        </td>
        <td className="px-2 text-sm leading-snug">
            { item.mpn }
        </td>
        <td className="px-2 text-sm leading-snug">
            { item.pn }
        </td>
        <td className={`flex items-center flex-grow text-sm leading-snug relative`}>
            { item.ignore && <FaPeace
                className="mr-1"
                title="Ignorar para producción"
            /> }
            <Link
                className="font-semibold"
                to={`/stock/${item.id}`}
            >
                { item.name }
            </Link>
        </td>
        <td className="px-2 text-center text-sm leading-snug">
            { item.locator }
        </td>
        <td className="px-2 text-center text-sm leading-snug">
            {item.relation?.notes }
        </td>
        { currentUser.admin && <td className="px-2 text-center text-sm leading-snug">
            <TotalCosts totalCosts={thisItemTotalCosts} />
        </td> }
        <td className="px-2 text-center text-sm leading-snug">
            { item.amount }
        </td>
        <td className="px-2 text-center text-sm leading-snug relative">
            { currentUser.admin && <span className="absolute right-0 flex items-center inset-y-0">
                <button
                    className="mlsp-2"
                    onClick={props.onEditDescendant}
                    title="Editar"
                >
                    <FaEdit />
                </button>
                <button
                    className="mlsp-2"
                    onClick={props.onRemoveDescendant}
                    title="Eliminar"
                >
                    <IoMdRemoveCircleOutline />
                </button>
            </span> }
        </td>
    </tr>
}))

export default StockDescendantItem
