import React from "react"
import axios from "axios"
import produce from "immer"
import cn from "classnames"
import { DateTime } from "luxon"

import {
    FaSpinner,
    FaFileCsv,
    FaInfoCircle,
    FaPeace,
} from "react-icons/fa"

import {
    AiOutlineSwap
} from "react-icons/ai"

import {
    IoIosArrowDropright,
    IoIosArrowDropdownCircle,
} from "react-icons/io"

import {
    doDownload,
} from "~/lib/utils"

import Button from "~/components/Button"
import StockReportPnModal from "~/components/StockReportPnModal"

const cookRows = (rows, openProducts) => {
    const out = []
    for (let row of rows) {
        out.push({
            ignore:             row.ignore,
            stockItemId:        row.stockItemId,
            key:                row.pn,
            type:               "product",
            pn:                 row.pn,
            name:               row.name,
            amount:             0,
            canBuild:           row.canBuild,
            ready:              row.ready,
            building:           row.building,
            available:          /^TA-FLV-/.test(row.pn) ? row.stockAmount : row.available, // TODO watch out
            reserved:           row.reserved,
            sent:               row.sent,
            total:              row.total,
            reclaims:           row.reclaims,
        })

        if (openProducts[row.pn]) {
            for (let child of (row.children || [])) {
                out.push({
                    ignore:             child.ignore,
                    stockItemId:        child.stockItemId,
                    key:                `${row.pn}${child.pn}`,
                    type:               "module",
                    pn:                 child.pn,
                    name:               child.name,
                    amount:             child.amount,
                    canBuild:           child.canBuild,
                    ready:              child.ready,
                    building:           child.building,
                    available:          /^TA-FLV-/.test(child.pn) ? child.stockAmount : child.available, // TODO watch out
                    reserved:           child.reserved,
                    sent:               child.sent,
                    total:              child.total,
                    reclaims:           "-",
                })
            }
        }
    }
    return out
}

export default class StockReport extends React.Component {
    constructor() {
        super()

        this.state = {
            stockReport: [],
            openProducts: {},
            loading: true,
            greedy: false,
            showMonitoredPnsModal: false,
        }
    }

    async componentDidMount() {
        await this.refresh()
    }

    async refresh() {
        this.setState({ loading: true })
        const { greedy } = this.state
        try {
            const response = await axios.get(`/stats/stock-report?greedy=${greedy}`)
            this.setState({ stockReport: response.data })
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return <div className={cn("p-2", this.props.className)}>
                <h2 className="text-2xl font-extralight">
                    Informe de Stock
                </h2>
                <div className="flex justify-center py-4">
                    <FaSpinner
                        className="spin text-4xl text-gray-600"
                    />
                </div>
            </div>
        }

        return <div className={cn("p-2 overflow-x-scroll", this.props.className)}>
            <div className="flex items-start">
                <h2 className="text-2xl font-extralight">
                    Informe de Stock
                </h2>

                <span className="ml-auto flex items-center">
                    <label className="mlsp-4">
                        <input
                            type="checkbox"
                            checked={this.state.greedy}
                            onChange={this.handleGreedyChange}
                            className="mr-1"
                        />
                        Greedy
                    </label>
                    <Button
                        className="mlsp-4"
                        onClick={this.handleShowMonitoredPns}
                        theme="outline"
                    >
                        <AiOutlineSwap className="mr-1" />
                        PNs
                    </Button>
                    <Button
                        className="mlsp-4"
                        onClick={this.handleDownloadClick}
                    >
                        <FaFileCsv className="mr-1" />
                        Descargar
                    </Button>
                </span>
            </div>

            <div className="text-xs text-gray-700">
                <FaInfoCircle className="text-xs inline mr-1" />
                Toda esta información se obtiene a partir de las órdenes de fabricación,
                <strong>excepto para los PN TA-FLV-XXX-YYY</strong>,
                que se obtiene del stock, que a su vez proviene del stock de Holded.
            </div>

            <table className="w-full border-collapse mt-2">
                <thead className="text-sm">
                    <tr>
                        <th
                            rowSpan="2"
                            className="border border-gray-600 px-1"
                        >
                            Productos y módulos
                        </th>
                        <th
                            rowSpan="2"
                            className="border border-gray-600 px-1"
                        >
                            Compuesto por
                        </th>
                        <th
                            colSpan="3"
                            className="border border-gray-600 px-1"
                        >
                            Planificación
                        </th>
                        <th
                            colSpan="4"
                            className="border border-gray-600 px-1"
                        >
                            Fabricados
                        </th>
                        <th
                            rowSpan="2"
                            className="border border-gray-600 px-1"
                        >
                            Devoluciones
                        </th>
                    </tr>
                    <tr>
                        <th className="border border-gray-600 align-bottom px-1">
                            <div className="flex">
                                Capacidad productiva
                                <FaInfoCircle
                                    className="text-xs flex-shrink-0"
                                    title="Cuántas unidades pueden producirse sin tener que fabricar subproductos intermedios."
                                />
                            </div>
                        </th>
                        <th className="border border-gray-600 align-bottom px-1">
                            <div className="flex">
                                Planificados
                            </div>
                        </th>
                        <th className="border border-gray-600 align-bottom px-1">
                            <div className="flex">
                                En fabricación
                            </div>
                        </th>
                        <th className="border border-gray-600 px-1">
                            <div className="flex">
                                Para stock
                                <FaInfoCircle
                                    className="text-xs flex-shrink-0"
                                    title="Productos: Montajes finalizados sin referencia ERP y no enviados. Módulos: Cantidad disponible en stock."
                                />
                            </div>
                        </th>
                        <th className="border border-gray-600 px-1">
                            <div className="flex">
                                Para pedidos
                                <FaInfoCircle
                                    className="text-xs flex-shrink-0"
                                    title="Productos: Montajes finalizados con referencia ERP y no enviados."
                                />
                            </div>
                        </th>
                        <th className="border border-gray-600 px-1">
                            <div className="flex">
                                Entregados
                            </div>
                        </th>
                        <th className="border border-gray-600 px-1">
                            <div className="flex">
                                TOTAL
                                <FaInfoCircle
                                    className="text-xs flex-shrink-0"
                                    title="Productos: Montajes finalizados."
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { cookRows(this.state.stockReport, this.state.openProducts).map(row => <tr
                        key={row.key}
                        className="odd:bg-gray-200"
                    >
                        <td className="border border-gray-600 px-1">
                            <div className="flex">
                                { row.type == "product" && <Button
                                    className="self-center mlsp-4"
                                    style="compact"
                                    theme="transparent"
                                    onClick={() => this.handleRowClick(row)}
                                >
                                    { !this.state.openProducts[row.pn] && <IoIosArrowDropright /> }
                                    { this.state.openProducts[row.pn] && <IoIosArrowDropdownCircle /> }
                                </Button> }
                                <div className={cn("mlsp-4 flex-grow", {
                                    "text-left": row.type == "product",
                                    "text-right": row.type == "module",
                                })}>
                                    <div className="text-sm font-semibold flex justify-end items-center">
                                        { row.ignore && <FaPeace
                                            title="Ignorar para producción"
                                            className="mr-1"
                                        /> }
                                        { row.name }
                                    </div>
                                    <div className="text-xs">
                                        { row.stockItemId ? <a
                                            href={`/stock/${row.stockItemId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            { row.pn }
                                        </a> : row.pn }
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.amount }
                        </td>
                        <td
                            className={cn("border border-gray-600 text-center px-1", {
                                "bg-red-200": row.canBuild == 0
                            })}
                        >
                            { row.canBuild }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.ready }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.building }
                        </td>
                        <td
                            className={cn("border border-gray-600 text-center px-1", {
                                "bg-red-200": row.available <= 0
                            })}
                        >
                            { row.available }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.reserved }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.sent }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.total }
                        </td>
                        <td className="border border-gray-600 text-center px-1">
                            { row.reclaims }
                        </td>
                    </tr>) }
                </tbody>
            </table>

            { this.state.showMonitoredPnsModal && <StockReportPnModal
                onRequestClose={this.handleCloseMonitoredPnsModal}
                onChange={this.handleReorder}
            /> }
        </div>
    }

    handleShowMonitoredPns = () => {
        this.setState({
            showMonitoredPnsModal: true
        })
    }

    handleCloseMonitoredPnsModal = () => {
        this.setState({
            showMonitoredPnsModal: false
        })
    }

    handleGreedyChange = event => {
        this.setState({
            greedy: event.target.checked
        }, () => {
            this.refresh()
        })
    }

    handleRowClick = (row) => {
        if (row.type == "product") {
            this.setState(produce(draft => {
                draft.openProducts[row.pn] = !draft.openProducts[row.pn]
            }))
        }
    }

    handleDownloadClick = event => {
        event.preventDefault()

        const { stockReport } = this.state
        const openProducts = {}
        for (let row of stockReport) {
            openProducts[row.pn] = true
        }

        const csv = [
            `PN, Name, Compuesto por, Capacidad productiva, Planificados, En fabricación, Para stock, Para pedidos, Entregados, TOTAL, Devoluciones`
        ].concat(cookRows(stockReport, openProducts).map(r =>
            `${r.pn}, ${r.name.replaceAll(",", "")}, ${r.amount}, ${r.canBuild}, ${r.ready}, ${r.building}, ${r.available}, ${r.reserved}, ${r.sent}, ${r.total}, ${r.reclaims}`
        )).join("\n")

        const timestamp = DateTime.local().toFormat("yyyy_LL_dd-HH_mm_ss")
        doDownload(new Blob([csv], { type: "text/csv" }),`stock_report-${timestamp}.csv`)
    }
}
