import React from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import thunkMiddleware from "redux-thunk"
import { createStore, applyMiddleware } from "redux"
import { createBrowserHistory } from "history"
import createDebounce from "redux-debounced"
import { routerMiddleware, push } from "connected-react-router"
import Modal from "react-modal"

import {
    createRootReducer,
    comeBack,
    startTicking,
    notify,
} from "~/store"
import App from "~/App"
import "~/main.css"
import "~/Calendar.css" // TODO Don't like it, but importing it from react-modal/dist/Calendar.css doesn't work in production
import "react-vis/dist/style.css"

// Configure axios
/* global API_BASE_URL */
axios.defaults.baseURL = API_BASE_URL
axios.defaults.withCredentials = true

// Configurar react-modal
Modal.setAppElement("#root")
Modal.defaultStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(200, 200, 200, 0.75)"
    },
    content: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        outline: "none",
    }
}

// Create Redux store
const history = createBrowserHistory()
const store = createStore(
	createRootReducer(history),
    applyMiddleware(
        routerMiddleware(history),
        createDebounce(),
        thunkMiddleware
    )
)

// Make all request that fail with a 5XX error code display the included error message.
// Make all 403 redirect to login
axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status.toString()[0] == "5") {
        store.dispatch(notify("error", error.message))
    } else if (error.response.status == 403) {
        store.dispatch(push("/login"))
    }

    return Promise.reject(error)
})

// Instantiate App and render it.
;(async () => {
    await store.dispatch(comeBack())
    store.dispatch(startTicking())
    ReactDOM.render(
        <App
            store={store}
            history={history}
        />,
        document.getElementById("root")
    )
})()
.catch(error => {
    console.log(error)
})
