import React, { useState } from "react"
import cn from "classnames"
import { DateTime } from "luxon"
import { usePopper } from "react-popper"

import {
    FaRecycle,
    FaShippingFast,
    FaEllipsisV,
} from "react-icons/fa"

import {
    useOnMousedownOutside
} from "~/lib/utils"

import Button from "~/components/Button"

export default function OrderMediaItem({
    className,
    order,

    onClickResendStartEmail,
    onClickResendDoneEmail,
    onClickResendShippingEmail,
}) {
    const [showPopUp, setShowPopUp] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    useOnMousedownOutside(popperElement, () => setShowPopUp(false))
    const { styles, attributes } = usePopper(referenceElement, popperElement)

    return <div className={cn("flex p-1", className)}>
        <div>
            <div>
                <span className="text-sm">
                    { order.clientName }
                </span>
                { order.erpReference && <span className="text-xs ml-1">
                    ({ order.erpReference })
                </span> }
            </div>
            <div className="text-xs text-gray-700">
                { order.clientEmail }
            </div>
        </div>
        <div className="ml-2">
            <div className="text-sm">
                { order.trackingNumber }
            </div>
            <div className="text-xs text-gray-700">
                { order.courier }
            </div>
        </div>
        { order.shippingAt && <div
            className="ml-2 text-sm flex items-center"
            title="Fecha de envío"
        >
            <FaShippingFast className="mr-1"/>
            { DateTime.fromISO(order.shippingAt).toLocaleString(DateTime.DATETIME_SHORT) }
        </div> }
        { order.reclaimedAt && <div className="ml-2 text-sm">
            <div className="text-sm flex items-center">
                <FaRecycle className="mr-1" />
                { DateTime.fromISO(order.reclaimedAt).toLocaleString(DateTime.DATETIME_SHORT) }
            </div>
            <div
                className="truncate text-xs text-gray-700"
                title={order.reclaimReason}
            >
                { order.reclaimReason }
            </div>
        </div> }

        <Button
            className="ml-auto self-center"
            theme="transparent"
            style="compact"
            onClick={() => setShowPopUp(true)}
        >
            <span ref={setReferenceElement} />
            <FaEllipsisV />
        </Button>

        { showPopUp && <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="bg-white rounded shadow flex flex-col overflow-hidden text-gray-700"
        >
            <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onClickResendStartEmail}
            >
                Reenviar email inicio
            </button>
            <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onClickResendDoneEmail}
            >
                Reenviar email finalización
            </button>
            <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onClickResendShippingEmail}
            >
                Reenviar email envío
            </button>
        </div>}
    </div>
}
