import React from "react"
import cn from "classnames"

import {
    FaUserCog,
    FaVial,
    FaBoxes,
} from "react-icons/fa"

export default function StockItemTypeIcon({ className, type }) {
    switch (type) {
        case "product":
            return <FaBoxes
                title="Componente"
                className={cn("text-blue-500", className)}
            />

        case "consumable":
            return <FaVial
                title="Consumible"
                className={cn("text-green-500", className)}
            />

        case "labour":
            return <FaUserCog
                title="Mano de obra"
                className={cn("text-red-500", className)}
            />

        default:
            throw new Error(`Unknown stock item type: ${type}`)
    }
}
