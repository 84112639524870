import React, { useState } from "react"
import axios from "axios"

import {
    FaRegTrashAlt
} from "react-icons/fa"

import InputFile from "~/components/InputFile"
import Button from "~/components/Button"
import ImageModal from "~/components/ImageModal"

export default function InputImage({
    value,
    className,
    canDelete,
    onUploading,
    onDone,
    onChange,
    target="/images"
}) {
    const [status, setStatus] = useState("ready") // uploading | ready
    const [showModal, setShowModal] = useState(false)

    const handleDeleteClick = event => {
        event.preventDefault()
        onChange("")
    }

    const handleChange = files => {
        (async () => {
            setStatus("uploading")
            if (onUploading) onUploading()
            for (let file of files) {
                const form = new FormData()
                form.append("image", file)
                const response = await axios.post(target, form)
                const url = response.data
                onChange(url)
            }
            setStatus("ready")
            if (onDone) onDone()
        })()
    }

    if (value) {
        return <div className={"relative " + className}>
            <img
                className="cursor-pointer w-full h-auto"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
                src={value}
                onClick={() => setShowModal(true)}
            />
            { canDelete && <Button
                className="absolute left-0 top-0 m-2"
                style="compact"
                onClick={handleDeleteClick}
            >
                <FaRegTrashAlt />
            </Button> }

            <ImageModal
                isOpen={showModal}
                url={value}
                onRequestClose={() => setShowModal(false)}
            />
        </div>
    } else {
        if (status == "ready") {
            return <InputFile
                className={className}
                accept="image/*"
                onChange={handleChange}
                multiple
            />
        } else {
            return "Redimensionando..."
        }
    }
}
