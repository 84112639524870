import React from "react"
import axios from "axios"

import LogEntry from "~/components/LogEntry"

const values2level = {
    "60": "fatal",
    "50": "error",
    "40": "warn",
    "30": "info",
    "20": "debug",
    "10": "trace",
}

export default class LogPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entries: []
        }
    }

    async componentDidMount() {
        const { file } = this.props
        const response = await axios.get(`/logs/${file}`)
        this.setState({
            entries: response.data
        })
    }

    render() {
        const { entries } = this.state
        const { levels } = this.props

        const filteredEntries = entries.filter(e => levels[values2level[e.level]])

        return <div className={this.props.className}>
            { filteredEntries.map((entry, idx) => <LogEntry
                key={idx}
                entry={entry}
            />) }
        </div>
    }
}
