import React from "react"
import isNil from "lodash/isNil"
import urlJoin from "url-join"

import {
    FaCheck,
    FaFilePdf,
    FaSpinner,
    FaPencilAlt,
} from "react-icons/fa"

import Button from "~/components/Button"
import Input from "~/components/Input"
import PdfViewer from "~/components/PdfViewer"

/* global API_BASE_URL */
export default class StepInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value:      props.answer?.value || "",
            thinking:   false,
            editing:    false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.answer?.id != prevProps.answer?.id) {
            this.setState({
                value:      this.props.answer?.value || "",
                thinking:   false,
                editing:    false,
            })
        }
    }

    render() {
        const {
            blocked,
            tooSoon,
            step,
            headless,
        } = this.props
        const {
            value,
            thinking,
            editing,
        } = this.state

        return <>
            <div className="relative">
                { step.manualVersionId && <PdfViewer
                    fit={headless ? "height" : "width"}
                    url={urlJoin(API_BASE_URL, "manuals", step.manualVersionId, "file")}
                    pageNumber={step.pageNumber}
                /> }
                { !step.manualVersionId && <div className="mt-20 text-gray-700 text-lg font-extralight flex items-center justify-center">
                    <FaFilePdf />
                    <span className="ml-2">
                        This step has no manual.
                    </span>
                </div> }
            </div>

            <div className="fixed bottom-0 inset-x-0">
                <form
                    className="flex"
                    onSubmit={editing ? this.handleEditSubmit : this.handleFormSubmit}
                >
                    <div className="p-1 bg-gray-200 self-end flex items-center rounded-tr overflow-hidden flex-1">
                        <Input
                            className="mlsp-2"
                            placeholder={step.name}
                            disabled={(blocked && !editing) || tooSoon}
                            value={value}
                            type={(step.min || step.max) ? "number" : "text"}
                            onChange={this.handleInput}
                        />
                        { step.unit && <span className="mlsp-2">
                            { step.unit }
                        </span> }
                        { (!isNil(step.min) || !isNil(step.max)) && <div className="mlsp-2 text-xs text-gray-700 flex flex-col">
                            { !isNil(step.min) && <span className="whitespace-no-wrap">
                                Mín: { step.min } { step.unit }
                                </span> }
                            { !isNil(step.max) && <span className="whitespace-no-wrap">
                                Máx: { step.max } { step.unit }
                            </span> }
                        </div> }
                    </div>
                    <div className="ml-2 py-2 pr-2">
                        { (this.props.answer && !editing) && <Button
                            title="Editar"
                            theme="warn"
                            style="small-circle"
                            type="button"
                            onClick={this.handleEditClick}
                        >
                            <FaPencilAlt className="transform scale-50" />
                        </Button> }
                        { editing && <Button
                            title="Cancelar edición"
                            style="small-circle"
                            type="button"
                            theme="danger"
                            onClick={this.handleCancelEditClick}
                        >
                            <FaPencilAlt className="transform scale-50" />
                        </Button> }
                        <Button
                            title="Confirmar"
                            style="big-circle"
                            disabled={(blocked && !editing) || tooSoon || thinking}
                        >
                            { thinking ? <FaSpinner className="spin" /> : <FaCheck /> }
                        </Button>
                    </div>
                </form>
            </div>
        </>
    }

    handleCancelEditClick = e => {
        e.preventDefault()

        this.setState((_, props) => ({
            editing: false,
            value: props.answer?.value,
        }))
    }

    handleEditClick = e => {
        e.preventDefault()

        this.setState({ editing: true })
    }

    handleEditSubmit = async e => {
        e.preventDefault()

        this.setState({ thinking: true })
        try {
            await this.props.onEdit(this.state.value)
        } finally {
            this.setState({
                thinking: false,
                editing: false,
            })
        }
    }

    handleFormSubmit = async event => {
        event.preventDefault()

        this.props.onInteraction()

        this.setState({ thinking: true })
        try {
            await this.props.onDone(this.state.value)
        } finally {
            this.setState({ thinking: false })
        }
    }

    handleInput = value => {
        this.setState({ value })
    }
}
