import { DateTime, Duration } from "luxon"
import isArray from "lodash/isArray"
import sortBy from "lodash/sortBy"
import mergeWith from "lodash/mergeWith"
import flatten from "lodash/flatten"
import { useEffect } from "react"

// Removes the item at position `from` at inserts it after `to`.
export function reorderArray(array, from, to) {
    if (from <= to) to -= 1
    const b = array.splice(from, 1)[0]
    array.splice(to+1, 0, b)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const clamp = (a, b, t) => Math.max(a, Math.min(t, b))

export function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea")

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed"
    textArea.style.top = 0
    textArea.style.left = 0

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em"
    textArea.style.height = "2em"

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0

    // Clean up any borders.
    textArea.style.border = "none"
    textArea.style.outline = "none"
    textArea.style.boxShadow = "none"

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent"

    textArea.value = text

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    document.execCommand("copy")

    document.body.removeChild(textArea)
}

export const fmtseconds = seconds => Duration.fromObject({ seconds }).toFormat("mm'm' ss's'")
export const min2hhmm   = minutes => Duration.fromObject({ minutes }).toFormat("hh:mm")

export const url2name = url => {
    const segments = url.split("/")
    return decodeURIComponent(segments[segments.length - 1])
}

export function times(n, cb) {
    const values = []
    for (let i = 0; i < n; i++) {
        values.push(cb(i))
    }
    return values
}

export function obj2qs(obj) {
    return Object.entries(obj).map(([k, v]) => `${k}=${v}`).join("&")
}

export const fmtdaterange = (range) => {
    if (isArray(range)) {
        let [start, end] = range
        start = DateTime.fromJSDate(start)
        end =  DateTime.fromJSDate(end)
        return `${start.toFormat("yyyy LLL dd")} - ${end.toFormat("yyyy LLL dd")}`
    } else if (range === true) {
        return "ANY"
    } else if (range === null) {
        return "NOT"
    } else {
        return "-"
    }
}

export const cookDateRange = filter => {
    if (isArray(filter)) {
        const start = DateTime.fromJSDate(filter[0]).toUTC().toISO()
        const end   = DateTime.fromJSDate(filter[1]).toUTC().toISO()
        return `${start},${end}`
    } else if (filter === true) {
        return "true"
    } else if (filter === null) {
        return "null"
    } else {
        throw new Error(`Unknown value ${filter}`)
    }
}

/**
 * File -> "data:[<mediatype>][;base64],<data>"
 */
export function readAsDataURL(file) {
    return new Promise((resolve) => {
        const reader = new window.FileReader()
        reader.addEventListener("load", () => {
            resolve(reader.result)
        })
        reader.readAsDataURL(file)
    })
}

export function humanFileSize(bytes, si=false) {
    var thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

// Returns both the adjacency list and the inverse adjacency list
//
// edges: [StockEdge]
//
// returns: { itemId => { itemId => [StockEdge] } }
export function getAdjacencyLists(edges) {
    const adj  = {} // Adjacency list
    const iadj = {} // Inverse adjacency list

    for (let edge of edges) {
        const { from, to } = edge
        if (!(from in adj)) adj[from] = {}
        if (!(to in adj[from])) adj[from][to] = []
        adj[from][to].push(edge)

        if (!(to in iadj)) iadj[to] = {}
        if (!(from in iadj[to])) iadj[to][from] = []
        iadj[to][from].push(edge)
    }

    return { adj, iadj }
}

// Sources = Vertices with no incoming edges
//
// vertices: [StockItem]
// iadj: { id => { id => Number } }
//
// return: [StockItem]
export function getSourceVertices(vertices, iadj) {
    return vertices.filter(v => !(v.id in iadj))
}

// Graph Depth-First Search
//
// adj: { itemId => { itemId => [StockEdge] } }
// node: String
//
// returns: StockItem
export function treemap(adj, node, cb, depth=0, incoming=null) {
    const t = cb(node, depth, incoming)
    if (node in adj) {
        let edges = flatten(Object.values(adj[node] || {}))
        edges = sortBy(edges, ({ order }) => order)

        t.children = edges.map(edge => {
            return treemap(adj,
                node == edge.to ? edge.from : edge.to,  // Ugly hack to allow traversing iadj
                cb,
                depth+1,
                edge)
        })
    }
    return t
}

// Tree Depth-First Search
//
// Stops if `cb` returns truthy.
export function traverse(node, cb) {
    if (cb(node)) return
    for (let kid of (node.children || [])) {
        if (traverse(kid, cb)) return
    }
}

// items: { id => StockItem }
// sources: [StockItem]
// adj: { itemId => { itemId => [Edge] } }
//
// returns: { id => { [type] => Number } }
export function getTotalCostsByType(items, sources, adj) {
    const totalCosts = {}

    // [totalCosts, items]
    const traverse = (n) => {
        if (n in totalCosts) return totalCosts[n]
        const item = items[n]
        const edges = flatten(Object.values(adj[n] || {}))
        if (edges.length > 0) {
            const childrenCost = edges.reduce((acc, cur) => {
                return mergeWith(acc, traverse(cur.to), (a=0, b=0) => a + cur.amount * b)
            }, {})
            totalCosts[n] = childrenCost
        } else {
            const selfCost = { [item.type]: item.cost }
            totalCosts[n] = selfCost
        }
        return totalCosts[n]
    }
    for (let src of sources) {
        traverse(src.id)
    }

    return totalCosts
}

// TODO
// item: StockItem
// items: { id => StockItem }
// adj: { id => { id => Boolean } }
//
// returns: StockItem
export function getDescendants(item, items, adj) {
    return treemap(adj, item.id, (n, _depth, incoming) => {
        const item = items[n]
        const node = { ...item }
        if (incoming) node.relation = incoming
        return node
    })
}

// TODO
// item: StockItem
// items: { id => StockItem }
// iadj: { id => { id => Boolean } }
//
// returns: StockItem
export function getAncestors(item, items, iadj) {
    return treemap(iadj, item.id, (n, _depth, incoming) => {
        const item = items[n]
        const node = { ...item }
        if (incoming) node.relation = incoming
        return node
    })
}

export function country2emoji(country) {
    switch (country) {
        case "eu": return "🇪🇺"
        case "uk": return "🇬🇧"
        case "usa": return "🇺🇸"
        case "es": return "🇪🇸"
        case "china": return "🇨🇳"
        case "japan": return "🇯🇵"
        case "south_korea": return "🇰🇷"
        case "latam": return "🇲🇽"
        default: return ""
    }
}

// Cálculo del dígito de control EAN
export function ean13_checksum(message) {
    let checksum = 0
    message = message.split('').reverse()
    for (let pos in message) {
        checksum += message[pos] * (3 - 2 * (pos % 2))
    }
    return ((10 - (checksum % 10 )) % 10)
}

export function useOnMousedownOutside(ref, cb) {
    useEffect(() => {
        if (!ref) return

        const handleClickOutside = (event) => {
            if (ref && !ref.contains(event.target)) {
                cb()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, cb])
}

export function fmtpriority(p) {
        switch (p) {
            case 3:  return "Alta"
            case 2:  return "Media"
            case 1:  return "Baja"
            default: return "?"
        }
}

export function average(xs) {
    return xs.reduce((acc, cur) => acc + cur, 0) / xs.length
}

export function doDownload(blob, name) {
    const a = window.document.createElement("a")
    a.href = window.URL.createObjectURL(blob)
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}
