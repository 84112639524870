import React from "react"
import { connect } from "react-redux"
import deburr from "lodash/deburr"

import {
    FaPencilAlt
} from "react-icons/fa"

import Input from "~/components/Input"
import Button from "~/components/Button"

const mapStateToProps = state => ({
    items: state.app.stock.items
})

function queryItems(items, query) {
    query = deburr(query.trim()).toLowerCase()

    return query.length > 2 ? items.filter(item => {
        const name = deburr(item.name).toLowerCase()
        if (name.includes(query)) return true

        if (item.pn.toLowerCase().includes(query)) return true
        if (item.mpn.toLowerCase().includes(query)) return true

        return false
    }).slice(0, 15) : []
}

export default connect(mapStateToProps)(class StockItemSelector extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            query: "",
        }
    }

    getSelectedItem() {
        const {
            items,
            value
        } = this.props
        return value ? items[value] : null
    }

    render() {
        const selectedItem = this.getSelectedItem()
        const filteredItems = queryItems(Object.values(this.props.items), this.state.query)

        return selectedItem ? <>
            <div className="flex items-center">
                <div className="text-xs">
                    <div className="font-semibold">
                        { selectedItem.name }
                    </div>
                    <div>
                        { selectedItem.pn }
                    </div>
                </div>
                <Button
                    type="button"
                    className="ml-2"
                    style="compact"
                    theme="transparent"
                    onClick={this.handleRemoveClick}
                >
                    <FaPencilAlt />
                </Button>
            </div>
        </> : <div className="relative">
            <Input
                value={this.state.query}
                onChange={this.handleInput("query")}
                placeholder="Escribe: Nombre, PN o MPN"
            />

            <div className="mt-1 absolute bg-white rounded shadow w-full z-10">
                { filteredItems.map(item => <button
                    className="mtsp-px text-xs flex flex-col items-start hover:bg-gray-200 w-full px-2 px-1"
                    onClick={(e) => this.handleItemClick(e, item)}
                    key={item.id}
                >
                    <div className="font-semibold">
                        { item.name }
                    </div>
                    <div className="text-gray-700">
                        { item.pn }
                    </div>
                </button>) }
                { (this.state.query.length > 2 && filteredItems.length == 0) && <div
                    className="text-gray-700 text-xs px-4 py-2"
                >
                    No hay resultados.
                </div> }
            </div>
        </div>
    }

    handleInput = (prop) => {
        return value => {
            this.setState({ [prop]: value })
        }
    }

    handleRemoveClick = () => {
        this.props.onChange(null)
    }

    handleItemClick = async (event, item) => {
        event.preventDefault()

        this.props.onChange(item.id)
    }
})
