import React from "react"
import ReactModal from "react-modal"
import cloneDeep from "lodash/cloneDeep"
import produce from "immer"
import set from "lodash/set"
import isNil from "lodash/isNil"
import isEqual from "lodash/isEqual"

import {
    FaArrowLeft,
} from "react-icons/fa"

import Button from "~/components/Button"
import Select from "~/components/Select"
import Input from "~/components/Input"
import InputNumber from "~/components/InputNumber"
import TextArea from "~/components/TextArea"
import ManualSelector from "~/components/ManualSelector"

const items2csv = (trayItems, tubeItems) => trayItems
    .map(({ amount, locator }) => `${amount} ${locator} Bandeja`)
    .concat(tubeItems
        .map(({ amount, locator, perBucket }) => `${amount} ${locator} ${perBucket}`))
    .join("\n")

const csv2items = str => {
    const lines = str.split(/\n+/)

    const trayItems = []
    const tubeItems = []

    for (let line of lines) {
        let [amount, locator, perBucket] = line.split(/\s+/)
        amount = parseInt(amount)
        perBucket = parseInt(perBucket)

        if (isNaN(perBucket)) {
            trayItems.push({ amount, locator })
        } else {
            tubeItems.push({ amount, locator, perBucket })
        }
    }

    return { trayItems, tubeItems }
}

export default class EditStepModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialStep = Object.assign({
            name: "",
            ref: "",
            type: "check",
            manualVersionId: undefined,
            pageNumber: undefined,
            parTime: undefined,
            perInstance: false,

            // Input
            min: undefined,
            max: undefined,
            unit: undefined,
            regex: undefined,
            critical: false,

            // Supply
            trayItems: [],
            tubeItems: [],
        }, props.step)

        this.state = {
            stepTypes: ["check", "input", "multi-input", "json", "picture", "supply", "autosupply"],
            step: cloneDeep(this.initialStep),
            csv: "",
        }

        if (this.initialStep.type == "supply") {
            this.state.csv = items2csv(this.initialStep.trayItems, this.initialStep.tubeItems)
        }
    }

    handleRequestClose = () => {
        if (!isEqual(this.initialStep, this.state.step)) {
            const go = window.confirm("Este paso tiene cambios sin guardar. ¿Seguro que deseas salir?")
            if (!go) return
        }

        this.props.onRequestClose()
    }

    render() {
        const {
            step,
            stepTypes,
            csv,
        } = this.state

        const {
            descriptionName,
            blockName,
        } = this.props

        return <ReactModal
            className="mx-auto max-w-3xl pt-8 px-4 pb-4 relative bg-white rounded overflow-hidden outline-none"
            isOpen
            onRequestClose={this.handleRequestClose}
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                type="button"
                theme="transparent"
                onClick={this.handleRequestClose}
            >
                <FaArrowLeft />
            </Button>

            <form onSubmit={this.handleSubmit}>

                <h1 className="font-semibold text-xl">
                    { descriptionName } &gt; { blockName }
                </h1>

                <hr className="my-2" />

                <div className="flex">
                    <div className="w-1/2 mlsp-2">
                        <div>
                            <label className="text-xs uppercase">
                                Nombre del paso
                            </label>
                            <Input
                                value={step.name || ""}
                                onChange={v => this.handleInput("step.name", v)}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Tipo
                            </label>
                            <Select
                                disabled
                                value={step.type}
                            >
                                { stepTypes.map(type => <option
                                    key={type}
                                    value={type}
                                >
                                    { type }
                                </option>) }
                            </Select>
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Por instancia
                            </label>
                            <input
                                type="checkbox"
                                className="mr-2 mt-6"
                                checked={step.perInstance}
                                onChange={e => this.handleInput("step.perInstance", e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 mlsp-2">
                        <div>
                            <label className="text-xs uppercase">
                                Referencia
                            </label>
                            <Input
                                value={step.ref || ""}
                                onChange={v => this.handleInput("step.ref", v)}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Tiempo (minutos/unidad)
                            </label>
                            <InputNumber
                                value={step.parTime}
                                onChange={v => this.handleInput("step.parTime", v)}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="w-1/2 pr-2">
                        <label className="text-xs uppercase">
                            Manual
                        </label>
                        <ManualSelector
                            value={step.manualVersionId}
                            onChange={v => this.handleInput("step.manualVersionId", v)}
                        />
                    </div>
                    { step.manualVersionId && <div className="w-1/2 pl-2">
                        <label className="text-xs uppercase">
                            Página
                        </label>
                        <InputNumber
                            value={step.pageNumber}
                            onChange={v => this.handleInput("step.pageNumber", v)}
                        />
                    </div> }
                </div>

                { step.type == "input" && <div className="flex mt-2">
                    <div className="w-1/5 mlsp-2">
                        <label className="text-xs uppercase">
                            Min.
                        </label>
                        <InputNumber
                            value={step.min}
                            onChange={v => this.handleInput("step.min", v)}
                        />
                    </div>
                    <div className="w-1/5 mlsp-2">
                        <label className="text-xs uppercase">
                            Max.
                        </label>
                        <InputNumber
                            value={step.max}
                            onChange={v => this.handleInput("step.max", v)}
                        />
                    </div>
                    <div className="w-1/5 mlsp-2">
                        <label className="text-xs uppercase">
                            Unidades
                        </label>
                        <Input
                            value={step.unit}
                            onChange={v => this.handleInput("step.unit", v)}
                        />
                    </div>
                    <div className="w-1/5 mlsp-2">
                        <label className="text-xs uppercase">
                            Regex
                        </label>
                        <Input
                            value={step.regex}
                            onChange={v => this.handleInput("step.regex", v)}
                        />
                    </div>
                    <div className="w-1/5 mlsp-2 flex justify-center items-center">
                        <label className="text-xs uppercase">
                            <input
                                type="checkbox"
                                className="mr-2 mt-6"
                                checked={step.critical}
                                onChange={e => this.handleInput("step.critical", e.target.checked)}
                            />
                            Critical
                        </label>
                    </div>
                </div> }

                { step.type == "supply" && <div className="mt-2">
                    <label className="text-xs uppercase">
                        Datos de aprovisionamiento
                    </label>
                    <div className="flex">
                        <pre className="text-xs font-mono font-semibold text-gray-700 mlsp-8">
                            # tubeItems
                            <br />
                            amount, locator, amountPerTube
                        </pre>
                        <pre className="text-xs font-mono font-semibold text-gray-700 mlsp-8">
                            # trayItems
                            <br />
                            amount, locator
                        </pre>
                    </div>
                    <TextArea
                        value={csv}
                        onChange={v => this.handleInput("csv", v)}
                        onBlur={this.handleItemsBlur}
                        rows={15}
                    />
                </div> }

                <div className="flex mt-4">
                    <Button
                        type="button"
                        className="ml-auto"
                        onClick={this.handleReset}
                        theme="outline"
                    >
                        Reset
                    </Button>

                    <Button className="ml-2">
                        Guardar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleSubmit = event => {
        event.preventDefault()

        const { step } = this.state

        if (!isNil(step.pageNumber)) {
            if (Number.isNaN(step.pageNumber)) {
                window.alert("Página debe ser un número")
                return
            }
            if (step.pageNumber < 1) {
                window.alert("Página debe ser mayor de 0")
                return
            }
        }

        if (!isNil(step.parTime)) {
            if (Number.isNaN(step.parTime)) {
                window.alert("PAR time debe ser un número")
                return
            }
            if (step.parTime < 0) {
                window.alert("PAR time debe ser mayor de 0")
            }
        }

        if (step.type == "input") {
            if (!isNil(step.min)) {
                if (Number.isNaN(step.min)) {
                    window.alert("Min. debe ser un número")
                    return
                }
            }

            if (!isNil(step.max)) {
                if (Number.isNaN(step.max)) {
                    window.alert("Max. debe ser un número")
                    return
                }
            }
        }

        this.props.onAccept(cloneDeep(step))
    }

    handleInput = (path, value) => {
        this.setState(produce(draft => {
            set(draft, path, value)
        }))
    }

    handleItemsBlur = () => {
        const { trayItems, tubeItems } = csv2items(this.state.csv)
        this.setState(produce(draft => {
            draft.step.trayItems = trayItems
            draft.step.tubeItems = tubeItems
            draft.csv = items2csv(trayItems, tubeItems)
        }))
    }

    handleReset = () => {
        this.setState(produce(draft => {
            draft.step = cloneDeep(this.initialStep)

            if (this.initialStep.type == "supply") {
                draft.csv = items2csv(this.initialStep.trayItems, this.initialStep.tubeItems)
            }
        }))
    }
}
