import React, { useState } from "react"
import cn from "classnames"
import { DateTime } from "luxon"
import urlJoin from "url-join"
import template from "lodash/template"
import { usePopper } from "react-popper"
import { useSelector } from "react-redux"

import {
    FaCloudUploadAlt,
    FaExternalLinkAlt,
    FaEye,
    FaKey,
    FaUser,
    FaEllipsisV,
    FaEquals,
    FaNotEqual,
    FaRegFilePdf,
    FaBoxOpen,
    FaShippingFast,
} from "react-icons/fa"

import {
    MdMoneyOff,
    MdAttachMoney,
    MdUpdate,
} from "react-icons/md"

import {
    useOnMousedownOutside,
    country2emoji,
} from "~/lib/utils"

import Button from "~/components/Button"

/* global GENUINE_URL_TEMPLATE */
const genuineUrl = sn => template(GENUINE_URL_TEMPLATE, {
    interpolate: /:([A-Za-z]+?)\b/g
})({ sn })

/* global API_BASE_URL */
export default function InstanceMediaItem({
    className,
    batch,
    instance,
    offset,

    onViewInstance,
    onViewSecurityCodes,
    onToggleDemo,
    onUpload,
    onMatch,
    onUnmatch,
}) {
    const currentUser = useSelector(state => state.app.users[state.app.currentUserId])
    const [showPopUp, setShowPopUp] = useState(false)
    const [updateDoneAtDate, setUpdateDoneAtDate] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    useOnMousedownOutside(popperElement, () => setShowPopUp(false))
    const { styles, attributes } = usePopper(referenceElement, popperElement)

    return <div className={cn("flex items-center p-1", className)}>
        <div className="mlsp-2 text-xs font-bold text-gray-800">
            #{ instance.seqIdx }##
        </div>
        <div
            className="text-sm mlsp-2"
            title="Número de serie"
        >
            { instance.code }
        </div>

        { instance.demo && <MdMoneyOff
            className="mlsp-2 text-sm text-gray-700"
            title="Equipo de demostración"
        /> }

        { instance.uploadedAt && <div
            className="text-xs flex items-center text-gray-700 mlsp-2"
            title="Fecha de publicación"
        >
            <FaCloudUploadAlt className="mr-1" />
            { DateTime.fromISO(instance.uploadedAt).toFormat("yyyy-LL-dd HH:mm:ss") }
        </div> }

        { (instance.clientEmail || instance.clientName) && <div
            className="text-gray-600 flex items-center mlsp-2"
            title="Cliente actual"
        >
            <FaUser className="mr-1 text-xs" />
            <span className="text-xs">
                { instance.clientName } { country2emoji(instance.clientCountry) }
            </span>
        </div> }

        { instance.shippingAt && <div
            className="text-gray-600 flex items-center mlsp-2 text-xs"
            title="Fecha de envío"
        >
            <FaShippingFast className="mr-1"/>
            { DateTime.fromISO(instance.shippingAt).toLocaleString(DateTime.DATETIME_SHORT) }
        </div> }

        { instance.matchGroupId && <div
            className="text-gray-600 flex items-center mlsp-2"
            title="Grupo de matcheo"
        >
            <FaEquals className="mr-1 text-xs" />
            <span className="text-xs">
                { instance.matchGroupCode }
            </span>
        </div> }

        { batch.retail && <Button
            className="ml-auto"
            theme="transparent"
            style="compact"
            onClick={() => setShowPopUp(true)}
            title="Más opciones"
        >
            <span ref={setReferenceElement} />
            <FaEllipsisV />
        </Button> }

        { showPopUp && <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="bg-white rounded shadow flex flex-col overflow-hidden text-gray-700"
        >
            { currentUser.admin && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onViewInstance}
            >
                <FaEye className="mr-1" />
                Ver detalles
            </button> }
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlJoin(API_BASE_URL, "box-stickers", instance.id)}
                className="text-sm p-4 flex items-center hover:bg-gray-200"
            >
                <FaBoxOpen className="mr-1" />
                Pegatina caja
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlJoin(API_BASE_URL, "device-stickers", instance.id, `?offset=${offset}`)}
                className="text-sm p-4 flex items-center hover:bg-gray-200"
            >
                <FaBoxOpen className="mr-1" />
                Pegatina trasera
            </a>
            { instance.uploadedAt && <a
                href={genuineUrl(instance.code)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm p-4 flex items-center hover:bg-gray-200"
            >
                <FaExternalLinkAlt className="mr-1" />
                Digital COC
            </a> }
            <a
                href={urlJoin(API_BASE_URL, "cocs", instance.id)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                title="COC"
            >
                <FaRegFilePdf className="mr-1" />
                COC
            </a>
            { (currentUser.admin && instance.matchGroupId) && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onUnmatch}
            >
                <FaNotEqual className="mr-1" />
                Des-Matchear
            </button> }
            { (currentUser.admin && !instance.matchGroupId) && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onMatch}
            >
                <FaEquals className="mr-1" />
                Matchear
            </button> }

            { currentUser.admin && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onViewSecurityCodes}
            >
                <FaKey className="mr-1" />
                Códigos de seguridad
            </button> }
            { currentUser.admin && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={onToggleDemo}
            >
                { instance.demo ? <MdMoneyOff className="mr-1" /> : <MdAttachMoney className="mr-1" /> }
                Equipo de demostración
            </button> }
            { currentUser.admin && <button
                className="text-sm p-4 flex items-center hover:bg-gray-200"
                type="button"
                onClick={() => onUpload(updateDoneAtDate)}
            >
                <FaCloudUploadAlt className="mr-1" />
                Publicar

                <span
                    className="ml-auto inline-flex"
                    title="Actualizar fecha de fabricación"
                >
                    <MdUpdate className="mr-1" />
                    <input
                        type="checkbox"
                        onClick={e => e.stopPropagation()}
                        checked={updateDoneAtDate}
                        onChange={e => setUpdateDoneAtDate(e.target.checked)}
                    />
                </span>

            </button> }
        </div> }
    </div>
}
