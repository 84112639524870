import React from "react"

import {
    fmtdaterange
} from "~/lib/utils"

import Input from "~/components/Input"
import Button from "~/components/Button"
import CalendarModal from "~/components/CalendarModal"

export default class InputDateRange extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showCalendar: false
        }
    }

    render() {
        const {
            value
        } = this.props

        const {
            showCalendar
        } = this.state

        return <>
            <div className="relative">
                <Input
                    className="pr-10"
                    value={fmtdaterange(value)}
                    onClick={this.handleInputClick}
                />
                <Button
                    className="absolute right-0 center-y mr-px"
                    theme="transparent"
                    onClick={this.handleClearDate}
                >
                    x
                </Button>
            </div>

            <CalendarModal
                isOpen={showCalendar}
                range={true}
                value={value}
                onChange={this.handleCalendarChange}
                onRequestClose={this.handleCloseModal}
            />
        </>
    }

    handleCloseModal = () => {
        this.setState({
            showCalendar: false
        })
    }

    handleInputClick = () => {
        this.setState({
            showCalendar: true
        })
    }

    handleCalendarChange = (v) => {
        this.props.onChange(v)
        this.setState({
            showCalendar: false
        })
    }

    handleClearDate = () => {
        this.props.onChange(undefined)
    }
}
