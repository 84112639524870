import React, { useState, useEffect } from "react"
import cn from "classnames"
import sortBy from "lodash/sortBy"
import { DateTime } from "luxon"
import axios from "axios"
import cloneDeep from "lodash/cloneDeep"

import { SizeMe } from "react-sizeme"

import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    LineMarkSeries,
    LineSeries,
    MarkSeries,
    Hint,
} from "react-vis"

import {
    FaFileCsv,
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa"

import {
    average,
    doDownload,
} from "~/lib/utils"

import Button from "~/components/Button"
import InputDate from "~/components/InputDate"
import Select from "~/components/Select"

function report2csv(report) {
    const lines = []
    for (let points of Object.values(report)) {
        for (let p of points) {
            lines.push(
                `${p.pn}, ${p.code}, ${p.units}, ${p.createdAt}, ${p.doneAt}, ${p.minutes}, ${p.doneBy}`
            )
        }
    }

    return [
        `pn, code, units, createdAt, doneAt, minutes, doneBy`
    ].concat(lines).join("\n")
}

export default function TimeReport({
    className
}) {
    const [from, setFrom] = useState(new Date(0))
    const [to, setTo] = useState(DateTime.now().plus({ days: 1}).toJSDate())
    const [folded, setFolded] = useState(true)
    const [selectedPn, setSelectedPn] = useState("")
    const [report, setReport] = useState({}) // { pn => [{}] }
    const [value, setValue] = useState(null)
    const [product, setProduct] = useState(null)

    useEffect(() => {
        const fromStr = from.toISOString()
        const toStr   = to.toISOString()
        axios.get(`/stats/time-report?from=${fromStr}&to=${toStr}`).then(response => {
            const { report } = response.data
            setReport(report)
        })
    }, [from, to])

    useEffect(() => {
        axios.get(`/products?pn=${selectedPn}`).then(response => {
            const { results } = response.data
            setProduct(results[0])
        })
    }, [selectedPn])

    const handleDownloadClick = event => {
        event.preventDefault()

        const csv = report2csv(report)
        const timestamp = DateTime.local().toFormat("yyyy_LL_dd-HH_mm_ss")
        doDownload(new Blob([csv], { type: "text/csv" }), `times_report-${timestamp}.csv`)
    }

    const removeDatapoint = async datapoint => {
        await axios.post(`/batches/${datapoint.id}/ignore`)
        const fromStr = from.toISOString()
        const toStr   = to.toISOString()
        const response = await axios.get(`/stats/time-report?from=${fromStr}&to=${toStr}`)
        const { report } = response.data
        setReport(report)
    }

    const restoreDatapoint = async datapoint => {
        await axios.post(`/batches/${datapoint.id}/not-ignore`)
        const fromStr = from.toISOString()
        const toStr   = to.toISOString()
        const response = await axios.get(`/stats/time-report?from=${fromStr}&to=${toStr}`)
        const { report } = response.data
        setReport(report)
    }

    const batches = cloneDeep(sortBy(report[selectedPn] || [], "doneAt"))
    for (let batch of batches) {
        batch.x = DateTime.fromISO(batch.doneAt).toMillis()
        batch.y = batch.minutes / batch.units
    }

    const dataSet        = batches.filter(b => b.monitorTime)
    const ignoredDataSet = batches.filter(b => !b.monitorTime)

    const avg = average(dataSet.map(b => b.y))
    const meanDataSet = batches.length ? [
        { x: batches[0].x,                y: avg },
        { x: batches[batches.length-1].x, y: avg },
    ] : [ ]

    let parTime = 0
    if (product) {
        for (let block of product.blocks) {
            for (let step of block.steps) {
                parTime += step.parTime
            }
        }
    }
    const targetDataSet = (batches.length && product) ? [
        { x: batches[0].x,                y: parTime },
        { x: batches[batches.length-1].x, y: parTime },
    ] : []

    const zeroDataSet = batches.length ? [
        { x: batches[0].x,                y: 0 },
        { x: batches[batches.length-1].x, y: 0 },
    ] : []

    return <div className={cn("p-2 relative", className)}>
        <h2 className="text-2xl font-extralight">
            Informe de tiempos
        </h2>

        <button
            className="absolute right-0 top-0 text-gray-300 bg-gray-700 hover:bg-gray-600 p-2 rounded-bl rounded-tr"
            onClick={() => setFolded(!folded)}
        >
            { folded ? <FaChevronDown /> : <FaChevronUp /> }
        </button>

        { !folded && <>
            <div className="flex mt-6">
                <div className="flex-1 pr-2">
                    <label className="uppercase text-xs">
                        FROM
                    </label>
                    <InputDate
                        value={from}
                        onChange={v => setFrom(v)}
                    />
                </div>
                <div className="flex-1 pl-2">
                    <label className="uppercase text-xs">
                        TO
                    </label>
                    <InputDate
                        value={to}
                        onChange={v => setTo(v)}
                    />
                </div>
            </div>

            <div className="mt-2">
                <label>
                    PN
                </label>
                <Select
                    onChange={v => setSelectedPn(v)}
                    value={selectedPn}
                >
                    { sortBy(Object.keys(report)).map(pn => <option
                        key={pn}
                        value={pn}
                    >
                        { pn }
                    </option>) }
                </Select>
            </div>

            <Button
                className="mt-2"
                onClick={handleDownloadClick}
            >
                <FaFileCsv className="mr-1" />
                Descargar
            </Button>

            <div className="flex mt-2">
                <div className="w-full">
                    Tiempo medio: { avg.toFixed(0) } minutos
                </div>
                { parTime && <div className="ml-2 w-full">
                    Tiempo objetivo: { parTime } minutos
                </div> }
            </div>

            <SizeMe render={({ size }) => (
                <div className="border w-full mt-2">
                    { size.width && <XYPlot
                        className="max-w-full"
                        xType="time"
                        width={size.width}
                        height={size.width * 9 / 16}>
                        <HorizontalGridLines />
                        <XAxis
                            title="Fecha de finalización"
                            tickPadding={1}
                            tickLabelAngle={-45}
                        />
                        <YAxis title="Minutos por unidad" />
                        <LineSeries
                            data={zeroDataSet}
                            color="gray"
                        />
                        { parTime ? <LineSeries
                            data={targetDataSet}
                            color="yellow"
                        /> : null }
                        <LineSeries
                            data={meanDataSet}
                            color="red"
                        />
                        <MarkSeries
                            onValueMouseOver={v => setValue(v)}
                            onValueMouseOut={() => setValue(null)}
                            onValueClick={restoreDatapoint}
                            data={ignoredDataSet}
                        />
                        <LineMarkSeries
                            onValueMouseOver={v => setValue(v)}
                            onValueMouseOut={() => setValue(null)}
                            onValueClick={removeDatapoint}
                            data={dataSet}
                        />

                        { value ? <Hint value={value} /> : null }
                    </XYPlot> }
                </div>
            )} />
        </> }
    </div>
}
