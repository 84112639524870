import React from "react"
import cn from "classnames"

import {
    FaUserCog,
    FaVial,
    FaBoxes,
} from "react-icons/fa"

export default function TotalCosts({ className, totalCosts }) {
    const productCost     = totalCosts.product || 0
    const labourCost      = totalCosts.labour || 0
    const consumablesCost = totalCosts.consumable || 0
    const totalCost = productCost + labourCost + consumablesCost

    return <div className={cn("flex items-end", className)}>
        <span
            className="mlsp-2 text-sm font-semibold"
            title="Coste total"
        >
            { totalCost.toFixed(4) } €
        </span>
        { productCost > 0 && <span
            className="mlsp-2 inline-flex items-center text-blue-500 text-xs"
            title="Componentes"
        >
            <FaBoxes className="mr-1" />
            { productCost.toFixed(4) } €
        </span> }
        { consumablesCost > 0 && <span
            className="mlsp-2 inline-flex items-center text-green-500 text-xs"
            title="Consumibles"
        >
            <FaVial className="mr-1" />
            { consumablesCost.toFixed(4) } €
        </span> }
        { labourCost > 0 && <span
            className="mlsp-2 inline-flex items-center text-red-500 text-xs"
            title="Mano de obra"
        >
            <FaUserCog className="mr-1" />
            { labourCost.toFixed(4) } €
        </span> }
    </div>
}
