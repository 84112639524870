import React from "react"
import isNil from "lodash/isNil"

import Input from "./Input"

export default class InputNumber extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: "",
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (isNil(props.value)) {
            return { value: "" }
        } else if (Number.isNaN(props.value)) {
            return {}
        } else if (parseFloat(state.value) != props.value) {
            return {
                value: props.value.toString()
            }
        } else {
            return {}
        }
    }

    render() {
        const props = Object.assign({}, this.props, {
            value: this.state.value,
            onChange: this.handleChange,
        })

        return <Input {...props} />
    }

    handleChange = value => {
        this.setState({ value })

        value = value.trim().replace(",", ".")
        if (value == "") {
            this.props.onChange(null)
        } else {
            const n = parseFloat(value, 10)
            this.props.onChange(n)
        }
    }
}
