import React from "react"

import Select from "~/components/Select"

export default class CountrySelector extends React.Component {
    render() {
        return <Select
            value={this.props.value}
            onChange={this.props.onChange}
        >
            <option value="es">
                España
            </option>
            <option value="uk">
                Reino Unido
            </option>
            <option value="eu">
                Europa
            </option>
            <option value="usa">
                Estados Unidos
            </option>
            <option value="china">
                China
            </option>
            <option value="japan">
                Japón
            </option>
            <option value="south_korea">
                Corea del Sur
            </option>
            <option value="latam">
                América Latina
            </option>
        </Select>
    }
}
