import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"

export default class ImageModal extends React.Component {
    render() {
        const {
            url,
            isOpen,
            onRequestClose,
        } = this.props

        return <ReactModal
            className="m-8 px-4 pb-4 pt-8 mx-auto max-w-6xl relative bg-white rounded overflow-hidden"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <img src={url} />
        </ReactModal>
    }
}
