import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import axios from "axios"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import TextArea from "~/components/TextArea"

export default function StockReportPnModal({
    onRequestClose
}) {
    const [pns, setPns] = useState([])

    useEffect(() => {
        axios.get("/stats/stock-report/pns")
        .then(response => {
            setPns(response.data.pns)
        })
    }, [])

    const handleAccept = async () => {
        await axios.post("/stats/stock-report/pns", { pns })
        onRequestClose()
    }

    return <ReactModal
        className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-lg relative outline-none"
        isOpen
        onRequestClose={onRequestClose}
    >
        <Button
            className="absolute left-0 top-0"
            style="compact"
            theme="transparent"
            onClick={onRequestClose}
        >
            <FaWindowClose />
        </Button>

        <TextArea
            rows="10"
            value={pns.join("\n")}
            onChange={str => setPns(str.split("\n"))}
        />

        <div className="flex justify-end mt-2">
            <Button
                className="mlsp-2"
                onClick={onRequestClose}
                theme="outline"
            >
                Cancelar
            </Button>
            <Button
                className="mlsp-2"
                onClick={handleAccept}
            >
                Aceptar
            </Button>
        </div>
    </ReactModal>
}
