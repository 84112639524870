import React from "react"
import { Page, Document } from "react-pdf/dist/esm/entry.webpack"
import { SizeMe } from "react-sizeme"
import { connect } from "react-redux"

import { clamp } from "~/lib/utils"

const mapStateToProps = state => ({
    scale: state.app.scale
})

export default connect(mapStateToProps)(class PdfViewer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            numPages: 1,
        }
    }

    render() {
        const {
            url,
            pageNumber,
            fit="width",
            scale,
        } = this.props
        const {
            numPages
        } = this.state

        return <SizeMe
            refreshRate={60}
            refreshMode={"debounce"}
            render={({ size }) => (
                <Document
                    file={url}
                    loading="Cargando manual…"
                    error="Error al cargar el manual."
                    onLoadSuccess={this.handleDocumentLoadSuccess}
                    options={{ withCredentials: true }}
                >
                    <Page
                        key={`${pageNumber}${numPages}`}
                        className="flex justify-center"
                        width={fit == "width" ? scale * size.width : undefined}
                        height={fit == "height" ? scale * window.innerHeight : undefined}
                        loading="…"
                        pageNumber={clamp(1, numPages, pageNumber)}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        onClick={this.props.onClick}
                        onRenderSuccess={this.props.onRenderSuccess}
                    />
                </Document>
            )}
        />
    }

    handleDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages })
        if (this.props.onReady) {
            this.props.onReady({ numPages })
        }
    }
})
