import React from "react"
import { connect } from "react-redux"
import { Redirect, Link } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import { DateTime } from "luxon"
import cn from "classnames"
import axios from "axios"

import {
    IoMdReturnLeft,
    IoMdRemoveCircleOutline,
} from "react-icons/io"

import {
    FaPlus,
    FaExternalLinkAlt,
    FaFileImport,
} from "react-icons/fa"

import {
    refreshStockGraph,
    importStockItemDescendants,
    addStockItemDescendant,
    updateStockItemDescendant,
    removeStockItemDescendant,
    reorderEdges,
} from "~/store"

import {
    getDescendants,
    getAncestors,
} from "~/lib/utils"

import Button from "~/components/Button"

import ImportStockDescendantsModal from "~/components/ImportStockDescendantsModal"
import AddStockDescendantModal from "~/components/AddStockDescendantModal"
import TotalCosts from "~/components/TotalCosts"
import StockItemRow from "~/components/StockItemRow"
import StockDescendantItem from "~/components/StockDescendantItem"
import SortList from "~/components/SortList"

const mapStateToProps = state => ({
    currentUser: state.app.users[state.app.currentUserId],

    items:      state.app.stock.items,
    edges:      state.app.stock.edges,
    adj:        state.app.stock.adj,
    iadj:       state.app.stock.iadj,
    subgraphs:  state.app.stock.subgraphs,
    totalCosts: state.app.stock.totalCosts,

    date:       state.app.stock.date,
})

const mapDispatchToProps = dispatch => ({
    onRefresh:                  (...args) => dispatch(refreshStockGraph(...args)),
    addStockItemDescendant:     (...args) => dispatch(addStockItemDescendant(...args)),
    importStockItemDescendants: (...args) => dispatch(importStockItemDescendants(...args)),
    removeStockItemDescendant:  (...args) => dispatch(removeStockItemDescendant(...args)),
    onReorderEdges:             (...args) => dispatch(reorderEdges(...args)),
    updateStockItemDescendant:  (...args) => dispatch(updateStockItemDescendant(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(class StockItemView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            descendantModalItem: null,
            showImportStockDescendantsModal: false,
            average: 0,
        }

        this.blockElements = []
    }

    async componentDidMount() {
        await this.props.onRefresh()
        const item = this.getItem()

        if (this.props.currentUser.admin && item.pn) {
            const response = await axios.get(`/stats/time-report/${item.pn}`)
            const { avg } = response.data
            this.setState({ average: avg })
        }
    }

    getItem() {
        const { id } = this.props.match.params
        return this.props.items[id]
    }

    getDescendants() {
        const {
            items,
            adj,
        } = this.props

        const item = this.getItem()

        return getDescendants(item, items, adj).children || []
    }

    getAncestors() {
        const {
            items,
            iadj
        } = this.props

        const item = this.getItem()

        return getAncestors(item, items, iadj).children || []
    }

    render() {
        if (this.state.redirectToStock) return <Redirect to="/stock" />

        const item = this.getItem()
        if (!item) return <div>Cargando...</div>

        const ancestors = this.getAncestors()
        const descendants = this.getDescendants()

        const { totalCosts, currentUser, date } = this.props

        const now = DateTime.utc()
        const timetraveling = !now.hasSame(DateTime.fromISO(date), "day")

        return <div className={cn("p-2", {
            "bg-yellow-200": timetraveling
        })}>
            <div className="flex items-center mb-2">
                <Link to="/stock">
                    <Button theme="transparent">
                        <IoMdReturnLeft />
                    </Button>
                </Link>
                <h1 className="text-xl ml-2">
                    { item.name }
                </h1>
            </div>

            <div className="grid grid-cols-3 gap-4 mb-8">
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Nombre
                    </label>
                    <div>
                        { item.name }
                    </div>
                </div>
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        tipo
                    </label>
                    <div>
                        { item.type }
                    </div>
                </div>
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        PN
                    </label>
                    <div>
                        { item.pn }
                    </div>
                </div>
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        MPN
                    </label>
                    <div>
                        { item.mpn }
                    </div>
                </div>
                { item.manufacturer && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Fabricante
                    </label>
                    <div>
                        { item.manufacturer }
                    </div>
                </div> }
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Descripción
                    </label>
                    <div
                        className="overflow-y-scroll"
                        style={{ maxHeight: "4em" }}
                    >
                        { item.description }
                    </div>
                </div>
                { currentUser.admin && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Web
                    </label>
                    <div className="truncate">
                        { item.web }
                    </div>
                </div> }
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Localización
                    </label>
                    <div>
                        { item.locator }
                    </div>
                </div>
                { (descendants.length == 0 && currentUser.admin) && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Coste
                    </label>
                    <div>
                        { item.cost.toFixed(4) } €
                    </div>
                </div> }
                { (descendants.length != 0 && currentUser.admin) && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Coste
                    </label>
                    <TotalCosts
                        className="mt-2"
                        totalCosts={totalCosts[item.id]}
                    />
                </div> }
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Cantidad por cubo
                    </label>
                    <div>
                        { item.amountPerTube }
                    </div>
                </div>
                { this.state.average && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Tiempo medio de fabricación
                    </label>
                    <div>
                        { this.state.average.toFixed(0) } minutos
                    </div>
                </div> }
                <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Cantidad
                    </label>
                    <div>
                        { item.amount }
                    </div>
                </div>
                { currentUser.admin && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Holded ID
                    </label>
                    <div className="flex items-center">
                        { item.holdedId || "-" }
                        { item.holdedId && <a
                            href={`https://app.holded.com/products/${item.holdedId}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="ml-2"
                        >
                            <FaExternalLinkAlt />
                        </a> }
                    </div>
                </div> }
                { currentUser.admin && <div className="border rounded p-1">
                    <label className="uppercase text-xs">
                        Ignorar para producción
                    </label>
                    <div>
                        { item.ignore ? "Sí" : "No" }
                    </div>
                </div> }
            </div>

            { ancestors.length > 0 && <div className="mb-8">
                <h2 className="text-xl font-extralight">
                    Pertenece a
                </h2>

                <hr />

                { ancestors.map(item => <StockItemRow
                    className="-mtsp-1"
                    key={item.relation.id || item.id}
                    item={item}
                    totalCosts={totalCosts}
                />) }
            </div> }

            <div className="mb-8">
                <div className="flex items-end">
                    <h2 className="text-xl font-extralight">
                        Contiene
                    </h2>
                    { (currentUser.admin && !timetraveling) && <span className="ml-auto flex mb-1">
                        <Button
                            className="mlsp-2"
                            theme="outline"
                            style="compact"
                            onClick={this.handleImportDescendantsClick}
                        >
                            <FaFileImport className="mr-1 text-xs" />
                            Importar
                        </Button>
                        <Button
                            className="mlsp-2"
                            theme="outline"
                            style="compact"
                            onClick={this.handleAddDescendantClick}
                        >
                            <FaPlus className="mr-1 text-xs" />
                            Añadir
                        </Button>
                        <Button
                            className="mlsp-2"
                            theme="danger"
                            style="compact"
                            onClick={this.handleRemoveAllDescendants}
                        >
                            <IoMdRemoveCircleOutline className="mr-1 text-xs" />
                            Eliminar todos
                        </Button>
                    </span> }
                </div>

                <hr />

                <table className="w-full">
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>
                                Cantidad
                            </th>
                            <th>
                                MPN
                            </th>
                            <th>
                                PN
                            </th>
                            <th>
                                Nombre
                            </th>
                            <th>
                                Ubicación
                            </th>
                            <th>
                                Designador
                            </th>
                            { currentUser.admin && <th>
                                Coste
                            </th> }
                            <th>
                                Stock
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <SortList
                            items={descendants}
                            render={(item, { dragged, onMouseDown, ref }) => <StockDescendantItem
                                item={item}
                                totalCosts={totalCosts}
                                isBeingDragged={dragged}
                                onMouseDown={onMouseDown}
                                onEditDescendant={() => this.handleEditDescendant(item)}
                                onRemoveDescendant={() => this.handleRemoveDescendant(item)}
                                ref={ref}
                            />}
                            disabled={!currentUser.admin || timetraveling}
                            onDragEnd={this.handleDragEnd}
                            renderPlaceholder={() => <tr>
                                <td
                                    colSpan="8"
                                    className="bg-blue-800 h-1"
                                />
                            </tr>}
                        />
                    </tbody>
                </table>
            </div>

            { this.state.descendantModalItem && <AddStockDescendantModal
                {...this.state.descendantModalItem}
                onRequestClose={() => this.setState({ descendantModalItem: null })}
                onSelectItem={this.handleAddDescendant}
            /> }

            { this.state.showImportStockDescendantsModal && <ImportStockDescendantsModal
                onRequestClose={() => this.setState({ showImportStockDescendantsModal: false })}
                onImportItem={this.handleImportItem}
            /> }
        </div>
    }

    handleDragEnd = (from, to) => {
        const { id } = this.props.match.params
        this.props.onReorderEdges(id, from, to)
    }

    handleAddDescendantClick = () => {
        this.setState({
            descendantModalItem: {}
        })
    }

    handleImportDescendantsClick = () => {
        this.setState({
            showImportStockDescendantsModal: true
        })
    }

    handleImportItem = async(itemId) => {
        await this.props.importStockItemDescendants(this.props.match.params.id, itemId)
    }

    handleAddDescendant = async (childId, notes, amount) => {
        if (isEmpty(this.state.descendantModalItem)) {
            // New
            const { id } = this.props.match.params
            const { items } = this.props
            const parent = items[id]
            const child = items[childId]
            await this.props.addStockItemDescendant(parent, child, notes, amount)
        } else {
            // Updating
            const { edgeId } = this.state.descendantModalItem
            await this.props.updateStockItemDescendant(edgeId, childId, notes, amount)
        }
    }

    handleRemoveDescendant = async (child) => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        const { id } = this.props.match.params
        const { items } = this.props
        const parent = items[id]
        await this.props.removeStockItemDescendant(parent, child)
    }

    handleRemoveAllDescendants = async () => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        const { id } = this.props.match.params
        const { items } = this.props
        const parent = items[id]
        const descendants = this.getDescendants()

        for (let child of descendants) {
            await this.props.removeStockItemDescendant(parent, child)
        }
    }

    handleEditDescendant = child => {
        this.setState({
            descendantModalItem: {
                selectedItemId: child.id,
                edgeId: child.relation.id,
                notes:  child.relation.notes,
                amount: child.relation.amount,
            }
        })
    }
})
