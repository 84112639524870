import React from "react"
import ReactModal from "react-modal"

import {
    FaSpinner
} from "react-icons/fa"

export default class SpinnerModal extends React.Component {
    render() {
        return <ReactModal isOpen>
            <FaSpinner className="absolute center spin text-gray-600 text-6xl" />
        </ReactModal>
    }
}
