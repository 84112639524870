import React from "react"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import axios from "axios"
import { Redirect, Link } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import cn from "classnames"

import {
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaInfoCircle,
    FaListOl,
    FaCamera,
} from "react-icons/fa"

import {
    IoMdReturnLeft
} from "react-icons/io"

import {
    doStep,
    editStep,
    enterBatch,
    refreshBatch,
} from "~/store"

import {
    selectBatchSteps,
    selectBlockFromStepId,
    selectStepStatus,
    selectBatchParTime,
} from "~/lib/selectors"

import {
    clamp,
    sleep,
    min2hhmm,
} from "~/lib/utils"

import Button from "~/components/Button"
import Select from "~/components/Select"
import StepInput from "~/components/StepInput"
import StepCheck from "~/components/StepCheck"
import StepPicture from "~/components/StepPicture"
import StepSupply from "~/components/StepSupply"
import Zoomer from "~/components/Zoomer"
import ProgressBar from "~/components/ProgressBar"
import BatchInfoModal from "~/components/BatchInfoModal"

const mapStateToProps = (state, props) => {
    const { id } = props.match.params
    return {
        batch:       state.app.batches.entities[id],
        now:         DateTime.fromISO(state.app.now),
        currentUser: state.app.users[state.app.currentUserId],
    }
}

const mapDispatchToProps = dispatch => ({
    onEnterBatch:   (...args) => dispatch(enterBatch(...args)),
    onRefreshBatch: (...args) => dispatch(refreshBatch(...args)),
    onStepDone:     (...args) => dispatch(doStep(...args)),
    onEditStep:     (...args) => dispatch(editStep(...args)),
})

function StepComponent(props) {
    const { step } = props

    switch (step.type) {
        case "input":
        case "json":        return <StepInput {...props} />
        case "check":       return <StepCheck {...props} />
        case "picture":     return <StepPicture {...props} />
        case "autosupply":
        case "supply":      return <StepSupply {...props} />
        default: throw new Error(`Unknown step type ${step.type}`)
    }
}

// dupped in BatchMediaItem
const batch2segments = (batch, instanceAnswers) => {
    const steps = selectBatchSteps(batch)
    const idx = selectFirstUnansweredStepIdx(steps, instanceAnswers)

    return steps.map((s, i) => {
        if (instanceAnswers[s.id]) {
            return "ok"
        } else if (idx == i) {
            return "enabled"
        } else {
            return "disabled"
        }
    })
}

// steps: [Step]
// instanceAnswers: { stepId => StepAnswer }
function selectFirstUnansweredStepIdx(steps, instanceAnswers) {
    for (let i = 0; i < steps.length; i++) {
        const step = steps[i]
        if (instanceAnswers[step.id] === undefined) {
            return i
        }
    }
    return -1
}

export default connect(mapStateToProps, mapDispatchToProps)(class BatchView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            stepIdx:            0,
            redirectToHome:     false,
            lastInteractionAt:  DateTime.utc().toISO(),
            pingCount:          {},
            showInfoModal:      false,
            selectedInstanceId: "",
            instances:          [],
            answers:            {}, // { instanceId => stepId => StepAnswer }
        }
    }

    async componentDidMount() {
        const { id } = this.props.match.params
        await this.props.onRefreshBatch(id)
        const { batch } = this.props

        if (!batch.enabledAt) {
            const go = window.confirm("¿Estás listo para empezar?")
            if (go) {
                await this.props.onEnterBatch(id)
            } else {
                this.setState({ redirectToHome: true })
                return
            }
        }

        const [instancesRes, answersRes, pingCountRes] = await Promise.all([
            axios.get(`/batches/${id}/instances`),
            axios.get(`/batches/${id}/answers`),
            axios.get(`/batches/${id}/ping-count`),
        ])

        this.setState({
            selectedInstanceId: instancesRes.data[0].id,
            instances:          instancesRes.data,
            answers:            answersRes.data,
            pingCount:          pingCountRes.data,
        }, () => {
            const steps = selectBatchSteps(this.props.batch)
            const instanceAnswers = this.state.answers[this.state.selectedInstanceId]
            const stepIdx = selectFirstUnansweredStepIdx(steps, instanceAnswers)
            if (stepIdx != -1) this.setState({ stepIdx })
        })

        this.intervalId = window.setInterval(() => {
            axios.post(`/batches/${id}/ping-count`)
            .then(response => {
                this.setState({ pingCount: response.data })
            })
        }, 15000)
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId)
    }

    refreshAnswers = async () => {
        const { id } = this.props.match.params
        const response = await axios.get(`/batches/${id}/answers`)
        this.setState({
            answers: response.data
        })
    }

    render() {
        const {
            currentUser,
            now,
            batch,
        } = this.props

        const {
            stepIdx,
            lastInteractionAt,
            redirectToHome,
            instances,
            answers,
            selectedInstanceId,
            pingCount,
        } = this.state

        if (redirectToHome)   return <Redirect to="/" />
        if (!currentUser)     return <Redirect to="/login" />
        if (!batch)           return null
        if (isEmpty(answers)) return null

        const steps = selectBatchSteps(batch)
        const currentStep = steps[stepIdx]
        const currentBlock = selectBlockFromStepId(batch, currentStep.id)

        const stepAnswer = answers[selectedInstanceId][currentStep.id]

        const tooSoon = now.diff(DateTime.fromISO(lastInteractionAt)).valueOf() < 500
        const stepBlocked = !!stepAnswer

        const showLeftButton = true
        const showRightButton = true
        const showEndButton = true

        const segments = batch2segments(batch, answers[selectedInstanceId])

        const parTime = selectBatchParTime(batch)

        const minutes = Object.values(pingCount).reduce((acc, cur) => acc + cur, 0)

        const showInstanceSelector = (instances.length > 1) && steps.some(s => s.perInstance)

        const dangerZone  = minutes >= parTime
        const warningZone = minutes >= 0.9*parTime && minutes < parTime
        const safeZone    = minutes < 0.9*parTime

        return <div className="mx-auto pt-16">
            <StepComponent
                batch={batch}
                step={currentStep}
                answer={stepAnswer}
                blocked={stepBlocked}
                tooSoon={tooSoon}
                onInteraction={this.handleStepInteraction}
                onDone={v => this.handleStepDone(currentStep.id, v)}
                onEdit={v => this.handleStepEdit(currentStep.id, v)}
            />

            <div className="fixed top-0 inset-x-0">
                <div className="flex items-center bg-gray-200">
                    <Link
                        title="Volver"
                        className="self-stretch p-2 hover:bg-gray-400 flex items-center"
                        to="/"
                    >
                        <IoMdReturnLeft />
                    </Link>

                    <div className="p-2 mr-2">
                        <span className="text-2xl">
                            { stepIdx + 1 }
                            </span> <span className="text-xs">
                            / { steps.length }
                        </span>
                    </div>

                    <div className="flex flex-col">
                        <div className="text-gray-700 text-sm flex items-center">
                            <span className="font-bold mlsp-2">
                                { batch.name || batch.pn }
                            </span>
                            <span className="mlsp-2 text-gray-700">
                                (x{ batch.units })
                            </span>
                            <Button
                                theme="transparent"
                                style="compact"
                                title="Más información"
                                className="ml-2"
                                onClick={this.handleInfoClick}
                            >
                                <FaInfoCircle />
                            </Button>
                        </div>
                        <div className="flex items-center text-xs text-gray-700">
                            <span className="font-thin">
                                { currentBlock.name } /
                            </span>
                            &nbsp;
                            <span>
                                { currentStep.name }
                            </span>
                        </div>
                    </div>

                    <div className="ml-auto p-1">
                        <div className="flex items-baseline text-gray-700">
                            <label className="uppercase text-xs">
                                Objetivo
                            </label>
                            <div className="ml-2 text-xs">
                                { min2hhmm(parTime) }
                            </div>
                        </div>
                        <div className={cn("text-xl font-light text-center rounded", {
                            "bg-red-200":   dangerZone,
                            "text-red-700": dangerZone,

                            "bg-orange-200":   warningZone,
                            "text-orange-200": warningZone,

                            "bg-green-200":   safeZone,
                            "text-green-700": safeZone,
                        })}>
                            { min2hhmm(minutes) }
                        </div>
                    </div>
                    { showInstanceSelector && <div className="ml-4 flex items-center p-1">
                        <label className="text-xs uppercase">
                            Instancia
                        </label>
                        <Select
                            className="ml-1"
                            value={this.state.selectedInstanceId}
                            onChange={this.handleSelectedInstanceIdChange}
                        >
                            { instances.map(instance => <option
                                value={instance.id}
                                key={instance.id}
                            >
                                #{ instance.seqIdx || "-" }## { instance.code }
                            </option>) }
                        </Select>
                    </div> }
                    <Zoomer className="ml-4" />
                </div>

                <ProgressBar
                    segments={segments}
                    currentSegmentIdx={stepIdx}
                    onClick={s => this.handleGoStep(s)}
                >
                    { (seg, idx) => steps[idx].perInstance ? <FaListOl className="mt-6 mlsp-1 text-gray-700 text-sm" /> : null }
                    { (seg, idx) => steps[idx].type == "picture" ? <FaCamera className="mt-6 mlsp-1 text-gray-700 text-sm" /> : null }
                </ProgressBar>

                <div className="flex p-3 opacity-75">
                    { showLeftButton && <Button
                        title="Anterior"
                        className="shadow-md"
                        style="circle"
                        onClick={this.handleLeftButtonClick}
                    >
                        <FaAngleLeft />
                    </Button> }

                    <span className="ml-auto flex">
                        { showRightButton && <Button
                            title="Siguiente"
                            className="shadow-md"
                            style="circle"
                            onClick={this.handleRightButtonClick}
                        >
                            <FaAngleRight />
                        </Button> }

                        { showEndButton && <Button
                            title="Continuar"
                            className="ml-3 shadow-md"
                            style="circle"
                            onClick={this.handleEndButtonClick}
                        >
                            <FaAngleDoubleRight />
                        </Button> }
                    </span>
                </div>
            </div>

            { this.state.showInfoModal && <BatchInfoModal
                batch={batch}
                onRequestClose={() => this.setState({ showInfoModal: false })}
            /> }
        </div>
    }

    handleInfoClick = () => {
        this.setState({ showInfoModal: true })
    }

    handleSelectedInstanceIdChange = (value) => {
        this.setState({ selectedInstanceId: value }, () => {
            this.handleEndButtonClick()
        })
    }

    handleInput = path => value => {
        this.setState({
            [path]: value
        })
    }

    handleStepInteraction = () => {
        this.setState({
            lastInteractionAt: DateTime.utc().toISO()
        })
    }

    handleLeftButtonClick = () => {
        const { batch } = this.props
        const steps = selectBatchSteps(batch)
        this.handleGoStep(clamp(0, steps.length - 1, this.state.stepIdx - 1))
    }

    handleRightButtonClick = () => {
        const { batch } = this.props
        const steps = selectBatchSteps(batch)
        this.handleGoStep(clamp(0, steps.length - 1, this.state.stepIdx + 1))
    }

    handleEndButtonClick = async () => {
        const { batch } = this.props
        const { answers, selectedInstanceId } = this.state
        const steps = selectBatchSteps(batch)
        const instanceAnswers = answers[selectedInstanceId]
        const stepIdx = selectFirstUnansweredStepIdx(steps, instanceAnswers)
        if (stepIdx != -1) this.setState({ stepIdx })
    }

    handleGoStep = idx => {
        const { batch } = this.props
        const { answers, selectedInstanceId } = this.state
        const steps = selectBatchSteps(batch)
        const instanceAnswers = answers[selectedInstanceId]
        const stepIdx = selectFirstUnansweredStepIdx(steps, instanceAnswers)
        const step = steps[idx]
        instanceAnswers[step.id]
        if (instanceAnswers[step.id] || idx == stepIdx) {
            this.setState({ stepIdx: idx })
        }
    }

    handleStepEdit = async (stepId, v) => {
        const { batch } = this.props
        const {
            selectedInstanceId,
            instances,
        } = this.state

        const instance = instances.find(i => i.id == selectedInstanceId)
        const steps = selectBatchSteps(batch)
        const step = steps.find(step => step.id == stepId)

        if (step.type == "json") {
            try {
                JSON.parse(v)
            } catch (e) {
                window.alert("El valor no tiene el formato adecuado.")
                return
            }
        }

        // Validate step format
        // TODO validate in each step
        if (step.regex) {
            if (!new RegExp(step.regex).test(v)) {
                window.alert(`El valor no tiene el formato adecuado.`)
                return
            }
        }

        if (instance.uploadedAt) {
            const ok = window.confirm("Este montaje ya está publicado. Los cambios realizados no se actualizarán automáticamente en la web de clientes. ¿Seguro que quieres modificar este valor?")
            if (!ok) return
        }

        // Threshold validation
        const status = selectStepStatus(step, v)
        if (status != "ok") {
            const ok = window.confirm("El valor no está dentro de los umbrales establecidos. ¿Seguro que quieres continuar?")
            if (!ok) return
        }

        await this.props.onEditStep(batch.id, selectedInstanceId, stepId, v)
        await this.refreshAnswers()
    }

    handleStepDone = async (stepId, v) => {
        const { batch } = this.props
        const { selectedInstanceId } = this.state
        const steps = selectBatchSteps(batch)
        const step = steps.find(step => step.id == stepId)

        if (step.type == "json") {
            try {
                JSON.parse(v)
            } catch (e) {
                window.alert("El valor no tiene el formato adecuado.")
                return
            }
        }

        // Validate step format
        // TODO validate in each step
        if (step.regex) {
            if (!new RegExp(step.regex).test(v)) {
                window.alert(`El valor no tiene el formato adecuado.`)
                return
            }
        }

        // Threshold validation
        const status = selectStepStatus(step, v)
        if (status != "ok") {
            const ok = window.confirm("El valor no está dentro de los umbrales establecidos. ¿Seguro que quieres continuar?")
            if (!ok) return
        }

        await this.props.onStepDone(batch.id, selectedInstanceId, stepId, v)
        await this.refreshAnswers()
        this.handleEndButtonClick()
        if (this.props.batch.doneAt) {
            window.alert("¡Montaje finalizado correctamente!")
            await sleep(2000)
            this.setState({ redirectToHome: true })
        }
    }
})
