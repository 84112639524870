import React from "react"

import {
    FaRegCheckCircle,
} from "react-icons/fa"

import {
    TiWarningOutline
} from "react-icons/ti"

export default class ToastNotification extends React.Component {
    getIcon(kind) {
        switch(kind) {
            case "warn": return <TiWarningOutline className="text-3xl text-yellow-700" />
            case "error": return <TiWarningOutline className="text-3xl text-red-700" />
            case "success": return <FaRegCheckCircle className="text-3xl text-green-600" />
            default: throw new Error(`Unknown notification kind ${kind}`)
        }
    }

    render() {
        const {
            kind,
            text,
            className,
        } = this.props

        return <div className={"flex items-center bg-white shadow-lg rounded p-4 border " + className}>
            { this.getIcon(kind) }
            <p className="ml-2 text-gray-800">
               { text }
            </p>
        </div>
    }
}
