import React from "react"
import ReactModal from "react-modal"
import { connect } from "react-redux"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import SpinnerModal from "~/components/SpinnerModal"

import {
    uploadManualVersion
} from "~/store"

const mapDispatchToProps = dispatch => ({
    onUploadManualVersion: (...args) => dispatch(uploadManualVersion(...args)),
})

export default connect(null, mapDispatchToProps)(class UploadManualVersionModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showSpinner: false
        }
    }

    render() {
        return <ReactModal
            className="m-8 p-4 mx-auto max-w-xl relative bg-white rounded overflow-hidden"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0 z-10"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <input
                    ref={el => this.inputFile = el}
                    accept=".pdf"
                    type="file"
                />
                <Button className="ml-auto">
                    Actualizar
                </Button>
            </form>

            { this.state.showSpinner && <SpinnerModal /> }
        </ReactModal>
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        const files = Array.from(this.inputFile.files)
        if (files.length == 0) return

        this.setState({ showSpinner: true })
        try {
            await this.props.onUploadManualVersion(this.props.manual, files[0])
            this.props.onRequestClose()
        } finally {
            this.setState({ showSpinner: false })
        }
    }
})
