import React from "react"
import { connect } from "react-redux"
import cn from "classnames"
import ReactModal from "react-modal"
import produce from "immer"
import cloneDeep from "lodash/cloneDeep"
import set from "lodash/set"
import isEqual from "lodash/isEqual"

import {
    FaWindowClose,
    FaSpinner,
    FaTrashAlt,
} from "react-icons/fa"

import {
    MdDragHandle,
} from "react-icons/md"

import {
    reorderArray,
    ean13_checksum
} from "~/lib/utils"

import {
    upsertProduct,
    notify,
} from "~/store"

import Button from "~/components/Button"
import Input from "~/components/Input"
import Select from "~/components/Select"
import SortList from "~/components/SortList"

import EditBlockModal from "~/components/EditBlockModal"

const mapDispatchToProps = dispatch => ({
    onUpsertProduct: p => dispatch(upsertProduct(p)),
    onNotify: (...args) => dispatch(notify(...args)),
})

export default connect(null, mapDispatchToProps)(class EditProductModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialProduct = Object.assign({
            pn: "",
            name: "",
            shortName: "",
            ean13: "",
            product: true,
            retail: false,
            countMode: "batch",
            blocks: [],
        }, props.product)

        this.state = {
            product: cloneDeep(this.initialProduct),
            newBlockName: "",
            editingBlockIdx: null,
            working: false,
        }

        this.blockElements = []
    }

    handleRequestClose = () => {
        if (!isEqual(this.initialProduct, this.state.product)) {
            const go = window.confirm("Este producto tiene cambios sin guardar. ¿Seguro que deseas salir?")
            if (!go) return
        }

        this.props.onRequestClose()
    }

    render() {
        const {
            newBlockName,
            product,
            editingBlockIdx
        } = this.state

        const editingBlock = product?.blocks[editingBlockIdx]

        return <ReactModal
            className="max-w-3xl mx-auto pt-8 px-4 pb-4 relative bg-white rounded overflow-hidden outline-none"
            isOpen
            onRequestClose={this.handleRequestClose}
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.handleRequestClose}
            >
                <FaWindowClose />
            </Button>
            <form onSubmit={this.handleSubmit}>
                <div className="flex">
                    <div className="w-1/3 mlsp-2">
                        <div>
                            <label className="text-xs uppercase">
                                Part Number
                            </label>
                            <Input
                                value={product.pn}
                                onChange={v => this.handleInput("product.pn", v)}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Nombre
                            </label>
                            <Input
                                value={product.name}
                                maxLength={38}
                                onChange={v => this.handleInput("product.name", v)}
                            />
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Cantidad óptima de fabricación
                            </label>
                            <Input
                                type="number"
                                value={product.optimalUnits}
                                onChange={v => this.handleInput("product.optimalUnits", Number(v))}
                            />
                        </div>
                    </div>
                    <div className="w-1/3 mlsp-2 flex flex-col">
                        <div>
                            <label className="text-xs uppercase">
                                Retail
                            </label>
                            <div>
                                <input
                                    type="checkbox"
                                    checked={product.retail}
                                    onChange={e => this.handleInput("product.retail", e.target.checked)}
                                />
                            </div>
                        </div>
                        <div>
                            <label className="text-xs uppercase">
                                Count Mode
                            </label>
                            <div>
                                <Select
                                    value={product.countMode}
                                    onChange={v => this.handleInput("product.countMode", v)}
                                >
                                    <option value="batch">batch</option>
                                    <option value="instance">instance</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 mlsp-2 flex flex-col">
                        { product.retail && <div>
                            <label className="text-xs uppercase">
                                EAN13
                            </label>
                            <Input
                                value={`${product.ean13}${ean13_checksum(product.ean13)}`}
                                disabled
                            />
                        </div> }
                        <div>
                            <label className="text-xs uppercase">
                                Nombre corto
                            </label>
                            <Input
                                value={product.shortName}
                                onChange={v => this.handleInput("product.shortName", v)}
                            />
                        </div>
                    </div>
                </div>

                <h1 className="mt-4 font-semibold text-xl">
                    Bloques
                </h1>

                <SortList
                    items={product.blocks}
                    render={(block, { dragged, index, ref, onMouseDown, onTouchStart }) => <div
                        ref={ref}
                        className={cn("flex items-baseline py-1 border-b pr-4", {
                            "opacity-50 bg-gray-200": dragged,
                            "border-t": index == 0,

                        })}
                    >
                        <MdDragHandle
                            className="self-center mr-2 text-xl cursor-move"
                            onMouseDown={onMouseDown}
                            onTouchStart={onTouchStart}
                        />
                        <button
                            className="mr-2 font-semibold"
                            type="button"
                            onClick={() => this.handleBlockClick(block, index)}
                        >
                            { block.name }
                        </button>
                        <span className="text-xs text-gray-700">
                            ({ block.steps.length } pasos)
                        </span>
                        <button
                            className="ml-auto"
                            type="button"
                            onClick={() => this.handleDeleteBlock(index)}
                        >
                            <FaTrashAlt />
                        </button>
                    </div>}
                    renderPlaceholder={() => <div className="bg-blue-800 h-1" />}
                    onDragEnd={this.handleDragEnd}
                />

                <div className="flex items-end py-1 mt-2">
                    <div>
                        <label className="text-xs uppercase">
                            Nombre
                        </label>
                        <Input
                            value={newBlockName}
                            onChange={v => this.handleInput("newBlockName", v)}
                        />
                    </div>
                    <Button
                        type="button"
                        className="ml-2"
                        onClick={this.handleNewBlock}
                    >
                        Añadir
                    </Button>
                </div>

                <div className="flex mt-4">
                    <Button
                        type="button"
                        className="ml-auto"
                        onClick={this.handleReset}
                        theme="outline"
                    >
                        Reset
                    </Button>

                    <Button
                        className="ml-2"
                        disabled={this.state.working}
                    >
                        Guardar
                        { this.state.working && <div className="ml-2 flex items-center">
                            <FaSpinner className="spin text-xl text-gray-700" />
                        </div> }
                    </Button>
                </div>
            </form>

            { editingBlock && <EditBlockModal
                block={editingBlock}
                descriptionName={product.name || product.pn}
                onRequestClose={this.hideBlockModal}
                onAccept={this.handleAccept}
            /> }
        </ReactModal>
    }

    handleAccept = block => {
        this.setState(produce(draft => {
            draft.product.blocks[draft.editingBlockIdx] = block
        }))
        this.hideBlockModal()
    }

    hideBlockModal = () => {
        this.setState({
            editingBlockIdx: null
        })
    }

    handleDragEnd = (fromIdx, toIdx) => {
        this.setState(produce(draft => {
            const blocks = draft.product.blocks
            reorderArray(blocks, fromIdx, toIdx)
        }))
    }

    handleInput = (path, value) => {
        this.setState(produce(draft => {
            set(draft, path, value)
        }))
    }

    handleSubmit = async event => {
        event.preventDefault()

        const p = this.state.product

        const pn = p.pn.trim()
        const name = p.name.trim()

        if (!pn) {
            window.alert("Part Number es obligatorio")
            return
        }
        if (p.blocks.length == 0) {
            window.alert("Es necesario al menos un bloque")
            return
        }
        if (p.blocks.every(b => b.steps.length == 0)) {
            window.alert("Es necesario al menos un paso")
            return
        }

        this.setState({ working: true })
        try {
            await this.props.onUpsertProduct({
                id:         p.id,
                pn,
                name,
                shortName:  p.shortName.trim(),
                product:    p.product,
                blocks:     p.blocks,
                retail:     p.retail,
                countMode:  p.countMode,
                optimalUnits: p.optimalUnits,
            })
        } finally {
            this.setState({ working: false })
        }
        this.props.onRequestClose()
    }

    handleBlockClick = (block, idx) => {
        this.setState({
            editingBlockIdx: idx,
        })
    }

    handleDeleteBlock = (idx) => {
        const go = window.confirm("¿Estás seguro?")
        if (!go) return

        this.setState(produce(draft => {
            draft.product.blocks.splice(idx, 1)
        }))
    }

    handleNewBlock = () => {
        if (this.state.newBlockName.trim().length == 0) {
            this.props.onNotify("warn", "Empty block name")
            return
        }

        this.setState(produce(draft => {
            draft.product.blocks.push({
                name: draft.newBlockName,
                steps: []
            })
            draft.newBlockName = ""
        }))
    }

    handleReset = () => {
        this.setState({
            product: cloneDeep(this.initialProduct)
        })
    }
})
