import axios from "axios"
import { nanoid } from "nanoid"
import produce from "immer"
import { combineReducers } from "redux"
import { push, connectRouter } from "connected-react-router"
import keyBy from "lodash/keyBy"
import isUndefined from "lodash/isUndefined"
import { DateTime } from "luxon"
import cloneDeep from "lodash/cloneDeep"

import {
    obj2qs,
    clamp,
    cookDateRange,
    getAdjacencyLists,
    getSourceVertices,
    getTotalCostsByType,
} from "~/lib/utils"

const EMAILS_DEFAULT = {
    entities: {},
    page: [],
    count: -1,
    offset: 0,
    limit: 20,
    filters: {
        sentAt: undefined,
        recipient: "",
        type: "",
        status: "",
    }
}

const PRODUCTS_DEFAULT = {
    entities: {},
    page: [], // [String] products.id
    count: -1,
    offset: 0,
    limit: 10,
    filters: {
        pn: "",
        name: "",
    }
}

export const defaultBatchFilterValues = {
    code:         "",
    createdAt:    undefined,
    doneAt:       undefined,
    uploadedAt:   undefined,
    enabledAt:    undefined,
    finishAt:     undefined,
    shippingAt:   undefined,
    ownedBy:      "",
    pn:           "",
    sn:           "",
    client:       "",
    erpReference: "",

    orderBy:    "createdAt",
    orderDir:   "ASC",
}

const BATCHES_DEFAULT = {
    entities: {},
    page: [], // [String] batches.id
    count: -1,
    offset: 0,
    limit: 10,
    filters: defaultBatchFilterValues,
}

const MANUALS_DEFAULT = {
    manuals: [],
    filters: {
        name: "",
        type: "any",
    }
}

const STOCK_DEFAULT = {
    date:   DateTime.utc().toISO(),

    snapshotDates: [], // [String]
    items:  {},    // { id => Item }
    edges:  [],    // [[from, to, { notes, amount }]]

    // TODO This cries for memoization
    adj:    {},    // { from => [to] }
    iadj:   {},    // { to => [from] }
    totalCosts: {},
}

const initialState = {
    now: DateTime.utc().toISO(),

    currentUserId: null,
    users: {},
    notifications: [],

    manuals: MANUALS_DEFAULT,
    products: PRODUCTS_DEFAULT,
    batches: BATCHES_DEFAULT,
    emails: EMAILS_DEFAULT,
    stock: STOCK_DEFAULT,

    scale: 1,
}

const TICK = "TICK"
function tick() {
    return { type: TICK }
}

const SET_SCALE = "SCALE"
function setScale(scale) {
    return {
        type: SET_SCALE,
        scale
    }
}

const SET_STOCK_SNAPSHOT_DATES = "SET_STOCK_SNAPSHOT_DATES"
function setStockSnapshotDates(dates) {
    return {
        type: SET_STOCK_SNAPSHOT_DATES,
        dates,
    }
}

const SET_STOCK_DATE = "SET_STOCK_DATE"
export function setStockDate(date) {
    return {
        type: SET_STOCK_DATE,
        date,
    }
}

const SET_STOCK_GRAPH = "SET_STOCK_GRAPH"
function setStockGraph({ items, edges, adj, iadj, totalCosts }) {
    return {
        type: SET_STOCK_GRAPH,
        items, edges,
        adj, iadj,
        totalCosts,
    }
}

const SET_EMAILS_PAGINATION = "SET_EMAILS_PAGINATION"
function setEmailsPagination(limit, offset) {
    return {
        type: SET_EMAILS_PAGINATION,
        offset,
        limit,
    }
}

const SET_BATCHES_PAGINATION = "SET_BATCHES_PAGINATION"
function setBatchesPagination(limit, offset) {
    return {
        type: SET_BATCHES_PAGINATION,
        offset,
        limit,
    }
}

const SET_PRODUCTS_PAGINATION = "SET_PRODUCTS_PAGINATION"
function setProductsPagination(limit, offset) {
    return {
        type: SET_PRODUCTS_PAGINATION,
        offset,
        limit,
    }
}

const SET_MANUALS_FILTERS = "SET_MANUALS_FILTERS"
function setManualsFilters(filters) {
    return {
        type: SET_MANUALS_FILTERS,
        filters,
    }
}

const SET_PRODUCTS_FILTERS = "SET_PRODUCTS_FILTERS"
function setProductsFilters(filters) {
    return {
        type: SET_PRODUCTS_FILTERS,
        filters,
    }
}

const SET_EMAILS_FILTERS = "SET_EMAILS_FILTERS"
function setEmailsFilters(filters) {
    return {
        type: SET_EMAILS_FILTERS,
        filters
    }
}

const SET_BATCHES_FILTERS = "SET_BATCHES_FILTERS"
function setBatchesFilters(filters) {
    return {
        type: SET_BATCHES_FILTERS,
        filters
    }
}

const SET_BATCH = "SET_BATCH"
function setBatch(batch) {
    return {
        type: SET_BATCH,
        batch
    }
}

const SET_BATCHES_PAGE = "SET_BATCHES_PAGE"
function setBatchesPage(batches, count) {
    return {
        type: SET_BATCHES_PAGE,
        batches,
        count
    }
}

const SET_EMAILS_PAGE = "SET_EMAILS_PAGE"
function setEmailsPage(emails, count) {
    return {
        type: SET_EMAILS_PAGE,
        emails,
        count,
    }
}

const SET_PRODUCTS_PAGE = "SET_PRODUCTS_PAGE"
function setProductsPage(products, count) {
    return {
        type: SET_PRODUCTS_PAGE,
        products,
        count,
    }
}

const SET_MANUALS = "SET_MANUALS"
function setManuals(manuals) {
    return {
        type: SET_MANUALS,
        manuals,
    }
}

const SET_CURRENT_USER = "SET_CURRENT_USER"
function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user,
    }
}

const RESET_STORE = "RESET_STORE"
function resetStore() {
    return {
        type: RESET_STORE
    }
}

const PUSH_NOTIFICATION = "PUSH_NOTIFICATION"
function pushNotification(id, kind, text) {
    return {
        type: PUSH_NOTIFICATION,
        id,
        kind,
        text
    }
}

const POP_NOTIFICATION = "POP_NOTIFICATION"
function popNotification(id) {
    return {
        type: POP_NOTIFICATION,
        id,
    }
}

const SET_USER = "SET_USER"
function setUser(user) {
    return {
        type: SET_USER,
        user,
    }
}

export function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),

        app: produce((draft, action) => {
            if (!draft) return initialState

            switch (action.type) {
                case SET_CURRENT_USER: {
                    const { user } = action
                    draft.users[user.id] = user
                    draft.currentUserId = user.id
                    break
                }

                case RESET_STORE: {
                    draft.currentUserId = null
                    draft.users = {}
                    draft.manuals = []
                    draft.products = cloneDeep(PRODUCTS_DEFAULT)
                    draft.batch = cloneDeep(BATCHES_DEFAULT)
                    // TODO stockItems, emails?
                    break
                }

                case PUSH_NOTIFICATION: {
                    draft.notifications.push({
                        id:   action.id,
                        kind: action.kind,
                        text: action.text,
                    })
                    break
                }

                case POP_NOTIFICATION: {
                    const idx = draft.notifications.findIndex(n => n.id == action.id)
                    if (idx != -1) draft.notifications.splice(idx, 1)
                    break
                }

                case SET_USER: {
                    const { user } = action
                    draft.users[user.id] = user
                    break
                }

                case SET_SCALE: {
                    const { scale } = action
                    draft.scale = clamp(0.1, 1, scale)
                    break
                }

                case SET_MANUALS: {
                    const { manuals } = action
                    draft.manuals.manuals = manuals
                    break
                }

                case SET_MANUALS_FILTERS: {
                    const { filters } = action
                    Object.assign(draft.manuals.filters, filters)
                    break
                }

                case SET_PRODUCTS_PAGE: {
                    const { products, count } = action
                    Object.assign(draft.products.entities, keyBy(products, "id"))
                    draft.products.page = products.map(p => p.id)
                    draft.products.count = count
                    break
                }

                case SET_PRODUCTS_PAGINATION: {
                    const { offset, limit } = action
                    draft.products.offset = offset
                    draft.products.limit = limit
                    break
                }

                case SET_PRODUCTS_FILTERS: {
                    const { filters } = action
                    Object.assign(draft.products.filters, filters)
                    break
                }

                case SET_BATCH: {
                    const { batch } = action
                    draft.batches.entities[batch.id] = batch
                    break
                }

                case SET_BATCHES_PAGE: {
                    const { batches, count } = action
                    Object.assign(draft.batches.entities, keyBy(batches, "id"))
                    draft.batches.page = batches.map(batch => batch.id)
                    draft.batches.count = count
                    break
                }

                case SET_BATCHES_PAGINATION: {
                    const { offset, limit } = action
                    draft.batches.offset = offset
                    draft.batches.limit = limit
                    break
                }

                case SET_EMAILS_PAGINATION: {
                    const { offset, limit } = action
                    draft.emails.offset = offset
                    draft.emails.limit = limit
                    break
                }

                case SET_BATCHES_FILTERS: {
                    const { filters } = action
                    Object.assign(draft.batches.filters, filters)
                    break
                }

                case SET_STOCK_SNAPSHOT_DATES: {
                    const { dates } = action
                    draft.stock.snapshotDates = dates
                    break
                }

                case SET_STOCK_DATE: {
                    const { date } = action
                    draft.stock.date = date
                    break
                }

                case SET_STOCK_GRAPH: {
                    const { items, edges, adj, iadj, totalCosts } = action
                    draft.stock.items = keyBy(items, "id")
                    draft.stock.edges = edges
                    draft.stock.adj = adj
                    draft.stock.iadj = iadj
                    draft.stock.totalCosts = totalCosts
                    break
                }

                case SET_EMAILS_FILTERS: {
                    const { filters } = action
                    Object.assign(draft.emails.filters, filters)
                    break
                }

                case SET_EMAILS_PAGE: {
                    const { emails, count } = action
                    Object.assign(draft.emails.entities, keyBy(emails, "id"))
                    draft.emails.page = emails.map(e => e.id)
                    draft.emails.count = count
                    break
                }

                case TICK: {
                    draft.now = DateTime.utc().toISO()
                    break
                }
            }
        })
    })
}

// kind: ["warn", "success"]
export function notify(kind, text) {
    return async dispatch => {
        const id = nanoid()
        await dispatch(pushNotification(id, kind, text))
        setTimeout(() => {
            dispatch(popNotification(id))
        }, 5000)
    }
}

export function login(email, password) {
    return async dispatch => {
        const response = await axios.post("/login", { email, password })
        if (response.data.ok) {
            await Promise.all([
                dispatch(setCurrentUser(response.data.user)),
                dispatch(refreshUsers()),
            ])
            await dispatch(push("/"))
        } else {
            await dispatch(notify("warn", "Email o contraseña incorrectos."))
        }
    }
}

export function logout() {
    return async dispatch => {
        await axios.post("/logout")
        await dispatch(resetStore())
        await dispatch(push("/login"))
    }
}

export function comeBack() {
    return async dispatch => {
        try {
            const response = await axios.get("/back")
            const user = response.data
            if (user) {
                await Promise.all([
                    dispatch(setCurrentUser(user)),
                    dispatch(refreshUsers()),
                ])
            }
        } catch(_) {
            // NOP
        }
    }
}

export function refreshUsers() {
    return async dispatch => {
        const response = await axios.get("/users")
        for (let user of response.data) {
            await dispatch(setUser(user))
        }
    }
}

export function refreshManuals() {
    const thunk = async (dispatch, getState) => {
        const {
            filters
        } = getState().app.manuals

        let qs = {}

        if (filters.name.trim())   qs.name = filters.name.trim()
        if (filters.type != "any") qs.type = filters.type

        qs = obj2qs(qs)

        const response = await axios.get(`/manuals?${qs}`)
        await dispatch(setManuals(response.data))
    }
    thunk.meta = {
        debounce: {
            time: 500,
            key: "REFRESH_MANUALS",
        }
    }
    return thunk
}

export function uploadManualVersion(manual, file) {
    return async dispatch => {
        const formData = new FormData()
        formData.append("file", file)
        await axios.post(`/manuals/${manual.id}`, formData)
        await dispatch(notify("success", "Manual actualizado con éxito"))
        await dispatch(refreshManuals())
    }
}

export function replaceManualVersion(manual, version, file) {
    return async dispatch => {
        const formData = new FormData()
        formData.append("file", file)
        await axios.post(`/manuals/${manual.id}/versions/${version.id}`, formData)
        await dispatch(notify("success", `Manual reemplazado con éxito`))
        await dispatch(refreshManuals())
    }
}

export function deleteManual(manual) {
    return async dispatch => {
        const response = await axios.get(`/batches?manualId=${manual.id}`)
        const batches = response.data.results
        const response2 = await axios.get(`/products?manualId=${manual.id}`)
        const products = response2.data.results

        let msg = `¿Seguro que deseas borrar "${manual.name}"?`

        if (batches.length > 0) {
            msg += ` Se utiliza en ${batches.length} montajes.`
        }
        if (products.length > 0) {
            msg += ` Se utiliza en los productos: ${products.map(p => p.pn).join(", ")}.`
        }

        const go = window.confirm(msg)
        if (!go) return

        await axios.delete(`/manuals/${manual.id}`)
        await dispatch(notify("success", `Manual borrado con éxito`))
        await dispatch(refreshManuals())
    }
}

export function uploadManual(files, type) {
    return async dispatch => {
        /*
        for (let file of files) {
            if (this.state.manuals.some(m => m.name == file.name)) {
                this.props.ui.alert(`¡Ya existe un manual llamado "${file.name}"!`)
                return
            }
        }
        // TODO check file doesn't exists
        */
        const formData = new FormData()
        for (let file of files) {
            formData.append("files", file)
        }
        formData.set("type", type)
        await axios.post("/manuals", formData)
        await dispatch(notify("success", `${files.length} manuales subidos con éxito`))
        await dispatch(refreshManuals())
    }
}

export function createUser(password, name, email) {
    return async dispatch => {
        const response = await axios.post("/users", { password, name, email })
        await dispatch(setUser(response.data))
        await dispatch(notify("success", "Usuario creado con éxito"))
    }
}

// parent: StockItem
// child is a StockItem with child.relation: StockEdge
export function addStockItemDescendant(parent, child, notes, amount) {
    return async dispatch => {
        await axios.post(`/stock-items/${parent.id}/link/${child.id}`, { // horrible URL
            notes, amount
        })
        await dispatch(notify("success", `${child.name} added to ${parent.name}`))
        await dispatch(refreshStockGraph())
    }
}

export function importStockItemDescendants(parentId, donorId) {
    return async dispatch => {
        await axios.post(`/stock-items/${parentId}/import/${donorId}`) // horrible URL
        await dispatch(notify("success", `Importación realizada con éxito!`))
        await dispatch(refreshStockGraph())
    }
}

export function updateStockItemDescendant(edgeId, to, notes, amount) {
    return async dispatch => {
        await axios.post(`/stock-edges/${edgeId}`, {
            to, notes, amount
        })
        await dispatch(notify("success", `fuck yeah`))
        await dispatch(refreshStockGraph())
    }
}

// parent: StockItem
// child is a StockItem with child.relation: StockEdge
export function removeStockItemDescendant(parent, child) {
    return async dispatch => {
        await axios.delete(`/stock-edges/${child.relation.id}`)
        await dispatch(notify("success", `${child.name} removed from ${parent.name}`))
        await dispatch(refreshStockGraph())
    }
}

export function updateUser(userId, payload) {
    return async dispatch => {
        const response = await axios.post(`/users/${userId}`, payload)
        await dispatch(setUser(response.data))
        await dispatch(notify("success", "Usuario actualizado con éxito"))
    }
}

export function updateEmailsPagination(dir) {
    return async (dispatch, getState) => {
        const { offset, limit, count } = getState().app.emails

        await dispatch(setEmailsPagination(
            limit,
            clamp(0,
                limit * Math.floor(count / limit),
                offset + dir * limit)
        ))
        await dispatch(refreshEmails())
    }
}

export function updateBatchesPagination(dir) {
    return async (dispatch, getState) => {
        const { offset, limit, count } = getState().app.batches

        await dispatch(setBatchesPagination(
            limit,
            clamp(0,
                limit * Math.floor(count / limit),
                offset + dir * limit)
        ))
        await dispatch(refreshBatches())
    }
}

export function updateProductsPagination(dir) {
    return async (dispatch, getState) => {
        const { offset, limit, count } = getState().app.products

        await dispatch(setProductsPagination(
            limit,
            clamp(0,
                limit * Math.floor(count / limit),
                offset + dir * limit)
        ))
        await dispatch(refreshProducts())
    }
}

export function updateManualFilters(filters) {
    return async dispatch => {
        await dispatch(setManualsFilters(filters))
        await dispatch(refreshManuals())
    }
}

export function updateProductFilters(filters) {
    return async (dispatch, getState) => {
        const { limit } = getState().app.products
        await dispatch(setProductsFilters(filters))
        await dispatch(setProductsPagination(limit, 0))
        await dispatch(refreshProducts())

    }
}

export function updateEmailFilters(filters) {
    return async (dispatch, getState) => {
        const { limit } = getState().app.emails
        await dispatch(setEmailsFilters(filters))
        await dispatch(setEmailsPagination(limit, 0))
        await dispatch(refreshEmails())
    }
}

export function updateBatchFilters(filters) {
    return async (dispatch, getState) => {
        const { limit } = getState().app.batches
        await dispatch(setBatchesFilters(filters))
        await dispatch(setBatchesPagination(limit, 0))
        await dispatch(refreshBatches())
    }
}

export function refreshBatch(id) {
    return async dispatch => {
        const response = await axios.get(`/batches/${id}`)
        const batch = response.data
        await dispatch(setBatch(batch))
    }
}

export function enterBatch(id) {
    return async dispatch => {
        const response = await axios.post(`/batches/${id}/enter`)
        const batch = response.data
        await dispatch(setBatch(batch))
    }
}

export function reassignBatch(batchId, userId) {
    return async dispatch => {
        const response = await axios.post(`/batches/${batchId}/reassign`, { userId })
        const batch = response.data
        await dispatch(setBatch(batch))
        await dispatch(notify("success", `Montaje reasignado con éxito`))
    }
}

export function changeBatchPriority(batchId, priority) {
    return async dispatch => {
        const response = await axios.post(`/batches/${batchId}/priority`, { priority })
        const batch = response.data
        await dispatch(setBatch(batch))
        await dispatch(notify("success", `Prioridad actualizada`))
    }
}

export function changeBatchFinishAt(batchId, finishAt) {
    return async dispatch => {
        const response = await axios.post(`/batches/${batchId}/finishAt`, {
            finishAt
        })
        const batch = response.data
        await dispatch(setBatch(batch))
        await dispatch(notify("success", `Fecha objetivo actualizada`))
    }
}

export function refreshEmails() {
    const thunk = async (dispatch, getState) => {
        const {
            offset,
            limit,
            filters,
        } = getState().app.emails

        let qs = {
            limit,
            offset,
        }

        if (!isUndefined(filters.sentAt)) qs.sentAt = cookDateRange(filters.sentAt)
        if (filters.recipient.trim()) qs.recipient = filters.recipient.trim()
        if (filters.type.trim()) qs.type = filters.type.trim()
        if (filters.status.trim()) qs.status = filters.status.trim()

        qs = obj2qs(qs)

        const response = await axios.get(`/emails?${qs}`)
        const { count, results } = response.data
        await dispatch(setEmailsPage(results, count))
    }
    thunk.meta = {
        debounce: {
            time: 500,
            key: "REFRESH_EMAILS",
        }
    }
    return thunk
}

export function refreshBatches() {
    const thunk = async (dispatch, getState) => {
        const {
            offset,
            limit,
            filters,
        } = getState().app.batches

        let qs = {
            limit,
            offset,
        }

        if (!isUndefined(filters.createdAt)) {
            qs.createdAt = cookDateRange(filters.createdAt)
        }
        if (!isUndefined(filters.enabledAt)) {
            qs.enabledAt = cookDateRange(filters.enabledAt)
        }
        if (!isUndefined(filters.doneAt)) {
            qs.doneAt = cookDateRange(filters.doneAt)
        }
        if (!isUndefined(filters.finishAt)) {
            qs.finishAt = cookDateRange(filters.finishAt)
        }
        if (!isUndefined(filters.uploadedAt)) {
            qs.uploadedAt = cookDateRange(filters.uploadedAt)
        }
        if (!isUndefined(filters.shippingAt)) {
            qs.shippingAt = cookDateRange(filters.shippingAt)
        }

        if (filters.erpReference.trim()) qs.erpReference = filters.erpReference.trim()
        if (filters.code.trim())   qs.code    = filters.code.trim()
        if (filters.pn.trim())     qs.pn      = filters.pn.trim()
        if (filters.sn.trim())     qs.sn      = filters.sn.trim()
        if (filters.client.trim()) qs.client  = filters.client.trim()
        if (filters.ownedBy)       qs.ownedBy = filters.ownedBy.trim()

        qs.orderDir = filters.orderDir
        qs.orderBy  = filters.orderBy

        qs = obj2qs(qs)

        const response = await axios.get(`/batches?${qs}`)
        const { count, results } = response.data
        await dispatch(setBatchesPage(results, count))
    }
    thunk.meta = {
        debounce: {
            time: 500,
            key: "REFRESH_BATCHES",
        }
    }
    return thunk
}

export function refreshStockGraphSnapshots() {
    return async dispatch => {
        const response = await axios.get("/stock-graph-snapshots")
        const { dates } = response.data
        dispatch(setStockSnapshotDates(dates))
    }
}

export function refreshStockGraph() {
    const thunk = async (dispatch, getState) => {
        const { date } = getState().app.stock
        const response = await axios.get(`/stock-graph?date=${encodeURIComponent(date)}`)
        const { items: itemList, edges } = response.data
        const items = keyBy(itemList, "id")
        const { adj, iadj } = getAdjacencyLists(edges)
        const sources = getSourceVertices(itemList, iadj)

        const totalCosts = getTotalCostsByType(items, sources, adj)

        dispatch(setStockGraph({
            items, edges,
            adj, iadj,
            totalCosts,
        }))
    }
    thunk.meta = {
        debounce: {
            time: 500,
            key: "REFRESH_STOCK_GRAPH",
        }
    }
    return thunk
}

export function refreshProducts() {
    const thunk = async (dispatch, getState) => {
        const {
            offset,
            limit,
            filters,
        } = getState().app.products

        let qs = {
            limit,
            offset,
        }

        if (filters.pn.trim())   qs.pn   = filters.pn.trim()
        if (filters.name.trim()) qs.name = filters.name.trim()

        qs = obj2qs(qs)

        const response = await axios.get(`/products?${qs}`)
        const { count, results } = response.data
        await dispatch(setProductsPage(results, count))
    }
    thunk.meta = {
        debounce: {
            time: 500,
            key: "REFRESH_PRODUCTS",
        }
    }
    return thunk
}

export function upsertProduct(product) {
    return async dispatch => {
        await axios.post("/products", product)
        await dispatch(notify("success", `Producto actualizado con éxito`))
        await dispatch(refreshProducts())
    }
}

export function deleteProduct(productId) {
    return async dispatch => {
        await axios.delete(`/products/${productId}`)
        await dispatch(notify("success", `Producto borrado con éxito`))
        await dispatch(refreshProducts())
    }
}

export function resetBatch(id) {
    return async dispatch => {
        await axios.post(`/batches/${id}/reset`)
        await dispatch(notify("success", `Lote reseteado con éxito`))
        await dispatch(refreshBatches())
    }
}

export function deleteBatch(id) {
    return async dispatch => {
        await axios.delete(`/batches/${id}`)
        await dispatch(notify("success", `Lote borrado con éxito`))
        await dispatch(refreshBatches())
    }
}

export function updateBatch(id) {
    return async dispatch => {
        await axios.post(`/batches/${id}/update`)
        await dispatch(notify("success", `Lote actualizado con éxito`))
        await dispatch(refreshBatches())
    }
}

export function uploadInstance(id, updateDoneAtDate) {
    return async dispatch => {
        await axios.post(`/upload/${id}`, { updateDoneAtDate })
        await dispatch(notify("success", `Instancia publicada con éxito`))
        await dispatch(refreshBatches())
    }
}

export function startTicking() {
    return dispatch => {
        setInterval(() => {
            dispatch(tick())
        }, 1000)
    }
}

export function addNote(batchId, text) {
    return async dispatch => {
        await axios.post(`/batches/${batchId}/notes`, { text })
        await dispatch(notify("success", "Nota añadida con éxito"))
        await dispatch(refreshBatch(batchId))
    }
}

export function addComment(batchId, stepId, text) {
    return async dispatch => {
        await axios.post(`/batches/${batchId}/steps/${stepId}/comment`, { text })
        await dispatch(notify("success", `Comentario actualizado con éxito`))
        await dispatch(refreshBatch(batchId))
    }
}

export function doStep(batchId, instanceId, stepId, value) {
    return async dispatch => {
        await axios.post(`/batches/${batchId}/instances/${instanceId}/steps/${stepId}`, { value })
        await dispatch(refreshBatch(batchId))
    }
}

export function editStep(batchId, instanceId, stepId, value) {
    return async dispatch => {
        await axios.post(`/batches/${batchId}/instances/${instanceId}/steps/${stepId}/edit`, { value })
        await dispatch(refreshBatch(batchId))
        await dispatch(notify("success", `Valor modificado con éxito`))
    }
}

export function createBatch({
    productId, units, finishAt, ownedBy, clientEmail, clientName, clientCountry, erpReference,
    priority,
}) {
    return async dispatch => {
        await axios.post(`/products/${productId}/assemble`, {
            units,
            finishAt,
            ownedBy,
            clientEmail,
            clientName,
            clientCountry,
            erpReference,
            priority,
        })
        await dispatch(notify("success", "Montaje creado con éxito"))
        await dispatch(refreshBatches())
    }
}

export function reorderEdges(stockItemId, from, to) {
    return async dispatch => {
        await axios.post(`/stock-items/${stockItemId}/reorder-edges`, { from, to })
        await dispatch(notify("success", `Elementos reordenados con éxito!`))
        await dispatch(refreshStockGraph())
    }
}

export function zoomIn() {
    return async (dispatch, getState) => {
        const { scale } = getState().app
        dispatch(setScale(scale + 0.1))
    }
}

export function zoomOut() {
    return async (dispatch, getState) => {
        const { scale } = getState().app
        dispatch(setScale(scale - 0.1))
    }
}
