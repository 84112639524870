import React from "react"
import cn from "classnames"
import { connect } from "react-redux"

import {
    MdZoomIn,
    MdZoomOut,
} from "react-icons/md"

import {
    zoomIn,
    zoomOut,
} from "~/store"

import Button from "~/components/Button"

const mapStateToProps = state => ({
    scale: state.app.scale
})
const mapDispatchToProps = dispatch => ({
    onZoomIn: () => dispatch(zoomIn()),
    onZoomOut: () => dispatch(zoomOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(class Zoomer extends React.Component {
    render() {
        return <div className={cn("flex", this.props.className)}>
            <Button
                theme="transparent"
                style="compact"
                className="mlsp-4"
                onClick={this.props.onZoomOut}
            >
                <MdZoomOut className="text-lg" />
            </Button>
            <Button
                theme="transparent"
                style="compact"
                className="mlsp-4"
                onClick={this.props.onZoomIn}
            >
                <MdZoomIn className="text-lg"/>
            </Button>
        </div>
    }
})
