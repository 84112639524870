import React from "react"
import { connect } from "react-redux"
import {
    Switch,
    Route,
} from "react-router-dom"
import { ConnectedRouter as Router } from "connected-react-router"
import { Provider } from "react-redux"

import LoginView from "~/components/LoginView"
import HomeView from "~/components/HomeView"
import BatchView from "~/components/BatchView"
import UsersView from "~/components/UsersView"
import ManualsView from "~/components/ManualsView"
import ProductsView from "~/components/ProductsView"
import EmailsView from "~/components/EmailsView"
import StockView from "~/components/StockView"
import StockItemView from "~/components/StockItemView"
import StatsView from "~/components/StatsView"
import ReportsView from "~/components/ReportsView"
import StockTransactionsView from "~/components/StockTransactionsView"
import LogsView from "~/components/LogsView"
import TagsView from "~/components/TagsView"

import ToastNotification from "~/components/ToastNotification"

const mapStateToProps = state => ({
    notifications: state.app.notifications,
})

export default connect(mapStateToProps)(class App extends React.Component {
    render() {
        const {
            notifications,
            history,
            store,
        } = this.props

        return <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path="/emails" component={EmailsView} />
                    <Route exact path="/login" component={LoginView} />
                    <Route exact path="/manuals" component={ManualsView} />
                    <Route exact path="/users" component={UsersView} />
                    <Route exact path="/products" component={ProductsView} />
                    <Route exact path="/stats" component={StatsView} />
                    <Route exact path="/reports" component={ReportsView} />
                    <Route path="/stock/:id" component={StockItemView} />
                    <Route exact path="/stock" component={StockView} />
                    <Route exact path="/stock-transactions" component={StockTransactionsView} />
                    <Route exact path="/logs" component={LogsView} />
                    <Route exact path="/tags" component={TagsView} />
                    <Route path="/:id" component={BatchView} />
                    <Route path="/" component={HomeView} />
                </Switch>

                <div className="z-50 flex flex-col-reverse items-end mr-6 mb-20 fixed bottom-0 right-0">
                    { notifications.slice().reverse().map(n => <ToastNotification
                        key={n.id}
                        kind={n.kind}
                        className="mt-3"
                        text={n.text}
                    />) }
                </div>
            </Router>
        </Provider>
    }
})
