import React from "react"
import { DateTime } from "luxon"

import {
    FaWindowClose,
} from "react-icons/fa"

import ReactModal from "react-modal"
import Button from "~/components/Button"
import InputDate from "~/components/InputDate"

export default class ChangeFinishAtModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            finishAt: props.finishAt ? DateTime.fromISO(props.finishAt).toJSDate() : new Date(),
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-lg relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <label className="text-xs uppercase">
                    Fecha objetivo
                </label>
                <InputDate
                    value={this.state.finishAt}
                    onChange={this.handleInput("finishAt")}
                />

                <div className="flex mt-2">
                    <Button className="ml-auto">
                        Aceptar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleSubmit = event => {
        event.preventDefault()

        const { finishAt } = this.state

        this.props.onChange(DateTime.fromJSDate(finishAt).toISO())
    }

    handleInput = prop => v => this.setState({ [prop]: v })
}
