import React from "react"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"
import { connect } from "react-redux"
import cn from "classnames"

import {
    FaInfoCircle,
    FaRegStickyNote,
    FaStar,
    FaUserClock,
} from "react-icons/fa"

import {
    times,
    fmtpriority,
} from "~/lib/utils"

import {
    selectBatchSteps,
} from "~/lib/selectors"

import BatchIcon from "~/components/BatchIcon"
import ProgressBar from "~/components/ProgressBar"

const mapStateToProps = state => ({
    users: state.app.users
})

// dupped in BatchView
const batch2segments = (batch) => {
    const steps = selectBatchSteps(batch)

    return steps.map(s => {
        if (s.doneAt) {
            return "ok"
        } else {
            return "disabled"
        }
    })
}

export default connect(mapStateToProps)(class BatchMediaItem extends React.Component {
    render() {
        const {
            batch,
            className,
            users,
            live,
        } = this.props

        const owner = batch.ownedBy ? users[batch.ownedBy] : undefined

        return <div className={cn("relative", className)}>
            <div className={cn("rounded overflow-hidden shadow", {
                "bg-green-200": live
            })}>
                <ProgressBar segments={batch2segments(batch)} />
                <div className="flex">
                    <div className="flex items-center justify-center text-xl px-4 mr-2">
                        <BatchIcon batch={batch} />
                    </div>
                    <div className="flex-1 flex flex-col py-2">
                        <div className="flex items-center">
                            <Link
                                to={`/${batch.id}`}
                                className="font-bold"
                            >
                                { batch.name }
                            </Link>
                            <span className="text-gray-700 ml-4">
                                (x{ batch.units })
                            </span>
                            { batch.notes.length > 0 && <FaRegStickyNote
                                title="Tiene notas"
                                className="text-gray-600 ml-4"
                            /> }
                            { owner && <div
                                title="Asignado a"
                                className="flex text-sm items-center ml-auto"
                            >
                                <FaUserClock className="text-gray-700 mr-1" />
                                { owner.name }
                            </div> }
                        </div>
                        <div className="flex items-center mt-1">
                            <span className="text-sm">
                                { batch.pn }
                            </span>
                            <span className="text-gray-700 text-xs ml-4">
                                { batch.code }
                            </span>
                            <div
                                className="flex items-center justify-center ml-auto"
                                title={`Prioridad: ${fmtpriority(batch.priority)}`}
                            >
                                { times(batch.priority, i => <FaStar
                                    key={i}
                                    className="text-orange-500"
                                />) }
                            </div>
                            { batch.doneAt ? <div
                                title="Fecha de finalización"
                                className="text-sm text-gray-700 ml-4"
                            >
                                { DateTime.fromISO(batch.doneAt).toLocaleString(DateTime.DATETIME_SHORT) }
                            </div> : batch.finishAt && <div
                                title="Fecha objetivo"
                                className="text-sm text-gray-700 ml-4"
                            >
                                { DateTime.fromISO(batch.finishAt).toLocaleString(DateTime.DATETIME_SHORT) }
                            </div>}
                        </div>
                    </div>
                    <button
                        title="Más información"
                        className="flex items-center justify-center px-4 ml-2 text-gray-700 hover:bg-gray-100"
                        onClick={this.props.onInfoClick}
                    >
                        <FaInfoCircle />
                    </button>
                </div>
            </div>
        </div>
    }
})
