import React from "react"
import ReactModal from "react-modal"

import {
    FaWindowClose,
} from "react-icons/fa"

import Button from "~/components/Button"
import Input from "~/components/Input"

export default class ShipOrderModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            courier: "",
            trackingNumber: "",
            trackingUrl: "",
            sendNotification: true,
        }
    }

    render() {
        return <ReactModal
            className="pb-4 mx-auto px-4 pt-8 rounded shadow bg-white max-w-2xl relative outline-none"
            onRequestClose={this.props.onRequestClose}
            isOpen
        >
            <Button
                className="absolute left-0 top-0"
                style="compact"
                theme="transparent"
                onClick={this.props.onRequestClose}
            >
                <FaWindowClose />
            </Button>

            <form onSubmit={this.handleSubmit}>
                <div className="flex">
                    <div>
                        <label className="text-xs uppercase">
                            Courier
                        </label>
                        <Input
                            value={this.state.courier}
                            onChange={this.handleInput("courier")}
                        />
                    </div>
                    <div className="ml-2">
                        <label className="text-xs uppercase">
                            Tracking number
                        </label>
                        <Input
                            value={this.state.trackingNumber}
                            onChange={this.handleInput("trackingNumber")}
                        />
                    </div>
                    <div className="ml-2">
                        <label className="text-xs uppercase">
                            Tracking URL
                        </label>
                        <Input
                            value={this.state.trackingUrl}
                            onChange={this.handleInput("trackingUrl")}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <label className="text-xs uppercase">
                        Enviar notificación
                    </label>
                    <input
                        className="block mt-1"
                        type="checkbox"
                        checked={this.state.sendNotification}
                        onChange={e => this.handleInput("sendNotification")(e.target.checked)}
                    />
                </div>
                <div className="mt-4">
                    <Button className="ml-auto">
                        Enviar
                    </Button>
                </div>
            </form>
        </ReactModal>
    }

    handleInput = path => value => {
        this.setState({ [path]: value })
    }

    handleSubmit = event => {
        event.preventDefault()

        const {
            courier,
            trackingNumber,
            trackingUrl,
            sendNotification,
        } = this.state

        this.props.onSubmit({
            courier, trackingNumber, trackingUrl, sendNotification,
        })
    }
}
